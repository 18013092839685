import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { PAYMENT_RETURN_URL } from "const/links";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import React, { useCallback, useContext } from "react";

import Button from "components/common/Button";
import Input from "components/common/Input";

import { getErrorMessage } from "utils";

import { voucherCodeSchema } from "validation/voucherFormValidation";

import { FormWrapper } from "./styles";

type InitialValues = {
  voucherCode: string;
};

type Props = {
  productId: number;
  onSuccess: () => void;
};

const VoucherForm: React.FC<Props> = ({ productId, onSuccess }) => {
  const { realizeVoucher, addToCart, fetchCart, payWithP24, user } =
    useContext(EscolaLMSContext);

  const handleSubmit = useCallback(
    async (values: InitialValues) => {
      try {
        const email = String(user?.value?.email).toLowerCase();
        await addToCart(productId);
        const voucherResponse = await realizeVoucher(values.voucherCode);

        if (!voucherResponse.success) {
          toast.error("Wystąpił niespodziewany błąd");
          return;
        }
        fetchCart();

        const p24Response: any = await payWithP24(email, PAYMENT_RETURN_URL);
        if (!p24Response?.success || p24Response?.data?.status !== "paid") {
          toast.error("Wystąpił niespodziewany błąd");
          return;
        }

        toast.success("Voucher zrealizowany poprawnie");
        onSuccess();
        fetchCart();
      } catch (error) {
        toast.error("Wystąpił niespodziewany błąd");
      }
    },
    [
      addToCart,
      fetchCart,
      onSuccess,
      payWithP24,
      productId,
      realizeVoucher,
      user?.value?.email,
    ]
  );

  const formik = useFormik<InitialValues>({
    initialValues: {
      voucherCode: "",
    },
    validationSchema: voucherCodeSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <Input
        name="voucherCode"
        id="voucherCode"
        placeholder="Wpisz kod dostępu"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.voucherCode}
        error={getErrorMessage<InitialValues>("voucherCode", formik)}
      />
      <Button styleVariant="primaryYellow">Potwierdź</Button>
    </FormWrapper>
  );
};

export default VoucherForm;
