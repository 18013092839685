import { OEmbedPlayer } from "@escolalms/components/lib/components/players/OEmbedPlayer/OEmbedPlayer";
import { XAPIEvent } from "@escolalms/h5p-react";
import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";

import React, { useCallback, useContext, useEffect } from "react";

import { MarkdownRenderer } from "components/MarkdownRenderer";
import AudioVideoPlayer from "components/Players/AudioVideoPlayer";
import H5Player from "components/Players/H5Player";
import ImagePlayer from "components/Players/ImagePlayer";
import PdfPlayer from "components/Players/PdfPlayer";
import ProjectPlayer from "components/Players/ProjectPlayer";
import ScormPlayer from "components/Players/ScormPlayer";

import { useCourse } from "../Context";
import GiftQuizPlayer from "../Quizzes";

export const CourseProgramContent: React.FC<{
  customNoCompletedEventsIds?: string[];
}> = ({
  customNoCompletedEventsIds = [
    "http://h5p.org/libraries/H5P.GuessTheAnswer-1.5",
  ],
}) => {
  const {
    currentTopic,
    currentCourseProgram,
    nextTopic,
    setIsNextTopicButtonDisabled,
  } = useCourse();

  const { topicPing, topicIsFinished, h5pProgress } =
    useContext(EscolaLMSContext);

  const isThereNextTopic = !!nextTopic;
  const topicId = currentTopic?.id;
  const courseId = currentCourseProgram?.id;

  const onXAPI = useCallback(
    (event: XAPIEvent): void => {
      if (isThereNextTopic) {
        setIsNextTopicButtonDisabled?.(!Boolean(event?.statement?.verb?.id));
      }

      if (event?.statement) {
        h5pProgress(
          String(courseId),
          Number(topicId),
          event?.statement as API.IStatement
        );
      }
    },
    [
      isThereNextTopic,
      setIsNextTopicButtonDisabled,
      h5pProgress,
      courseId,
      topicId,
    ]
  );

  useEffect(() => {
    const isTopicFinished = Boolean(topicId && topicIsFinished(topicId));

    isThereNextTopic &&
      isTopicFinished &&
      setIsNextTopicButtonDisabled?.(
        !isTopicFinished && !Boolean(currentTopic?.can_skip)
      );
  }, [
    setIsNextTopicButtonDisabled,
    topicId,
    currentTopic?.can_skip,
    isThereNextTopic,
    topicIsFinished,
  ]);

  useEffect(() => {
    const ping = () =>
      topicId && !topicIsFinished(topicId) && topicPing(topicId);

    const interval = setInterval(() => {
      ping();
    }, 5000);

    ping();
    return () => clearInterval(interval);
  }, [topicPing, topicId, topicIsFinished]);

  const enableNextButton = useCallback(
    () => setIsNextTopicButtonDisabled?.(false),
    [setIsNextTopicButtonDisabled]
  );

  if (!currentTopic) {
    return <React.Fragment />;
  }

  if (!currentTopic.topicable?.value) {
    return (
      <pre className="error">Error: topic.topicable?.value is missing</pre>
    );
  }

  if (currentTopic.topicable_type) {
    // TODO: specific interface for advanced topic players -> example: ImagePlayer
    switch (currentTopic.topicable_type) {
      case API.TopicType.H5P:
        return (
          <H5Player
            onXAPI={(e: XAPIEvent) => onXAPI(e)}
            // @ts-ignore
            h5pObject={currentTopic.topicable.content as API.H5PObject}
          />
        );
      case API.TopicType.OEmbed:
        return (
          <OEmbedPlayer url={currentTopic.topicable.value} key={topicId} />
        );
      case API.TopicType.RichText:
        return (
          <MarkdownRenderer>{currentTopic.topicable.value}</MarkdownRenderer>
        );
      case API.TopicType.Video:
        return (
          <AudioVideoPlayer
            // mobile={mobileView}
            url={currentTopic.topicable.url}
            light
            onTopicEnd={enableNextButton}
          />
        );
      case API.TopicType.Image:
        return <ImagePlayer topic={currentTopic} onLoad={enableNextButton} />;

      case API.TopicType.Audio:
        return (
          <AudioVideoPlayer
            // mobile={mobileView}
            audio
            url={currentTopic.topicable.url}
            light
            onTopicEnd={enableNextButton}
          />
        );

      case API.TopicType.Pdf:
        return (
          <PdfPlayer
            url={currentTopic.topicable.url}
            pageConfig={{
              renderMode: "svg",
              className: "course-pdf-player",
            }}
          />
        );

      case API.TopicType.Scorm:
        return (
          <ScormPlayer
            value={{
              title: currentTopic.title,
              uuid: currentTopic.topicable.uuid,
            }}
          />
        );

      case API.TopicType.GiftQuiz:
        return (
          <GiftQuizPlayer topic={currentTopic} onTopicEnd={enableNextButton} />
        );
      case API.TopicType.Project:
        return (
          <ProjectPlayer
            course_id={currentCourseProgram?.id ?? 0}
            topic={currentTopic}
            onSuccess={enableNextButton}
          />
        );
      default:
        return <pre>{(currentTopic as API.Topic).topicable_type}</pre>;
    }
  }

  return <pre>loading... (or error)</pre>;
};

export default CourseProgramContent;
