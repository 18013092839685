import { LinkProps as LinkComponentProps } from "react-router-dom";

import React from "react";

import { ButtonVariant, StyledButton, StyledLink } from "./styles";

type CommonProps = {
  children: React.ReactNode;
  styleVariant?: ButtonVariant;
  icon?: React.ReactNode;
  size?: "small" | "large";
  iconGap?: number;
  iconOnLeft?: boolean;
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

type LinkProps = LinkComponentProps & CommonProps;

type Props = ButtonProps | LinkProps;

type IconProps = {
  icon: React.ReactNode;
};

const Button: React.FC<Props> = ({
  children,
  styleVariant,
  icon,
  size,
  iconGap,
  iconOnLeft,
  ...props
}) => {
  if ("to" in props) {
    return (
      <StyledLink
        $iconGap={iconGap}
        $size={size}
        $withIcon={!!icon}
        $styleVariant={styleVariant}
        {...props}
      >
        {iconOnLeft && <Icon icon={icon} />}
        {children}
        {!iconOnLeft && <Icon icon={icon} />}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      $iconGap={iconGap}
      $size={size}
      $withIcon={!!icon}
      $styleVariant={styleVariant}
      {...props}
    >
      {iconOnLeft && <Icon icon={icon} />}
      {children}
      {!iconOnLeft && <Icon icon={icon} />}
    </StyledButton>
  );
};

export default Button;

const Icon: React.FC<IconProps> = ({ icon }) => {
  return <span data-testid="button-icon">{icon}</span>;
};
