import { API } from "@escolalms/sdk/lib";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import React, { useCallback, useMemo, useState } from "react";

import DashedDivider from "components/DashedDivider";
import routeRoutes from "components/Routes/routes";
import TestScoreSidebar from "components/TestScoreSidebar";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import { ButtonWrapper, SidebarTitle, Text, TextContainer } from "./styles";

type Props = {
  challenge?: API.CompetencyChallenge;
};

const Section = styled.section`
  margin-bottom: 25px;
`;

const ChallengeSection: React.FC<Props> = ({ challenge }) => {
  const [scoresOpened, setScoresOpened] = useState(false);

  const closeScores = useCallback(() => setScoresOpened(false), []);
  const navigate = useNavigate();

  const isResolved = useMemo(
    () => (challenge?.results ?? []).length > 0,
    [challenge]
  );

  if (!challenge) return null;

  return (
    <>
      <Section>
        <TextContainer>
          {!isResolved && (
            <SidebarTitle>Najpierw wykonaj bezpłatny test.</SidebarTitle>
          )}
          <Text>
            {isResolved
              ? "Super, że poświęciłeś/aś czas na wykonanie testu"
              : "Zajmie Ci około 15 minut, a pozwoli określić jakie kursy będą dla Ciebie najodpowiedniejsze."}
          </Text>
        </TextContainer>
        <ButtonWrapper>
          {challenge?.quiz_id && (
            <Button
              size="small"
              styleVariant="primaryGreen"
              icon={<Icon name="arrowRightSmall" />}
              onClick={() =>
                isResolved
                  ? setScoresOpened(true)
                  : navigate(
                      routeRoutes.competencyTest
                        .replace(":quizId", String(challenge.quiz_id))
                        .replace(":challengeId", String(challenge.id))
                    )
              }
            >
              {isResolved ? "Sprawdź wyniki" : "Rozpocznij test"}
            </Button>
          )}
        </ButtonWrapper>
        <DashedDivider margin="30px 0 0 0" />
      </Section>
      <TestScoreSidebar
        competencyChallenge={challenge}
        scoresOpened={scoresOpened}
        closeScores={closeScores}
      />
    </>
  );
};

export default ChallengeSection;
