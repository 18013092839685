import type { Color } from "theme";

import React from "react";

import type { IconName } from "components/common/Icon";

import { pixelsToRem } from "utils";

import { ColorIcon, Container, Label, Text } from "./styles";

type Props = {
  text?: string;
  iconName: IconName;
  iconColor?: Color;
  label?: string;
  textSize?: number;
};

const TextWithIcon: React.FC<Props> = ({
  text,
  iconName,
  iconColor = "primary",
  label,
  textSize,
}) => {
  const fontSize = pixelsToRem(textSize || 15);

  return (
    <Container>
      <ColorIcon name={iconName} $color={iconColor} />
      {label && (
        <Label
          style={{
            fontSize,
          }}
        >
          {label}
        </Label>
      )}
      {text && (
        <Text
          style={{
            fontSize,
          }}
        >
          {text}
        </Text>
      )}
    </Container>
  );
};

export default TextWithIcon;
