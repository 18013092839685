import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useEffect } from "react";

import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Input from "components/common/Input";

import { getErrorMessage } from "utils";

import { resetPasswordSchema } from "validation/resetPasswordFormValidation";

import { FormWrapper, InputWrapper } from "./styles";

type InitialValues = {
  password: string;
  repeatPassword: string;
};

const initialValues: InitialValues = {
  password: "",
  repeatPassword: "",
};

const ResetPasswordForm: React.FC = () => {
  const { reset, token: userToken } = useContext(EscolaLMSContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const onSubmit = useCallback(
    async (values: InitialValues) => {
      try {
        const response = await reset({
          ...values,
          email: String(email).toLowerCase(),
          token: String(token),
        });
        if (response.success) {
          toast.success("Hasło zostało zmienione. Możesz się teraz zalogować");
          navigate(routeRoutes.login);
        }
      } catch (e) {
        toast.error("Wystąpił błąd podczas próby zmiany hasła");
      }
    },
    [reset, token, email, navigate]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    userToken && navigate(routeRoutes.home);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <Input
          name="password"
          type="password"
          id="password"
          label="Nowe hasło"
          placeholder="Podaj nowe hasło"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={getErrorMessage<InitialValues>("password", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          name="repeatPassword"
          type="password"
          id="repeatPassword"
          placeholder="Powtórz nowe hasło"
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={getErrorMessage<InitialValues>("repeatPassword", formik)}
        />
      </InputWrapper>
      <Button iconGap={50} icon={<Icon name="arrowRight" />}>
        Zapisz nowe hasło
      </Button>
    </FormWrapper>
  );
};

export default ResetPasswordForm;
