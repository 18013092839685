import { useNavigate } from "react-router-dom";

import React, { useCallback, useMemo } from "react";

import routes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import { useCourse } from "../Context";
import { Wrapper } from "./styles";

const CourseControls: React.FC = () => {
  const navigate = useNavigate();
  const {
    isNextTopicButtonDisabled,
    finishedTopicsIds,
    flatTopics,
    currentTopic,
    prevTopic,
    nextTopic,
    currentCourseProgram,
    isCourseFinished,
    availableTopicsIds,
    completeCurrentTopic,
  } = useCourse();

  const changeLessonFactory = useCallback(
    (courseId: number | undefined, topicId: number | undefined) => () => {
      if (courseId === undefined || topicId === undefined) return;

      const to = routes.course
        .replace(":courseId", `${courseId}`)
        .replace(":topicId", `${topicId}`);
      navigate(to);
    },
    [navigate]
  );

  const isTopicFinished = useMemo(
    () =>
      currentTopic?.id !== undefined &&
      (finishedTopicsIds ?? []).includes(currentTopic?.id),
    [finishedTopicsIds, currentTopic?.id]
  );

  const isLastTopic = useMemo(
    () =>
      currentTopic?.id !== undefined &&
      (flatTopics ?? []).at(-1)?.id === currentTopic?.id,
    [currentTopic?.id, flatTopics]
  );

  const isNextTopicAvailable = useMemo(
    () =>
      Boolean(
        nextTopic?.id !== undefined &&
          availableTopicsIds?.includes(nextTopic.id)
      ),
    [availableTopicsIds, nextTopic?.id]
  );

  return (
    <Wrapper>
      <Button
        disabled={!prevTopic}
        onClick={changeLessonFactory(currentCourseProgram?.id, prevTopic?.id)}
      >
        <Icon name="arrowLeft" />
      </Button>
      {!isTopicFinished && !isLastTopic && (
        <Button
          iconGap={22}
          disabled={isNextTopicButtonDisabled}
          icon={<Icon name="arrowRight" />}
          onClick={completeCurrentTopic}
        >
          Zaznacz jako wykonane
        </Button>
      )}
      {isTopicFinished && !isLastTopic && (
        <Button
          iconGap={22}
          disabled={!isNextTopicAvailable}
          icon={<Icon name="arrowRight" />}
          onClick={changeLessonFactory(currentCourseProgram?.id, nextTopic?.id)}
        >
          Przejdź dalej
        </Button>
      )}
      {!isTopicFinished && isLastTopic && (
        // TODO end course callback
        <Button
          disabled={isNextTopicButtonDisabled}
          onClick={completeCurrentTopic}
        >
          Ukończ
        </Button>
      )}
      {isLastTopic && isCourseFinished && (
        <Button
          iconGap={22}
          icon={<Icon name="download" />}
          onClick={() => navigate(routes.userPanel.certificates)}
        >
          Pobierz certyfikat
        </Button>
      )}
    </Wrapper>
  );
};

export default CourseControls;
