import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { DATE_FORMAT } from "const/dates";
import { differenceInWeeks, format } from "date-fns";

import React, { useContext } from "react";

import Button from "components/common/Button";
import Icon from "components/common/Icon";
import { Row } from "components/common/Row";
import { Stack } from "components/common/Stack";

import {
  downloadCertificate,
  handleRedirectToCourseSupplement,
} from "utils/certificate";

import {
  Card,
  StyledStack,
  StyledSubtitle,
  StyledText,
  StyledTitle,
} from "./styles";

type CertificateCardProps = {
  certificate: API.Certificate & { vars?: Record<string, string> };
};
const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  const { generateCertificate } = useContext(EscolaLMSContext);
  const startDate = certificate?.vars?.var_course_active_from
    ? new Date(certificate.vars.var_course_active_from)
    : "";
  const endDate = certificate?.vars?.var_course_active_to
    ? new Date(certificate.vars.var_course_active_to)
    : "";

  const [role = "", competence = "", level = ""] = certificate?.vars
    ?.var_course_categories_with_breadcrumbs
    ? certificate?.vars?.var_course_categories_with_breadcrumbs.split(". ")
    : [];

  return (
    <Card>
      <Stack>
        <div>
          <StyledTitle>
            Wyzwanie: <strong>{role || "brak danych"}</strong>
          </StyledTitle>
          {/* Protection for previously generated certificates */}
          {(competence || certificate.title) && (
            <StyledText>
              <span>Kompetencja: </span>
              <strong>
                {competence || certificate.title} {level && `(${level})`}
              </strong>
            </StyledText>
          )}
        </div>
        <Row $justifyContent="space-between" $flexWrap="wrap">
          <Stack>
            <StyledSubtitle>Start wyzwania:</StyledSubtitle>
            <Row $alignItems="center" $gap={8}>
              <Icon name="calendar" />
              <StyledText>
                {startDate ? format(startDate, DATE_FORMAT) : "Brak danych"}
              </StyledText>
            </Row>
          </Stack>
          <Stack>
            <StyledSubtitle>Czas trwania:</StyledSubtitle>
            <Row $alignItems="center" $gap={8}>
              <Icon name="progress" />

              <StyledText>
                {startDate && endDate
                  ? `${differenceInWeeks(endDate, startDate)} tygodni`
                  : "Brak danych"}
              </StyledText>
            </Row>
          </Stack>
          <Stack>
            <StyledSubtitle>Ukończenie:</StyledSubtitle>
            <Row $alignItems="center" $gap={8}>
              <Icon name="finishDate" />

              <StyledText>
                {endDate ? format(endDate, DATE_FORMAT) : "Brak danych"}
              </StyledText>
            </Row>
          </Stack>
          <StyledStack $alignItems="center" $gap={10}>
            <Button
              styleVariant="secondaryBlack"
              iconGap={63}
              icon={<Icon name="download" />}
              size="small"
              onClick={() =>
                downloadCertificate({
                  generateCertificate,
                  id: certificate.id,
                  title: certificate.title,
                })
              }
            >
              <StyledText>Pobierz certyfikat</StyledText>
            </Button>
            <Button
              styleVariant="linkBlack"
              size="small"
              onClick={handleRedirectToCourseSupplement}
            >
              Potrzebujesz suplement?
            </Button>
          </StyledStack>
        </Row>
      </Stack>
    </Card>
  );
};

export default CertificateCard;
