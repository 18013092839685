import { useEffect, useRef, useState } from "react";

const formatNumber = (num: number): string => {
  if (Number.isNaN(num) || num <= 0) {
    return "00";
  }

  return `${num}`.padStart(2, "0");
};
const getReturnValues = (countDown: number): [string, string, string] => {
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [formatNumber(hours), formatNumber(minutes), formatNumber(seconds)];
};

export function useCountdown(
  targetDate: string | Date,
  onCountdownEnd?: () => void
): [string, string, string] {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );
  // to prevent infinite loop
  const countdownEndCb = useRef(onCountdownEnd);

  useEffect(() => {
    if (countDown <= 0) {
      countdownEndCb.current?.();
      return;
    }

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, countDown]);

  return getReturnValues(countDown);
}
