import { Property } from "csstype";
import styled from "styled-components";

type Props = {
  $justifyContent?: Property.JustifyContent;
  $alignItems?: Property.AlignItems;
  $gap?: number;
  $flexWrap?: Property.FlexWrap;
};

export const Row = styled.div<Props>`
  display: flex;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? ""};
  align-items: ${({ $alignItems }) => $alignItems ?? ""};
  gap: ${({ $gap }) => $gap ?? "0"}px;
  flex-wrap: ${({ $flexWrap }) => $flexWrap ?? "nowrap"};
`;
