import React from "react";

import { Label, Progress, Track } from "./styles";

interface Props {
  /* progress in percent (0-100) */
  progress: number;
  disableLabel?: boolean;
}

const getSafeProgress = (value: number) => {
  if (value < 0 || Number.isNaN(value)) return 0;
  if (value > 100) return 100;

  return Math.round(value);
};

const CourseProgressBar: React.FC<Props> = ({ progress, disableLabel }) => {
  const safeProgress = getSafeProgress(progress);

  return (
    <Track>
      <Progress $value={safeProgress}>
        {!disableLabel && <Label>{safeProgress}%</Label>}
      </Progress>
    </Track>
  );
};

export default CourseProgressBar;
