import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { GroupedCompetencyChallengeResult } from "types/course";

import { useCallback, useContext, useMemo } from "react";

import { getFlatCategoriesSortedById } from "utils/categories";

const useCompetencyTestResults = (
  results: API.CompetencyTestResults | undefined | null
) => {
  const { categoryTree } = useContext(EscolaLMSContext);

  const flatCategoriesSortedById = useMemo(
    () => getFlatCategoriesSortedById(categoryTree.list ?? []),
    [categoryTree.list]
  );

  const findCategoryById = useCallback(
    (id: number): API.Category | undefined => flatCategoriesSortedById?.[id],
    [flatCategoriesSortedById]
  );

  const challengeTestResults = useMemo(
    () =>
      Object.values(
        (results?.value ?? []).reduce<
          Record<number, GroupedCompetencyChallengeResult>
        >((acc, result) => {
          const roleCategory = findCategoryById(result.parent_category_id);
          const category = findCategoryById(result.category_id);
          if (!roleCategory || !category) return acc;

          return {
            ...acc,
            [result.parent_category_id]: {
              roleCategory,
              results: [
                ...(acc?.[result.parent_category_id]?.results ?? []),
                {
                  category,
                  percentage: Math.round(
                    (result.score / result.max_score) * 100
                  ),
                  score: result.score,
                  max_score: result.max_score,
                  scale_category_id: result.scale_category_id,
                  matched_course: result.matched_course,
                },
              ],
            },
          };
        }, {})
      ),
    [findCategoryById, results?.value]
  );

  return {
    challengeTestResults,
  };
};

export default useCompetencyTestResults;
