import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Link } from "react-router-dom";

import React, { useContext } from "react";

import routeRoutes from "components/Routes/routes";

import { Container, Menu, MenuItem } from "./styles";

const UserMenu: React.FC = () => {
  const { logout } = useContext(EscolaLMSContext);
  return (
    <Container>
      <Menu>
        <MenuItem>
          <Link to={routeRoutes.userPanel.certificates}>Certyfikaty</Link>
        </MenuItem>
        <MenuItem>
          <Link to={routeRoutes.userPanel.metrics}>Metryka</Link>
        </MenuItem>
        <MenuItem>
          <Link to={routeRoutes.userPanel.accountDetails}>Dane konta</Link>
        </MenuItem>
        <MenuItem>
          <Link to={routeRoutes.userPanel.newsletter}>Newsletter</Link>
        </MenuItem>
        <MenuItem onClick={() => logout()}>Wyloguj się</MenuItem>
      </Menu>
    </Container>
  );
};

export default UserMenu;
