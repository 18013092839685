import styled from "styled-components";
import { device } from "theme/breakpoints";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 21px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray200};

  @media ${device.mobile} {
    position: fixed;
    inset: auto 0 0 0;
    border-radius: 0 0 10px 10px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 3px 6px #00000029;
  }
`;
