import { styled } from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledInput = styled.input`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: 12px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 5px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.45;
  }
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
  padding-left: 6px;
  margin-bottom: -4px;
`;

export const Error = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: red;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ShowPasswordBtn = styled.button`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
