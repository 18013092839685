import Image from "@escolalms/sdk/lib/react/components/Image";
import styled from "styled-components";

import React from "react";

const Wrapper = styled.div`
  &,
  & > .escolalms-image,
  & > .escolalms-image img {
    width: 100%;
    max-width: 100%;
  }
`;

interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "onError"> {
  path: string;
  size?: number;
  srcSizes?: number[];
  alt?: string;
  format?: string;
}

const ResponsiveImage = React.forwardRef<HTMLImageElement, Props>(
  (props, ref) => (
    <Wrapper data-testid={`responsive-image-${props.path}`}>
      <Image {...props} ref={ref} />
    </Wrapper>
  )
);

export default ResponsiveImage;
