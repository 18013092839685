import styled, { css } from "styled-components";

export const Wrapper = styled.aside<{
  $headerHeight: number;
  $mobile: boolean;
}>`
  min-width: 258px;
  padding: 8px 6px 8px 9px;

  height: 100%;
  max-height: ${({ $headerHeight }) => `calc(100vh - ${$headerHeight}px)`};

  overflow: auto;
  border-left: 1px solid ${({ theme }) => theme.colors.gray200};

  ${({ $mobile }) =>
    $mobile
      ? css`
          height: auto;
          background: ${({ theme }) => theme.colors.white};
          box-shadow: 0px 5px 10px #00000029;
        `
      : ""}
`;

export const List = styled.ul<{ $mobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: ${({ $mobile }) => ($mobile ? "auto" : "243px")};
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const MobileLessonListWrapper = styled.div<{
  $headerHeight: number;
}>`
  position: absolute;
  padding: 0px;
  margin: 0px;
  top: ${({ $headerHeight }) => `${$headerHeight}px`};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.backgroundOpacity};
`;
