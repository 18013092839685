import { styled } from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
  flex-wrap: wrap;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 18px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
