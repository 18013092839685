import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useEffect, useState } from "react";

import AuthLayout from "components/App/AuthLayout";
import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import bgImg from "assets/images/login-bg-img.png";

import { Desc } from "./styles";

const DeleteAccountConfirmationPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { confirmAccountDelete, logout, token } = useContext(EscolaLMSContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const deleteToken = searchParams.get("token");
  const userId = searchParams.get("id");

  const handleAccountDelete = useCallback(async () => {
    setLoading(true);
    try {
      const res = await confirmAccountDelete(
        String(userId),
        String(deleteToken)
      );
      if (res.success) {
        logout().then(() => {
          navigate(routeRoutes.login);
          toast.success("Konto zostało usunięte");
        });
      } else {
        toast.error("Wystąpił błąd podczas usuwania konta");
      }
    } catch (error) {
      console.log(error);
      toast.error("Wystąpił błąd podczas usuwania konta");
    } finally {
      setLoading(false);
    }
  }, [confirmAccountDelete, deleteToken, userId, logout, navigate]);

  useEffect(() => {
    if (!deleteToken || !userId || !token) {
      navigate(routeRoutes.login);
    }
  }, [deleteToken, userId, navigate, token]);

  return (
    <AuthLayout title="Usuwanie konta" backgroundImgSrc={bgImg}>
      <Desc>
        Uwaga! Ten proces jest nieodwracalny - wszystkie Twoje dane zostaną
        usunięte.
      </Desc>
      <div>
        <Button
          disabled={loading}
          onClick={handleAccountDelete}
          styleVariant="red"
          icon={<Icon name="delete" />}
          iconGap={104}
          size="small"
        >
          {loading ? "Usuwanie konta..." : "Usuń konto"}
        </Button>
      </div>
    </AuthLayout>
  );
};

export default DeleteAccountConfirmationPage;
