import { API } from "@escolalms/sdk/lib";

import React from "react";

import { Checkbox } from "components/common/Option/Checkbox";

import { getUniqueId } from "utils";

import DefaultQuestionLayout from "../DefaultQuestionLayout";

interface Props
  extends API.QuizQuestion_MultipleChoiceWithMultipleRightAnswers {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  values: Record<string, boolean>;
  hasQuizEnded?: boolean;
  resultScore?: number | null;
}

const MultipleChoiceWithMultipleRightAnswers: React.FC<Props> = ({
  id,
  question,
  title,
  options,
  onChange,
  onBlur,
  values,
  hasQuizEnded,
  resultScore,
}) => {
  const getChecked = (option: string) =>
    Object.entries(values).find(([key]) => key === option)?.[1];

  return (
    <DefaultQuestionLayout
      data-testid={`multiple-choice-multiple-right-${question}`}
      title={title}
      question={question}
      resultScore={resultScore}
      showScore={hasQuizEnded}
    >
      {options?.answers.map((option) => (
        <Checkbox
          label={option}
          disabled={hasQuizEnded}
          key={option}
          id={getUniqueId(option)}
          value={option}
          name={`${id}.${option}`}
          checked={getChecked(option)}
          onChange={onChange}
          onBlur={onBlur}
        />
      ))}
    </DefaultQuestionLayout>
  );
};

export default MultipleChoiceWithMultipleRightAnswers;
