import { API } from "@escolalms/sdk/lib";
import Image from "@escolalms/sdk/lib/react/components/Image";

import React from "react";

import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";

import bgImg from "assets/images/challenge-example-thumbnail.png";

import {
  Card,
  Content,
  Description,
  IconWrapper,
  ImageContainer,
  StyledButton,
  TestDone,
  Title,
} from "./styles";

type Props = {
  challenge: API.CompetencyChallenge;
  variant?: "horizontal" | "vertical";
};

const ChallengeCard: React.FC<Props> = ({
  challenge,
  variant = "vertical",
}) => (
  <Card $variant={variant}>
    <ImageContainer>
      {challenge.image_path ? (
        <Image path={challenge.image_path || ""} alt={challenge.name} />
      ) : (
        <img src={bgImg} alt={challenge.name} />
      )}
    </ImageContainer>
    <Content>
      {challenge.results_count > 0 && (
        <TestDone>
          <Icon name="finishTest" />
          Wykonano test
        </TestDone>
      )}
      <Title>
        <IconWrapper>
          <Icon name="arrowRightOrange" />
        </IconWrapper>
        {challenge.name}
      </Title>
      <Description>{challenge.summary}</Description>
      <StyledButton
        to={routeRoutes.challengeDetails.replace(":id", String(challenge.id))}
        styleVariant="primaryYellow"
        size="small"
      >
        <strong>Dowiedz się więcej</strong>
      </StyledButton>
    </Content>
  </Card>
);

export default ChallengeCard;
