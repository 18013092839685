import styled from "styled-components";

import { Row } from "components/common/Row";

import { useCountdown } from "hooks/useCountdown";

interface Props {
  targetDate: string | Date;
  onCountdownEnd?: () => void;
}

const Wrapper = styled(Row)`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.border};

  & p {
    margin: 0px;
  }
`;

export const Countdown: React.FC<Props> = ({ targetDate, onCountdownEnd }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate, onCountdownEnd);

  return (
    <Wrapper
      data-testid={`countdown-${targetDate}`}
      $alignItems="center"
      $justifyContent="space-between"
    >
      <p>
        {hours}:{minutes}:{seconds}
      </p>
    </Wrapper>
  );
};
