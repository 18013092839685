import { styled } from "styled-components";
import type { Color } from "theme";

import Icon from "components/common/Icon";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

export const Label = styled.span`
  opacity: 0.5;
`;

export const Text = styled.p`
  margin: 0;
  letter-spacing: 0;
`;

export const ColorIcon = styled(Icon)<{ $color: Color }>`
  color: ${({ theme, $color }) => theme.colors[$color]};
`;
