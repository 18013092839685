import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { useCallback, useContext, useState } from "react";

import TestScoreSidebar from "components/TestScoreSidebar";

import { PickedChallenge } from "hooks/usePickedChallenges";

import routes from "../Routes/routes";
import CourseInfo from "./CourseInfo";
import {
  ButtonsContainer,
  Card,
  CardTitle,
  IconPrimary,
  StyledButton,
  StyledLink,
} from "./styles";

type Props = PickedChallenge;
type MyCoursesType = {
  value: { ids: number[] };
};
const PickedChallengeCard: React.FC<Props> = ({
  challengeIdByRoleCategory,
  challenge,
  roleCategory,
  data,
}) => {
  const { myCourses } = useContext(EscolaLMSContext);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  const closeSidebar = useCallback(() => setSidebarOpened(false), []);

  const checkMyCourses = (suggestedCourseId: number) => {
    const foundCourse = !!(
      myCourses as unknown as MyCoursesType
    )?.value?.ids.find((item) => item === suggestedCourseId);
    return !foundCourse;
  };

  const checkSuggestCourse = (
    progress: API.CourseProgressItem,
    suggestedCourseId?: number
  ) => {
    const isCourseFinished = !!progress?.finish_date;

    if (isCourseFinished) {
      return !!suggestedCourseId ? checkMyCourses(suggestedCourseId) : false;
    }
    return true;
  };

  return (
    <>
      <Card>
        <CardTitle>
          <IconPrimary name="arrowRightOrange" />
          {roleCategory.name}
        </CardTitle>
        {data.map(
          (courseInfo) =>
            checkSuggestCourse(
              courseInfo.progress,
              courseInfo.suggestedCourseId
            ) && <CourseInfo key={courseInfo.course.id} {...courseInfo} />
        )}
        <ButtonsContainer>
          {challenge.results?.length > 0 && (
            <StyledButton
              onClick={() => setSidebarOpened(true)}
              styleVariant="primaryGray"
            >
              Wyniki testu poziomującego
            </StyledButton>
          )}
          {challengeIdByRoleCategory !== undefined && (
            <StyledLink
              to={routes.challengeDetails.replace(
                ":id",
                `${challengeIdByRoleCategory}`
              )}
            >
              Czytaj więcej
            </StyledLink>
          )}
        </ButtonsContainer>
      </Card>
      <TestScoreSidebar
        competencyChallenge={challenge}
        scoresOpened={sidebarOpened}
        closeScores={closeSidebar}
      />
    </>
  );
};

export default PickedChallengeCard;
