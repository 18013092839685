import React, { useCallback, useState } from "react";

import Icon from "components/common/Icon";

import {
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionTitle,
  AccordionWrapper,
} from "./styles";

type Props = {
  title: string;
  children: React.ReactNode;
};

const Accordion: React.FC<Props> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <AccordionWrapper>
      <AccordionHeader onClick={toggleAccordion}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon $isOpen={isOpen}>
          <Icon name="arrowRightSmall" />
        </AccordionIcon>
      </AccordionHeader>
      {isOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionWrapper>
  );
};

export default Accordion;
