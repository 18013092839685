import React from "react";

import { StyledLink, Text, Title, Wrapper } from "./styles";

type Props = {
  title: string;
  message: string;
  callToAction: { to: string; text: string };
};

const ErrorBox: React.FC<Props> = ({ title, message, callToAction }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Text>{message}</Text>
    <StyledLink to={callToAction.to}>{callToAction.text}</StyledLink>
  </Wrapper>
);

export default ErrorBox;
