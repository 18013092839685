import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const StyledSelect = styled.select`
  width: 100%;
  border-radius: 5px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 12px 13px;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.gray50};
`;

export const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-bottom: 3px;

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Error = styled.span`
  display: block;
  color: red;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 8px;
`;
