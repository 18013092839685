import { styled } from "styled-components";

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  gap: 13px;
  margin-bottom: 35px;
  & > div {
    width: 100%;
  }
  & > button {
    max-width: 116px;
    max-height: 43px;
  }
`;
