import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useState } from "react";

import Button from "components/common/Button";
import Input from "components/common/Input";
import { Row } from "components/common/Row";

import { getErrorMessage } from "utils";

import { changeUserNameSchema } from "validation/userDataFormValidation";

import {
  FormWrapper,
  InitialValueWrapper,
  InputWrapper,
  StyledInfoText,
  StyledStack,
} from "../styles";

type InitialValues = {
  first_name: string;
  last_name: string;
};

export const UserNameForm: React.FC = () => {
  const { user, updateProfile } = useContext(EscolaLMSContext);
  const [editName, setEditName] = useState(false);

  const initialValues: InitialValues = {
    first_name: user.value?.first_name || "",
    last_name: user.value?.last_name || "",
  };

  const onSubmit = useCallback(
    (values: InitialValues) => {
      updateProfile({
        first_name: values.first_name,
        last_name: values.last_name,
      })
        .then((response) => {
          if (response.success) {
            setEditName(false);
            toast.success("Dane zostały zmienione");
          } else {
            toast.error("Wystąpił błąd podczas zmiany danych");
          }
        })
        .catch((error) => {
          toast.error("Wystąpił błąd podczas zmiany danych");

          console.log(error);
        });
    },
    [updateProfile]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changeUserNameSchema,
    onSubmit: onSubmit,
  });

  return (
    <StyledStack>
      <Row
        $justifyContent="space-between"
        $alignItems="flex-end"
        $flexWrap="wrap"
        $gap={10}
      >
        {editName ? (
          <FormWrapper onSubmit={formik.handleSubmit}>
            <InputWrapper>
              <Input
                type="text"
                id="first_name"
                name="first_name"
                placeholder="Wpisz Imię"
                label="Imię"
                disabled={!editName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={getErrorMessage<InitialValues>("first_name", formik)}
              />
              <Input
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Wpisz Nazwisko"
                label="Nazwisko"
                disabled={!editName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                error={getErrorMessage<InitialValues>("last_name", formik)}
              />
            </InputWrapper>
            {editName && (
              <Button type="submit" styleVariant="secondaryGreen">
                Zapisz zmiany
              </Button>
            )}
          </FormWrapper>
        ) : (
          <InitialValueWrapper>
            <h4>Imię i nazwisko</h4>
            <p>{user.value?.name}</p>
          </InitialValueWrapper>
        )}
        <Button
          styleVariant="primaryText"
          onClick={() => setEditName(!editName)}
        >
          {editName ? "Anuluj" : "Edytuj"}
        </Button>
      </Row>
      <StyledInfoText>
        Imię i nazwisko znajdzie się na certyfikatach generowanych po ukończeniu
        wyzwań
      </StyledInfoText>
    </StyledStack>
  );
};
