function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

interface Options {
  waitFor?: number;
  attempts?: number;
}

export async function retryPromise<T>(
  promiseFn: () => Promise<T>,
  shouldBreak: (response: T) => boolean,
  { waitFor = 300, attempts = 5 }: Options = { waitFor: 300, attempts: 5 },
  attemptCount = 1
): Promise<T> {
  const response = await promiseFn();
  if (!shouldBreak(response) && attemptCount <= attempts) {
    await wait(waitFor);
    return retryPromise(
      promiseFn,
      shouldBreak,
      { waitFor, attempts },
      attemptCount + 1
    );
  }

  return response;
}
