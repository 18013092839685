import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";

import Routes from "components/Routes";

import ErrorPage from "pages/ErrorPage";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: "Rubik", sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: -0.25px;
    line-height: 24px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    letter-spacing: -0.35px;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
  }
  h2 {
    font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  }
  p, li, a, span {
    letter-spacing: -0.25px;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 24px;
  }
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};

    .Toastify__toast-icon {
      display: none;
    }
  }
  
  button {
    font-family: "Rubik", sans-serif;
  }
`;

function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <GlobalStyles />
      <Routes />
      <ToastContainer position="top-center" hideProgressBar />
    </Sentry.ErrorBoundary>
  );
}

export default App;
