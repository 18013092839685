import { Col } from "react-grid-system";

import React from "react";

import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";

import { scrollToTop } from "utils";

import LayoutContainer from "../Container";
import {
  CompanyDetailsItem,
  Copy,
  CopyContainer,
  Divider,
  Email,
  GoTopButton,
  Heading,
  IconsContainer,
  List,
  SocialsContainer,
  StyledFooter,
  StyledLink,
  StyledRow,
  StyledStack,
} from "./styles";

const Footer: React.FC = () => (
  <StyledFooter>
    <LayoutContainer>
      <StyledRow nogutter>
        <Col md={3}>
          <Icon name="logoFooter" />
        </Col>
        <Col md={3}>
          <Heading>Dane firmowe</Heading>
          <List>
            <li>Simpliciti sp. z o.o.</li>
            <li>ul. Bohdana Dobrzańskiego 3</li>
            <li>20-262 Lublin</li>
          </List>
          <List $marginTop={19}>
            <CompanyDetailsItem>KRS: 0001005157</CompanyDetailsItem>
            <CompanyDetailsItem>NIP: 9462722956</CompanyDetailsItem>
          </List>
        </Col>
        <Col md={2}>
          <Heading>Kontakt z nami</Heading>
          <Email href="mailto:example@email.com">biuro@simpliciti.pl</Email>
        </Col>
        <Col md={4}>
          <StyledStack>
            <SocialsContainer>
              <Heading>Obserwuj nas</Heading>
              <IconsContainer>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <Icon name="instagram" />
                </a>
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <Icon name="linkedin" />
                </a>
              </IconsContainer>
            </SocialsContainer>
            <CopyContainer>
              <StyledLink to={routeRoutes.privacyPolicy}>
                Polityka prywatności
              </StyledLink>
              <Divider />
              <StyledLink to={routeRoutes.terms}>Regulamin serwisu</StyledLink>
              <Copy>© 2023 Simpliciti</Copy>
            </CopyContainer>
          </StyledStack>
        </Col>
      </StyledRow>
    </LayoutContainer>
    <GoTopButton name="goTop" onClick={scrollToTop} />
  </StyledFooter>
);

export default Footer;
