import * as Yup from "yup";

import {
  checkboxValidation,
  emailValidation,
  fieldIsRequired,
  passwordValidation,
  repeatPasswordValidation,
} from "./common";

export const signUpSchema = Yup.object().shape({
  email: emailValidation,
  password: passwordValidation,
  repeatPassword: repeatPasswordValidation,
  firstName: fieldIsRequired,
  lastName: fieldIsRequired,
  privacyPolicy: checkboxValidation,
});

export const onboardingSchema = Yup.object().shape({
  education: fieldIsRequired,
  sector: Yup.string().notOneOf(["choose"], "To pole jest wymagane"),
});
