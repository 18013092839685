import styled from "styled-components";
import { ExtendableStyledComponent } from "types/common";

import * as React from "react";

export interface OptionType
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">,
    ExtendableStyledComponent {
  label?: React.ReactNode;
  type: "checkbox" | "radio";
}

const StyledDiv = styled.div<OptionType>`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 4px 0;
  transition: box-shadow 0.25s;

  &:hover {
    box-shadow: 0px 3px 9px #00000029;
  }

  label {
    display: inline-flex;
    align-items: center;
    padding: 22px;
    cursor: pointer;
    width: 100%;
  }

  span {
    margin-left: 15px;
    color: ${({ theme }) => theme.colors.black};
  }

  input {
    cursor: pointer;
    transition: border-color 0.5s;
    appearance: none;
    width: 26px;
    height: 26px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    position: relative;
    margin: 0;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: ${(props) => props.theme.checkboxRadius || "0"}px;
    ${(props) => (props.type === "radio" ? "border-radius:100%" : "")};
    z-index: 1;
  }

  input::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => (props.type === "radio" ? "26px" : "18px")};
    height: ${(props) => (props.type === "radio" ? "26px" : "18px")};
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: ${(props) => (props.type === "radio" ? "100%" : "0")};
    z-index: 0;
    opacity: 0;
  }

  input::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 50%;
    border-right: 2px solid ${(props) => props.theme.colors.white};
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%) rotate(45deg);
    width: 5px;
    height: 10px;
    z-index: 1;
    opacity: 0;
  }

  input:checked::after,
  input:checked::before {
    opacity: 1;
  }

  &:checked {
    opacity: 0.5;
  }
  color: ${({ theme }) => theme.colors.primary};
`;

export const Option: React.FC<OptionType> = (props) => {
  const { label, type } = props;

  if (label) {
    return (
      <StyledDiv type={type}>
        <label>
          <input {...props} type={type} />
          <span>{label}</span>
        </label>
      </StyledDiv>
    );
  }

  return (
    <StyledDiv type={type}>
      <input {...props} type={type} />
    </StyledDiv>
  );
};

const NewComponent = styled(Option)<{ type: "checkbox" | "radio" }>``;

export default NewComponent;
