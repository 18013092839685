import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import React, { useCallback, useContext } from "react";

import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Input from "components/common/Input";

import { getErrorMessage } from "utils";

import { forgotPasswordSchema } from "validation/resetPasswordFormValidation";

import { FormWrapper } from "./styles";

type InitialValues = {
  email: string;
};

type Props = {
  onSuccess: () => void;
};

const ForgotPasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const { forgot } = useContext(EscolaLMSContext);

  const handleSubmit = useCallback(
    async (values: InitialValues) => {
      try {
        const response = await forgot({
          email: values.email.toLowerCase(),
          return_url: `${window.location.origin}/#${routeRoutes.resetPassword}`,
        });
        if (response.success) {
          toast.success(
            "Na podany adres email został wysłany link do zresetowania hasła"
          );
          onSuccess();
        }
      } catch (error) {
        toast.error("Wystąpił błąd. Spróbuj ponownie.");
      }
    },
    [forgot, onSuccess]
  );

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <Input
        label="Adres email"
        name="email"
        id="email"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.email}
        error={getErrorMessage<InitialValues>("email", formik)}
      />
      <Button icon={<Icon name="arrowRight" />}>Wyślij</Button>
    </FormWrapper>
  );
};

export default ForgotPasswordForm;
