import { Link, LinkProps as LinkComponentProps } from "react-router-dom";
import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";

export type ButtonVariant =
  | "primaryBlack"
  | "primaryYellow"
  | "primaryGreen"
  | "primaryGray"
  | "primaryText"
  | "secondaryText"
  | "secondaryBlack"
  | "secondaryGray"
  | "secondaryGreen"
  | "linkRed"
  | "linkGreen"
  | "red"
  | "linkGray"
  | "linkBlack"
  | "menu";

type CommonProps = {
  $styleVariant?: ButtonVariant;
  $withIcon?: boolean;
  $size?: "small" | "large";
  $iconGap?: number;
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & CommonProps;

type LinkProps = LinkComponentProps & CommonProps;

const getButtonContentStyles = () => css`
  text-align: ${($withIcon) => ($withIcon ? "left" : "center")};
  display: ${($withIcon) => ($withIcon ? "flex" : "block")};
  justify-content: ${($withIcon) => ($withIcon ? "space-between" : "center")};
`;

const getButtonStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case "primaryBlack":
      return css`
        background-color: ${({ theme: { colors } }) => colors.text};
        color: ${({ theme: { colors } }) => colors.white};
      `;
    case "primaryYellow":
      return css`
        background-color: ${({ theme: { colors } }) => colors.primary};
        color: ${({ theme: { colors } }) => colors.text};
        & strong {
          font-weight: ${({ theme }) => theme.fontWeights.medium};
        }
      `;
    case "primaryGreen":
      return css`
        background-color: ${({ theme: { colors } }) => colors.green};
        color: ${({ theme: { colors } }) => colors.white};
        & strong {
          font-weight: ${({ theme }) => theme.fontWeights.medium};
        }
      `;
    case "primaryGray":
      return css`
        background-color: ${({ theme: { colors } }) => colors.gray50};
        color: ${({ theme: { colors } }) => colors.text};
      `;
    case "primaryText":
      return css`
        padding: 0;
        background-color: transparent;
        max-height: fit-content;
        max-width: fit-content;
        color: ${({ theme: { colors } }) => colors.greenAccent};
      `;
    case "secondaryText":
      return css`
        padding: 0;
        background-color: transparent;
        max-height: fit-content;
        max-width: fit-content;
        color: ${({ theme: { colors } }) => colors.gray300};
      `;
    case "secondaryBlack":
      return css`
        background-color: ${({ theme: { colors } }) => colors.text};
        color: ${({ theme: { colors } }) => colors.white};
        max-height: 34px;
        border-radius: 21px;
      `;
    case "secondaryGray":
      return css`
        background-color: ${({ theme: { colors } }) => colors.gray500};
        color: ${({ theme: { colors } }) => colors.white};
      `;
    case "secondaryGreen":
      return css`
        background-color: ${({ theme: { colors } }) => colors.greenAccent};
        color: ${({ theme: { colors } }) => colors.white};
      `;
    case "linkRed":
      return css`
        padding: 0;
        background-color: transparent;
        color: ${({ theme: { colors } }) => colors.red};
        &:hover {
          text-decoration: underline;
        }
      `;
    case "linkGreen":
      return css`
        background-color: transparent;
        padding: 0;
        color: ${({ theme: { colors } }) => colors.greenAccent};
        &:hover {
          text-decoration: underline;
        }
      `;
    case "linkGray":
      return css`
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        background-color: transparent;
        padding: 0;
        color: ${({ theme: { colors } }) => colors.gray300};
        text-decoration: underline;
        & > p {
          font-size: ${({ theme }) => theme.fontSizes.xxsmall}!important;
        }
        &:hover {
          color: ${({ theme: { colors } }) => colors.text};
        }
      `;
    case "linkBlack":
      return css`
        font-weight: ${({ theme }) => theme.fontWeights.normal};
        background-color: transparent;
        padding: 0;
        color: ${({ theme: { colors } }) => colors.text};
        text-decoration: underline;
        & > p {
          font-size: ${({ theme }) => theme.fontSizes.xxsmall}!important;
        }
        &:hover {
          color: ${({ theme: { colors } }) => colors.text};
        }
      `;
    case "red":
      return css`
        background-color: #b50000;
        color: ${({ theme: { colors } }) => colors.white};
      `;
    case "menu":
      return css`
        background-color: ${({ theme }) => theme.colors.black};
        color: ${({ theme: { colors } }) => colors.white};
        padding: 0px;
        height: 33px;
        width: 42px;
        transition: 0.3s;
        & picture {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          aspect-ratio: unset;
        }
        &:hover {
          opacity: 0.8;
        }
      `;
    default:
      return css`
        background-color: ${({ theme: { colors } }) => colors.text};
        color: ${({ theme: { colors } }) => colors.white};
      `;
  }
};

export const StyledButton = styled.button<ButtonProps>`
  border: none;
  border-radius: 5px;
  align-items: center;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  cursor: pointer;
  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  padding: ${({ $size }) => ($size === "small" ? "8px 19px" : "12px 20px")};
  font-size: ${({ theme, $size }) =>
    $size === "small" ? theme.fontSizes.medium : theme.fontSizes.large};

  gap: ${({ $iconGap }) => $iconGap ?? 0}px;

  ${({ $styleVariant }) => getButtonStyles($styleVariant ?? "primaryBlack")}

  ${getButtonContentStyles()}

  @media ${device.smallTablet} {
    border-radius: 7px;
  }
`;

export const StyledLink = styled(Link)<LinkProps>`
  text-decoration: none;
  border-radius: 5px;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
  letter-spacing: normal;

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  padding: ${({ $size }) => ($size === "small" ? "8px 19px" : "12px 20px")};
  font-size: ${({ theme, $size }) =>
    $size === "small" ? theme.fontSizes.medium : theme.fontSizes.large};

  gap: ${({ $iconGap }) => $iconGap ?? 0}px;

  ${({ $styleVariant }) => getButtonStyles($styleVariant ?? "primaryBlack")}

  ${getButtonContentStyles()}
`;
