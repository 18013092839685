import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { CERTIFICATE_PROBLEM } from "const/links";
import { styled } from "styled-components";

import React, { useContext, useEffect, useState } from "react";

import UserPanelLayout from "components/App/UserPanelLayout";
import CertificateCard from "components/CertificateCard";
import { Pagination } from "components/Pagination";
import InfoText from "components/common/InfoText";
import { Stack } from "components/common/Stack";

const StyledInfoText = styled(InfoText)`
  margin-bottom: 17px;
  padding-right: 12px;
`;

const StyledAnchor = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const CertificatesPage: React.FC = () => {
  const { certificates, fetchCertificates } = useContext(EscolaLMSContext);
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = certificates.list?.meta.last_page || 1;
  useEffect(() => {
    fetchCertificates({ page: currentPage, per_page: 5 });
  }, [currentPage, fetchCertificates]);

  return (
    <UserPanelLayout title="Certyfikaty">
      <div data-testid="certificates-page">
        <p>
          {certificates?.list?.data.length === 0
            ? "Nie masz jeszcze żadnych certyfikatów"
            : "Otrzymane po ukończeniu Twoich wyzwań"}
        </p>
        <StyledInfoText>
          Jeśli na Twoich certyfikatach są nieaktualne dane (ponieważ edytowałeś
          je później) to w celu otrzymania zaktualizowanej wersji{" "}
          <StyledAnchor href={`mailto:${CERTIFICATE_PROBLEM}`}>
            zgłoś tutaj
          </StyledAnchor>
        </StyledInfoText>
        <Stack $gap={8}>
          {certificates &&
            certificates?.list?.data &&
            certificates.list?.data.length > 0 &&
            certificates?.list?.data.map((certificate: API.Certificate) => (
              <div key={certificate.id}>
                <CertificateCard certificate={certificate} />
              </div>
            ))}
        </Stack>
      </div>
      {lastPage > 1 && (
        <Pagination
          pageCount={lastPage}
          setCurrentPage={(item: number) => setCurrentPage(item)}
        />
      )}
    </UserPanelLayout>
  );
};

export default CertificatesPage;
