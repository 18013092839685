import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Link } from "react-router-dom";
import { Color } from "theme";

import React, { useContext } from "react";

import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";

import useMediaQuery from "hooks/useMediaQuery";

import UserMenu from "./UserMenu";
import {
  LogoWrapper,
  MobileIcon,
  StyledHeader,
  UserContainer,
  UserEmail,
  UserMenuWrapper,
} from "./styles";

type HeaderProps = {
  barColor?: Color;
};

const Header: React.FC<HeaderProps> = ({ barColor }) => {
  const mobileView = useMediaQuery("mobile");
  const { token } = useContext(EscolaLMSContext);

  return (
    <StyledHeader $barColor={barColor}>
      <LogoWrapper>
        <Link to={routeRoutes.home}>
          <Icon name={mobileView ? "logoWhite" : "logoHorizontal"} />
        </Link>
      </LogoWrapper>
      {!!token && (
        <UserContainer>
          {mobileView ? (
            <MobileIcon name="userMobile" />
          ) : (
            <>
              <Icon name="user" />
              <UserEmail>Twoje konto</UserEmail>
            </>
          )}
          <UserMenuWrapper>
            <UserMenu />
          </UserMenuWrapper>
        </UserContainer>
      )}
    </StyledHeader>
  );
};

export default Header;
