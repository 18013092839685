import { CSSTransition } from "react-transition-group";

import React, { useRef } from "react";

import { Stack } from "components/common/Stack";

import { LeftPaddingStack, RelativeText, ScoreIndicator } from "../styles";

type ResultScore = null | number;

export type ResultScoreState = "positive" | "neutral" | "negative";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  question: string;
  children: React.ReactNode;
  showScore?: boolean;
  resultScore?: ResultScore;
}

function determineResultScoreState(score?: ResultScore): ResultScoreState {
  if (!score) return "neutral";
  else if (score > 0) return "positive";
  else if (score < 0) return "negative";

  return "neutral";
}

const resultScoreSign: Record<ResultScoreState, string> = {
  positive: "+",
  neutral: "",
  negative: "-",
};

const DefaultQuestionLayout: React.FC<Props> = ({
  title,
  question,
  children,
  showScore,
  resultScore,
  ...props
}) => {
  const resultScoreState = determineResultScoreState(resultScore);
  const scoreRef = useRef<HTMLSpanElement | null>(null);
  return (
    <Stack {...props} $gap={6}>
      <Stack $gap={2}>
        {title && <p>{title}</p>}
        <RelativeText>
          {question}
          <CSSTransition
            in={showScore}
            nodeRef={scoreRef}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <ScoreIndicator ref={scoreRef} $scoreState={resultScoreState}>
              {`${resultScoreSign[resultScoreState]}${resultScore ?? 0}`}
            </ScoreIndicator>
          </CSSTransition>
        </RelativeText>
      </Stack>
      <Stack $gap={4}>{children}</Stack>
    </Stack>
  );
};

export default DefaultQuestionLayout;
