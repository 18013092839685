import { API } from "@escolalms/sdk/lib";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import React from "react";

import CourseAuthor from "components/CourseAuthor";
import { Stack } from "components/common/Stack";

type Props = {
  authors?: API.Author[];
};

const StyledSidebar = styled.aside`
  position: sticky;
  top: 30px;
  border-left: 1px solid #e3e3e3;
  padding: 36px 0 170px 40px;

  @media ${device.smallTablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    top: unset;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px -3px 6px #00000029;
    z-index: 999;
    border: none;
    padding: 8px 12px 8px 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  @media ${device.smallMobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
  }
`;

const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 15px;

  @media ${device.smallTablet} {
    margin-bottom: 0;
  }
`;

const ChallengeSidebar: React.FC<Props> = ({ authors }) => (
  <StyledSidebar>
    <SectionTitle>Kto prowadzi wyzwanie:</SectionTitle>
    <Stack $gap={8}>
      {authors?.map((item) => (
        <CourseAuthor
          avatar={item.path_avatar}
          name={`${item.first_name} ${item.last_name}`}
          position={String(item.author_position || "")}
        />
      ))}
    </Stack>
  </StyledSidebar>
);

export default ChallengeSidebar;
