import type { API } from "@escolalms/sdk/lib";

import type React from "react";

import routeRoutes from "components/Routes/routes";
import Checkbox, { type CheckboxProps } from "components/common/Checkbox";

import { convertSnakeCaseToCamelCase } from "utils";

import { CheckboxWrapper, StyledLink } from "./styles";

const CONSENT_CUSTOM_CONTENT: Record<string, { text: string; to: string }> = {
  terms_of_use: {
    text: "regulamin",
    to: routeRoutes.terms,
  },
  privacy_policy: {
    text: "politykę prywatności",
    to: routeRoutes.privacyPolicy,
  },
};

const ConsentFieldLabel: React.FC<{ field: API.Metadata }> = ({ field }) => {
  if (["terms_of_use", "privacy_policy"].includes(field.name)) {
    const content = CONSENT_CUSTOM_CONTENT?.[field.name];

    return (
      <>
        Akceptuję{" "}
        <StyledLink to={content?.to} target="_blank">
          {content?.text}
        </StyledLink>
      </>
    );
  }

  return <>{field.default}</>;
};

interface Props extends Omit<CheckboxProps, "name" | "id" | "label"> {
  field: API.Metadata;
}

const ConsentField: React.FC<Props> = ({ field, ...checkboxProps }) => {
  const camelCaseName = convertSnakeCaseToCamelCase(field?.name ?? "");

  return (
    <CheckboxWrapper>
      <Checkbox
        name={camelCaseName}
        id={camelCaseName}
        label={<ConsentFieldLabel field={field} />}
        {...checkboxProps}
      />
    </CheckboxWrapper>
  );
};

export default ConsentField;
