import { API } from "@escolalms/sdk/lib";

import React, { useCallback, useMemo, useState } from "react";

import routeRoutes from "components/Routes/routes";
import TestScoreSidebar from "components/TestScoreSidebar";
import Button from "components/common/Button";

import bgImg from "assets/images/woman_board.svg";

import { Baner, BgImg, RepeatTest, StyledRow, TextContainer } from "./styles";

type Props = {
  complexChallenge: API.ComplexCompetencyChallenge;
};

const TestBanner: React.FC<Props> = ({ complexChallenge }) => {
  const [showScore, setShowScore] = useState(false);

  const areResultsPresent = complexChallenge.results_count > 0;
  const diagnosticTestRoute = useMemo(
    () =>
      routeRoutes.competencyTest
        .replace(":challengeId", String(complexChallenge.id))
        .replace(":quizId", String(complexChallenge.quiz_id))
        .replace("/:questionId", ""),
    [complexChallenge.id, complexChallenge.quiz_id]
  );

  const closeScores = useCallback(() => setShowScore(false), []);

  return (
    <Baner>
      {areResultsPresent ? (
        <>
          <TextContainer>
            <h3>Test wykonany</h3>
            <StyledRow>
              <Button
                styleVariant="primaryGreen"
                onClick={() => setShowScore(true)}
              >
                <strong>Sprawdź wyniki</strong>
              </Button>
              <RepeatTest>
                <h4>Możesz wykonać</h4>
                <Button
                  to={diagnosticTestRoute}
                  styleVariant="linkBlack"
                  size="small"
                >
                  <span>jeszcze raz</span>
                </Button>
              </RepeatTest>
            </StyledRow>
          </TextContainer>
          <BgImg alt="" src={bgImg} />
        </>
      ) : (
        <>
          <TextContainer>
            <h3>Nie wiesz, którą rolę wybrać?</h3>
            <p>Nie ma problemu! Rozwiąż bezpłatny test diagnostyczny</p>
          </TextContainer>
          <BgImg alt="" src={bgImg} />
          <Button
            to={diagnosticTestRoute}
            styleVariant="primaryBlack"
            style={{
              padding: "9px 45px",
            }}
          >
            <strong>Rozpocznij test</strong>
          </Button>
        </>
      )}
      <TestScoreSidebar
        competencyChallenge={complexChallenge}
        scoresOpened={showScore}
        closeScores={closeScores}
      />
    </Baner>
  );
};

export default TestBanner;
