import { API } from "@escolalms/sdk/lib";
import { CompetencyTestResult } from "types/course";
import { QuizScoreProps } from "types/gift-quiz";

export const getFlatTopics = (lessons: API.Lesson[]): API.Topic[] =>
  lessons.reduce<API.Topic[]>(
    (acc, curr) => [
      ...acc,
      ...(curr.lessons ? getFlatTopics(curr.lessons) : []),
      ...(curr.topics ?? []),
    ],
    []
  );

export const getFlatLessons = (lessons: API.Lesson[]): API.Lesson[] =>
  lessons.reduce<API.Lesson[]>(
    (acc, curr) => [...acc, ...getFlatLessons(curr?.lessons ?? []), curr],
    []
  );

export const getLessonParentsIds = (
  flatLessons: API.Lesson[],
  currentLesson: API.Lesson,
  result: number[] = []
): number[] => {
  const parentsIds: number[] = [...result];

  const parentLesson = flatLessons.find((l) =>
    l?.lessons?.find((innerL: API.Lesson) => innerL?.id === currentLesson?.id)
  );

  if (parentLesson) {
    parentsIds.unshift(parentLesson.id);
    return getLessonParentsIds(flatLessons, parentLesson, parentsIds);
  }

  return parentsIds;
};

export const getPreviousProgressedCourseProgressItemElement = (
  progressItemElements: API.CourseProgressItemElement[]
): API.CourseProgressItemElement => {
  const lastStartedProgressItemElement = progressItemElements
    ?.filter(
      ({ status }) =>
        status === API.CourseProgressItemElementStatus.IN_PROGRESS ||
        status === API.CourseProgressItemElementStatus.COMPLETE
    )
    ?.sort(
      (a, b) =>
        new Date(b.started_at as string).getTime() -
        new Date(a.started_at as string).getTime()
    )[0];

  if (!lastStartedProgressItemElement) {
    const firstTopic = progressItemElements?.[0];
    const lastCompletedTopic = progressItemElements
      ?.filter(
        (topic) => topic.status === API.CourseProgressItemElementStatus.COMPLETE
      )
      .sort(
        (a, b) =>
          new Date(b.finished_at as string).getTime() -
          new Date(a.finished_at as string).getTime()
      )[0];

    return firstTopic ?? lastCompletedTopic;
  }

  return lastStartedProgressItemElement;
};

export const getPrevNextTopic = (
  topic_id: number,
  topics: API.Topic[],
  dir: "prev" | "next" = "prev"
): API.Topic | undefined => {
  const currIndex = topics.findIndex((topic) => topic.id === topic_id);

  const topicsMap = new Map([
    ["prev", topics?.[currIndex - 1]],
    ["next", topics?.[currIndex + 1]],
  ]);

  return topicsMap.get(dir);
};

export const checkQuizScore = ({
  max_score,
  min_pass_score,
  result_score,
}: QuizScoreProps): boolean =>
  (result_score / max_score) * 100 >= min_pass_score;

export const getCompetenceResultLevel = (competence: CompetencyTestResult) =>
  competence.category?.subcategories?.find(
    (item) => item.id === competence.scale_category_id
  );

export const findCompetenceById = (
  id: number,
  categories: API.Category[]
): API.Category | undefined => {
  for (const category of categories) {
    if (category.id === id) {
      return category;
    }
    if (category.subcategories && category.subcategories.length > 0) {
      const found = findCompetenceById(id, category.subcategories);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const roundTo = (val: number, places = 2, divider?: number): number => {
  if (!val || typeof val !== "number") return 0;
  const returnedValue =
    Math.round(val * Math.pow(10, places)) / Math.pow(10, places);
  if (divider) {
    return returnedValue / divider;
  }
  return returnedValue;
};

export const formatPrice = (price: number | undefined, taxRate?: number) => {
  if (!price) return 0;
  const roundedPrice = taxRate
    ? roundTo(price, 2, 100) * (1 + taxRate / 100)
    : roundTo(price, 2, 100);

  return roundedPrice.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
