import styled, { DefaultTheme } from "styled-components";
import { device } from "theme/breakpoints";

import { Row } from "components/common/Row";
import { Stack } from "components/common/Stack";

import DefaultQuestionLayout, {
  ResultScoreState,
} from "./questions/DefaultQuestionLayout";

//GiftQuizPlayer Quizzes/index.tsx
export const GiftQuizPlayerWrapper = styled.div`
  & > .spinner__overlay {
    min-height: 529px;
  }
`;

export const StartButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const StyledDefaultLayout = styled(DefaultQuestionLayout)`
  & input {
    height: 42px;
    padding: 10px;
    background: ${({ theme }) => theme.colors.gray50};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    opacity: 1;
    outline: none;
    &:focus-visible {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
  & > picture {
    aspect-ratio: 0;
    position: absolute;
    right: 24px;
    top: 36px;
    cursor: pointer;
  }
`;

//GiftQuizScore
export const GiftQuizScoreWrapper = styled(Stack)<{ passedQuiz?: boolean }>`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme: { colors }, passedQuiz }) =>
      passedQuiz !== undefined
        ? passedQuiz
          ? colors.greenDark
          : colors.red
        : colors.border};

  & p {
    margin: 0;
  }
`;

//GiftQuizPlayerContent
export const GiftQuizPlayerContentForm = styled.form<{
  $singleQuestion?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ $singleQuestion }) => ($singleQuestion ? "8px" : "16px")};
  background-color: ${({ theme }) => theme.colors.border};
  border-radius: 10px 10px 0 0;
  padding: ${({ $singleQuestion }) =>
    $singleQuestion ? "0 13px 40px" : "25px 13px"};
`;

export const CloseButton = styled.span`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme }) => theme.colors.gray200};

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;
export const AttemptsTooltipWrapper = styled(Row)`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const GiftQuizHeader = styled(Row)`
  margin-top: 12px;
  padding: 12px;
  border: none;
  background-color: ${({ theme }) => theme.primaryColor};
  & h4 {
    margin: 0;
  }
`;

//DefaultQuestionLayout
export const RelativeText = styled.p`
  position: relative;
  width: max-content;
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-left: 25px;
  word-wrap: break-word;
  @media ${device.tablet} {
    margin: 0 0 30px;
    padding-left: 21px;
    width: unset;
  }
`;

const scoreBackgrounds = (
  scoreState: ResultScoreState,
  theme: DefaultTheme
) => {
  switch (scoreState) {
    case "positive":
      return theme.colors.greenDark;
    case "negative":
      return theme.colors.red;
    default:
      return theme.colors.gray100;
  }
};

export const ScoreIndicator = styled.span<{ $scoreState: ResultScoreState }>`
  position: absolute;
  top: -8px;
  right: -12px;
  padding-inline: 4px;
  border-radius: 4px;
  background-color: ${({ theme, $scoreState }) =>
    scoreBackgrounds($scoreState, theme)};
  line-height: 1.3;

  &.fade-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  &.fade-enter-done {
    opacity: 1;
    transform: scale(1);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
  }
`;

export const LeftPaddingStack = styled(Stack)`
  padding-left: 12px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.border};
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  padding: 16px 25px;
  border-radius: 0 0 10px 10px;

  @media ${device.smallMobile} {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    button {
      width: 100%;
    }
  }
`;

export const ProgressContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ProgressTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0;
`;
