import React from "react";

import { RadioInputBox, RadioInputContainer, RadioInputLabel } from "./styles";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

const RadioInput: React.FC<Props> = ({ label, ...props }) => {
  return (
    <RadioInputContainer>
      <RadioInputBox type="radio" {...props} />
      <RadioInputLabel>{label}</RadioInputLabel>
    </RadioInputContainer>
  );
};

export default RadioInput;
