import { Row } from "react-grid-system";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const Container = styled.div`
  min-height: 100vh;
  overflow-x: hidden;
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.mobile} {
    padding: 20px 36px;
  }
`;

export const BackgroundImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 130px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledRow = styled(Row)`
  min-height: 100vh;
  @media ${device.mobile} {
    min-height: fit-content;
  }
`;
