import { EscolaLMSContextProvider } from "@escolalms/sdk/lib/react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import WebFont from "webfontloader";

import React, { useEffect } from "react";

import App from "./App";

export const API_URL =
  process.env.REACT_APP_API_URL || window.REACT_APP_API_URL || "";
const SENTRY_DSN =
  process.env.REACT_APP_SENTRYDSN || window.REACT_APP_SENTRYDSN || "";

if (SENTRY_DSN && !window.location.hostname.includes("localhost")) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of React router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0, // Set the sample rate for tracing (1.0 means capture all transactions)
  });
}

WebFont.load({
  google: {
    families: ["Rubik:ital,wght@0,400;0,500;0,600;0,400;1,500;1,600;1"],
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <EscolaLMSContextProvider apiUrl={API_URL} initialFetch={false}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </EscolaLMSContextProvider>
  </React.StrictMode>
);
