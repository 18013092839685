import { styled } from "styled-components";

export const Container = styled.div`
  .input-range {
    &__label {
      &--min,
      &--max {
        display: none;
      }

      &-container {
        background-color: ${({ theme }) => theme.colors.text};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 3px;
        font-size: ${({ theme }) => theme.fontSizes.small};
        padding: 2px 5px;
        top: -11px;
        left: -46%;

        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border: 5px solid transparent;
          border-top-color: ${({ theme }) => theme.colors.text};
        }
      }
    }

    &__slider {
      width: 21px;
      height: 21px;
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      top: -3px;
      left: -2px;
    }

    &__track {
      &--active {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Marks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Mark = styled.div`
  width: 1px;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.text};
`;
