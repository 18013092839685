const size = {
  smallMobile: "420px",
  mobile: "768px",
  smallTablet: "991px",
  tablet: "1024px",
  laptop: "1440px",
};

export const device = {
  smallMobile: `(max-width: ${size.smallMobile})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  smallTablet: `(max-width: ${size.smallTablet})`,
  laptop: `(max-width: ${size.laptop})`,
};
