import { Col } from "react-grid-system";

import React from "react";

import AuthSidebar from "../AuthSidebar";
import { BackgroundImg, Container, ContentWrapper, StyledRow } from "./styles";

type Props = {
  children: React.ReactNode;
  backgroundImgSrc: string;
  title: string;
};

const AuthLayout: React.FC<Props> = ({ children, backgroundImgSrc, title }) => {
  return (
    <Container>
      <StyledRow>
        <Col lg={3}>
          <AuthSidebar />
        </Col>
        <Col offset={{ sm: 1 }} lg={4}>
          <ContentWrapper>
            <h1>{title}</h1>
            {children}
          </ContentWrapper>
        </Col>
        <Col lg={4}>
          <BackgroundImg
            data-testid="auth-bg-img"
            src={backgroundImgSrc}
            alt=""
          />
        </Col>
      </StyledRow>
    </Container>
  );
};

export default AuthLayout;
