import React from "react";

import Icon from "components/common/Icon";

import { Sidebar } from "./styles";

const AuthSidebar: React.FC = () => {
  return (
    <Sidebar data-testid="auth-sidebar">
      <Icon data-testid="logo-icon" name="logoWhite" />
    </Sidebar>
  );
};

export default AuthSidebar;
