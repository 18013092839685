import { styled } from "styled-components";

export const FormWrapper = styled.form`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  max-width: 410px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  &:first-of-type {
    margin-bottom: 22px;
  }
`;

export const ResetPasswordLink = styled.span`
  margin: 11px 0 56px;
  display: block;

  & > button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 0 0 6px;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};

    &:hover {
      text-decoration: underline;
    }
  }
`;
