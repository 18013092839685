import React, { useState } from "react";

import Icon from "../Icon";
import {
  Error,
  InputContainer,
  InputWrapper,
  Label,
  ShowPasswordBtn,
  StyledInput,
} from "./styles";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string;
};

const Input: React.FC<Props> = ({ label, error, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputContainer>
      {label && <Label>{label}</Label>}
      <InputWrapper>
        <StyledInput
          {...props}
          data-testid="input"
          type={showPassword ? "text" : props.type}
        />
        {props.type === "password" && (
          <ShowPasswordBtn
            type="button"
            data-testid="toggle"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon name="showPassword" />
          </ShowPasswordBtn>
        )}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </InputContainer>
  );
};

export default Input;
