import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";

import { Row } from "components/common/Row";

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #00000040;
  z-index: 998;
  left: 0;
  top: 0;
`;

export const StyledSidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 772px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px 0 0 15px;
  z-index: 999;
  height: 100%;
  padding: 33px 35px;
  overflow-y: auto;

  @media ${device.tablet} {
    max-width: unset;
    border-radius: 0;
  }
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ButtonsHeader = styled(Row)`
  margin-top: -12px;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  padding-bottom: 30px;
`;

export const CloseButton = styled.button<{ backButton?: boolean }>`
  ${({ backButton }) =>
    !backButton &&
    css`
      position: fixed;
      top: 19px;
      right: 24px;
    `}

  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.gray300};
  }
`;

export const Content = styled.div`
  margin-top: 24px;
`;
