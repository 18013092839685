import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import Button from "components/common/Button";

export const CourseInfoWrapper = styled.div`
  display: flex;

  border-bottom: 1px dashed ${({ theme }) => theme.colors.gray400};

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;

    border-bottom: none;
    gap: 24px;
  }
`;

export const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;

  max-width: 710px;
  width: 100%;
  padding: 0 35px 35px 0;

  @media ${device.mobile} {
    border-bottom: 1px dashed ${({ theme }) => theme.colors.gray400};
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

export const PairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 15px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledButton = styled(Button)`
  max-width: max-content;

  @media ${device.mobile} {
    max-width: unset;
  }
`;

export const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 0 0 35px 35px;

  border-left: 1px dashed ${({ theme }) => theme.colors.gray400};

  @media ${device.mobile} {
    width: 100%;
    padding: 0;

    border-left: none;
  }
`;

export const ListTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ListItem = styled.li`
  display: flex;
  gap: 11px;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};

  &:before {
    content: "";
    width: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const ListEmpty = styled.p`
  margin: 0;
`;
