import { API } from "@escolalms/sdk/lib";
import { DATE_FORMAT } from "const/dates";
import { format } from "date-fns";
import { LessonListLessonState } from "types/course";

import React, { useCallback, useEffect, useMemo, useState } from "react";

import { getFlatTopics } from "utils/course";

import { useCourse } from "../../Context";
import CourseLessonCard from "../CourseLessonCard";
import CourseTopics from "../CourseTopics";
import { List, Wrapper } from "./styles";

interface Props {
  index: number;
  lesson: API.Lesson;
  children: React.ReactNode;
}
const CourseLessons: React.FC<Props> = ({ lesson, index, children }) => {
  const {
    currentLesson,
    currentLessonParentsIds,
    availableTopicsIds,
    finishedTopicsIds,
  } = useCourse();

  const isRootAncestor = useMemo(() => {
    if (currentLessonParentsIds?.[0] === undefined)
      return currentLesson?.id === lesson.id;

    return currentLessonParentsIds[0] === lesson.id;
  }, [currentLesson?.id, currentLessonParentsIds, lesson.id]);

  const isAncestor = useMemo(
    () =>
      currentLessonParentsIds?.includes(lesson.id) ||
      currentLesson?.id === lesson.id,
    [currentLesson?.id, currentLessonParentsIds, lesson.id]
  );

  const isLessonFinished = useMemo(() => {
    const lessonFlatTopics = getFlatTopics([lesson]);

    return (
      lessonFlatTopics.length > 0 &&
      lessonFlatTopics.every((t) => finishedTopicsIds?.includes(t.id))
    );
  }, [finishedTopicsIds, lesson]);

  const isAvailable = useMemo(() => {
    const lessonFlatTopics = getFlatTopics([lesson]);

    return (
      lessonFlatTopics.length > 0 &&
      lessonFlatTopics.some((t) => availableTopicsIds?.includes(t.id))
    );
  }, [availableTopicsIds, lesson]);

  const state: LessonListLessonState = useMemo(() => {
    if (isRootAncestor) return "current_root";
    if (isAncestor) return "current_within";
    if (isAvailable) return "available";
    if (isLessonFinished) return "finished";

    return "locked";
  }, [isRootAncestor, isAncestor, isAvailable, isLessonFinished]);

  // open on enter to another lesson
  useEffect(() => {
    if (
      currentLesson?.id !== lesson.id &&
      !currentLessonParentsIds?.includes(lesson.id)
    )
      return;

    setOpen(true);
  }, [currentLesson?.id, lesson.id, currentLessonParentsIds]);

  const [open, setOpen] = useState(state.includes("current"));
  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);
  const hasTopics = (lesson.topics ?? []).length > 0;

  return (
    <Wrapper data-testid="agenda-lessons" $withBorder={isRootAncestor}>
      <CourseLessonCard
        state={state}
        onClick={toggleOpen}
        hasTopics={hasTopics}
        title={lesson.title}
        index={index}
        tooltip={
          state === "locked" && lesson.active_from
            ? `Aktywne od ${format(new Date(lesson.active_from), DATE_FORMAT)}`
            : undefined
        }
      />
      {open && (
        <List>
          {children}
          {lesson.topics?.map((topic, topicIndex) => (
            <CourseTopics key={topic.id} topic={topic} index={topicIndex + 1} />
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default CourseLessons;
