import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const FormWrapper = styled.form`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  max-width: 410px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const InputWrapper = styled.div`
  margin-bottom: 22px;
  width: 100%;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  margin-bottom: 5px;

  &:first-of-type {
    margin-top: 33px;
  }

  &:last-of-type {
    margin-bottom: 38px;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};

  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
