import { styled } from "styled-components";

import React from "react";

type Props = {
  margin?: string;
};

const StyledDivider = styled.div`
  height: 2px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #a7a7a76d 33%,
    rgba(0, 0, 0, 0) 0%
  );
  background-position: bottom;
  background-size: 8px 2px;
  background-repeat: repeat-x;
`;

const DashedDivider: React.FC<Props> = ({ margin }) => {
  return (
    <StyledDivider
      data-testid="dashed-divider"
      style={{
        margin,
      }}
    />
  );
};

export default DashedDivider;
