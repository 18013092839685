import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Link, useSearchParams } from "react-router-dom";
import { ToastOptions, toast } from "react-toastify";

import React, { useCallback, useContext, useEffect } from "react";

import AuthLayout from "components/App/AuthLayout";
import CustomModal from "components/App/Modal";
import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";

import useModal from "hooks/useModal";

import bgImg from "assets/images/woman_man_proud.svg";

import ForgotPasswordForm from "./ForgotPasswordForm";
import LoginForm from "./LoginForm";
import {
  EmailToast,
  ModalContent,
  ModalDesc,
  ModalTitle,
  RegisterInfo,
} from "./styles";

const toastSettings: ToastOptions = {
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  style: {
    minWidth: "510px",
    padding: 0,
    textAlign: "center",
    minHeight: "unset",
  },
  bodyStyle: {
    padding: 0,
    margin: 0,
    height: 48,
    maxHeight: 48,
  },
};

const LoginPage: React.FC = () => {
  const { isOpen, setIsOpen } = useModal();
  const { emailVerify } = useContext(EscolaLMSContext);
  const [searchParams] = useSearchParams();

  const hash = searchParams.get("hash");
  const id = searchParams.get("id");

  const verifyEmail = useCallback(async () => {
    if (!id || !hash) return;
    try {
      const request = await emailVerify(String(id), String(hash));

      if (request) {
        toast.success(
          <EmailToast>
            <Icon name="emailActivated" />{" "}
            <span>Twoje konto zostało aktywowane, możesz się zalogować.</span>
          </EmailToast>,
          toastSettings
        );
      } else {
        toast.error("Wystąpił błąd podczas weryfikacji adresu email.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Wystąpił błąd podczas weryfikacji adresu email.");
    }
  }, [id, hash, emailVerify]);

  useEffect(() => {
    if (hash && id) {
      verifyEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout backgroundImgSrc={bgImg} title="Zaloguj się">
      <RegisterInfo>
        Jeśli nie posiadasz jeszcze konta to,{" "}
        <Link to={routeRoutes.register}>
          Zarejestruj się
          <span>
            <Icon name="arrowRight" />
          </span>
        </Link>
      </RegisterInfo>
      <LoginForm onForgotPasswordClick={() => setIsOpen(true)} />
      <CustomModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalContent>
          <ModalTitle>Zapomniane hasło?</ModalTitle>
          <ModalDesc>
            Na adres przypisany do Twojego konta w platformie zostanie wysłany
            link służący do ustawienia nowego hasła
          </ModalDesc>
          <ForgotPasswordForm onSuccess={() => setIsOpen(false)} />
        </ModalContent>
      </CustomModal>
    </AuthLayout>
  );
};

export default LoginPage;
