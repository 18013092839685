import { LessonListLessonState } from "types/course";

import React from "react";

import Icon, { IconName } from "components/common/Icon";

import { onKeyboardInteraction } from "utils/a11y";

import { CardTitle, Col, LessonTitle, Wrapper } from "./styles";

type StateWithoutCurrent = Exclude<
  LessonListLessonState,
  "current_root" | "current_within" | "available"
>;

const ICON_MAP: Record<StateWithoutCurrent, IconName> = {
  locked: "lock",
  finished: "finish",
};

function isStateWithoutCurrent(
  state: LessonListLessonState
): state is StateWithoutCurrent {
  return (
    state !== "current_within" &&
    state !== "current_root" &&
    state !== "available"
  );
}

interface Props {
  state: LessonListLessonState;
  onClick: () => void;
  hasTopics: boolean;
  index: number;
  title: string;
  tooltip?: string;
}

const CourseLessonCard: React.FC<Props> = ({
  state,
  onClick,
  hasTopics,
  index,
  title,
  tooltip,
}) => (
  <Wrapper
    $state={state}
    role="button"
    aria-disabled={state === "locked"}
    tabIndex={state === "locked" ? -1 : 0}
    onClick={state === "locked" ? undefined : onClick}
    onKeyDown={state === "locked" ? undefined : onKeyboardInteraction(onClick)}
    /* TODO probably could be react-tooltip or something like that */
    title={tooltip}
  >
    <Col>
      <CardTitle>{hasTopics ? `Lekcja ${index + 1}` : "Moduł"}</CardTitle>
      <LessonTitle>{title}</LessonTitle>
    </Col>
    {isStateWithoutCurrent(state) && <Icon name={ICON_MAP[state] ?? "lock"} />}
  </Wrapper>
);

export default CourseLessonCard;
