import React from "react";

import { Container, Error, Label, StyledSelect } from "./styles";

type Props = React.InputHTMLAttributes<HTMLSelectElement> & {
  options: {
    value: string;
    label: string;
    disabled?: boolean;
    selected?: boolean;
  }[];
  label?: string;
  error?: string;
  labelSufix?: string;
};

const Select: React.FC<Props> = ({
  label,
  error,
  options,
  labelSufix,
  ...props
}) => {
  return (
    <Container>
      {label && (
        <Label htmlFor={props.id || props.name}>
          {label} <span>{labelSufix}</span>
        </Label>
      )}
      <StyledSelect {...props}>
        {options.map((option) => (
          <option key={option.value} {...option}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Select;
