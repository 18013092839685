import styled from "styled-components";

export const Wrapper = styled.li<{ $withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  border: 2px solid
    ${({ theme, $withBorder }) =>
      $withBorder ? theme.colors.primary : "transparent"};
  border-radius: 5px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;

  padding: 0 0 4px 4px;
  margin: 0;
  list-style: none;
`;
