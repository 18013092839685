import { API } from "@escolalms/sdk/lib";

export const getFlatCategoriesSortedById = (
  categories: API.Category[]
): Record<number, API.Category> =>
  categories.reduce<Record<number, API.Category>>(
    (acc, curr) => ({
      ...acc,
      ...getFlatCategoriesSortedById(curr?.subcategories ?? []),
      [curr.id]: curr,
    }),
    []
  );
