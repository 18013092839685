import styled from "styled-components";

export const Wrapper = styled.div`
  .project-player__upload-input {
    margin-bottom: 1em;
    .wrapper {
      .border img {
        display: none;
      }

      &::after {
        padding-top: 0;
        aspect-ratio: 16 / 3;
      }
    }
  }

  .project-player__projects-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    .project-card {
      width: 100%;
      max-width: 225px;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }
`;

export const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: 5px;
  padding: 10px;

  display: flex;
  justify-content: space-between;

  .project-card__details {
    & > p {
      margin: 0;
    }

    .project-card__title {
      display: flex;
      gap: 5px;
    }
  }

  .project-card__delete-btn {
    background: transparent;
    border: none;
    padding: 0;

    cursor: pointer;
    transition: color 200ms ease-in-out, opacity 200ms ease-in-out;
    color: ${({ theme }) => theme.colors.text};

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
