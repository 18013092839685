import { API } from "@escolalms/sdk/lib";
import {
  CompetencyTestResult,
  GroupedCompetencyChallengeResult,
} from "types/course";

import React from "react";

import ScoreCard from "components/ScoreCard";
import { Row } from "components/common/Row";

import { getCompetenceResultLevel } from "utils/course";

import { PrimaryColorIcon, Title } from "./styles";

type Props = {
  challengeType: API.CompetencyChallengeType;
  result: GroupedCompetencyChallengeResult;
  onDevelopCompetencyClick: (value: CompetencyTestResult) => void;
};

const ResultCard: React.FC<Props> = ({
  challengeType,
  result,
  onDevelopCompetencyClick,
}) => {
  const { roleCategory, results } = result;

  return (
    <>
      {challengeType === API.CompetencyChallengeType.Complex && (
        <Row $gap={9}>
          <PrimaryColorIcon name="arrowRightOrange" />
          <Title>{roleCategory.name}</Title>
        </Row>
      )}
      {results.map((result, i) => (
        <ScoreCard
          key={i}
          className="complex-test"
          title={result.category.name}
          description={
            result.category.description || "Brak opisu dla danej kompetencji"
          }
          progress={result}
          level={String(getCompetenceResultLevel(result)?.name)}
          onClick={() => onDevelopCompetencyClick(result)}
        />
      ))}
    </>
  );
};

export default ResultCard;
