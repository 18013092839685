import { API } from "@escolalms/sdk/lib";

import React from "react";

import { useCourse } from "../Context";
import CourseLessons from "./CourseLessons";
import { List, Wrapper } from "./styles";

interface RecursiveLessonsProps {
  lessons: API.Lesson[];
}

const RecursiveLessons: React.FC<RecursiveLessonsProps> = ({ lessons }) => (
  <>
    {lessons.map((lesson, index) => (
      <CourseLessons key={lesson.id} index={index} lesson={lesson}>
        <RecursiveLessons lessons={lesson?.lessons ?? []} />
      </CourseLessons>
    ))}
  </>
);

interface CourseLessonListProps {
  headerHeight: number;
  mobile: boolean;
  showLessonList: boolean;
}

export const CourseLessonList: React.FC<CourseLessonListProps> = ({
  headerHeight,
  mobile,
}) => {
  const { currentCourseProgram } = useCourse();

  return (
    <Wrapper $headerHeight={headerHeight} $mobile={mobile}>
      <List $mobile={mobile}>
        <RecursiveLessons lessons={currentCourseProgram?.lessons ?? []} />
      </List>
    </Wrapper>
  );
};

export default CourseLessonList;
