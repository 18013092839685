import React, { useMemo } from "react";

import { useCourse } from "../Context";
import CourseControls from "../CourseControls";
import { ContentWrapper, Order, Title, TitleWrapper, Wrapper } from "./styles";

const CourseContentCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { flatTopics, currentTopic } = useCourse();

  const currentTopicNumber = useMemo(() => {
    const currentTopicIndex = (flatTopics ?? []).findIndex(
      (t) => t.id === currentTopic?.id
    );
    if (currentTopicIndex < 0) return undefined;

    return currentTopicIndex + 1;
  }, [flatTopics, currentTopic]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Order>Temat {currentTopicNumber}</Order>
        <Title>{currentTopic?.title}</Title>
      </TitleWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <CourseControls />
    </Wrapper>
  );
};

export default CourseContentCard;
