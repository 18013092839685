import { styled } from "styled-components";

import React from "react";

import DashedDivider from "components/DashedDivider";
import MarkdownRenderer from "components/MarkdownRenderer";

type Props = {
  description?: string;
};

const Section = styled.section`
  margin-bottom: 20px;
`;

const DescriptionSection: React.FC<Props> = ({ description }) => {
  return (
    <Section>
      {!!description && <MarkdownRenderer>{description}</MarkdownRenderer>}
      <DashedDivider margin="35px 0 0 0" />
    </Section>
  );
};

export default DescriptionSection;
