import React from "react";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

import { useCourse } from "../Context";
import CourseProgressBar from "../CourseProgressBar";
import {
  ProgressBarWrapper,
  SubTitle,
  SubTitleContainer,
  SubTitleLabel,
  Wrapper,
} from "./styles";

type CourseSubheaderProps = {
  mobile: boolean;
  showLessonList?: boolean;
  setShowLessonList?: () => void;
};

const CourseSubheader = React.forwardRef<HTMLDivElement, CourseSubheaderProps>(
  ({ mobile, showLessonList, setShowLessonList }, ref) => {
    const { courseCategories, finishedTopicsIds, flatTopics } = useCourse();

    const progress =
      ((finishedTopicsIds ?? []).length / (flatTopics ?? []).length) * 100;

    return (
      <Wrapper ref={ref} $mobile={mobile}>
        <ProgressBarWrapper>
          <SubTitleContainer>
            <SubTitleLabel>Kompetencja:</SubTitleLabel>
            <SubTitle>{courseCategories?.competencyCategory?.name}</SubTitle>
          </SubTitleContainer>
          <CourseProgressBar progress={progress} />
        </ProgressBarWrapper>

        {mobile && (
          <Button onClick={setShowLessonList} styleVariant="menu">
            <Icon name={showLessonList ? "closeHamburger" : "hamburger"} />
          </Button>
        )}
      </Wrapper>
    );
  }
);

export default CourseSubheader;
