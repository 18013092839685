import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 15px;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 24px;

  @media ${device.smallTablet} {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    max-width: 215px;
    margin-top: 23px;
  }
`;
