import { styled } from "styled-components";

export const ResultsContainer = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: ${({ theme }) => theme.colors.border};
  padding: 18px 13px 40px 13px;
`;

export const ResultsTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin: 0 0 20px 0;
  padding-left: 10px;
`;

export const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 19px;
  margin-top: 15px;
`;

export const ResultsList = styled.div`
  padding: 0 10px;
`;

export const DescriptionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.border};
  padding: 15px 13px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
`;
