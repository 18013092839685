import { StyledPaginate } from "./styles";

type PaginationProps = {
  setCurrentPage: (item: number) => void;
  pageCount: number;
};

export const Pagination = ({ pageCount, setCurrentPage }: PaginationProps) => {
  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected + 1);
  };
  return (
    <StyledPaginate
      nextLabel=">"
      onPageChange={handlePageChange}
      pageRangeDisplayed={4}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel="<"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link-prev"
      nextClassName="page-item"
      nextLinkClassName="page-link-next"
      breakLabel=".."
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
};
