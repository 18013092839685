import React from "react";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

import {
  ButtonsHeader,
  CloseButton,
  Content,
  Overlay,
  StyledSidebar,
  Title,
} from "./styles";

type Props = {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  onClickBackBtn?: () => void;
};

const Sidebar: React.FC<Props> = ({
  title,
  children,
  onClose,
  onClickBackBtn,
}) => (
  <>
    <StyledSidebar>
      {!!onClickBackBtn ? (
        <ButtonsHeader>
          <Button
            onClick={onClickBackBtn}
            iconGap={10}
            styleVariant="secondaryText"
          >
            <Icon name="back" /> Wróć
          </Button>
          <CloseButton onClick={onClose} data-testid="close-button" backButton>
            <Icon name="close" />
          </CloseButton>
        </ButtonsHeader>
      ) : (
        <CloseButton onClick={onClose} data-testid="close-button">
          <Icon name="close" />
        </CloseButton>
      )}
      <Title>{title}</Title>

      <Content>{children}</Content>
    </StyledSidebar>
    <Overlay />
  </>
);

export default Sidebar;
