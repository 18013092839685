import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  max-width: ${({ theme }) => theme.container.maxWidth};
  height: max-content;
  border-radius: 8px;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 6px #00000029;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  padding: 14px 21px 12px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`;
export const Order = styled.span``;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ContentWrapper = styled.div`
  padding: 14px 21px;
`;
