import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useState } from "react";

import UserPanelLayout from "components/App/UserPanelLayout";
import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";

import { Box, Text } from "./styles";

const DeleteAccountPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { initAccountDelete } = useContext(EscolaLMSContext);

  const handleDelete = useCallback(() => {
    setLoading(true);
    initAccountDelete(
      `${window.location.origin}/#${routeRoutes.deleteAccountConfirmation}`
    )
      .then((res) => {
        if (res.success) {
          toast.success(
            "Na Twój adres email został wysłany link potwierdzający usunięcie konta."
          );
        } else {
          toast.error("Wystąpił błąd. Spróbuj ponownie.");
        }
      })
      .catch((error) => {
        toast.error("Wystąpił błąd. Spróbuj ponownie.");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [initAccountDelete]);

  return (
    <UserPanelLayout title="Dezaktywacja konta">
      <Box>
        <Text>
          Po kliknięciu usuń, Twoje konto oraz wszystkie dane zostaną usunięte.
          <br />
          Uwaga, ten proces jest nieodwracalny!
        </Text>
        <Button
          styleVariant="linkRed"
          disabled={loading}
          onClick={handleDelete}
        >
          {loading ? "Ładowanie..." : "Usuń konto"}
        </Button>
      </Box>
    </UserPanelLayout>
  );
};

export default DeleteAccountPage;
