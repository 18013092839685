import React from "react";

import routeRoutes from "components/Routes/routes";
import { Stack } from "components/common/Stack";

import { ReactComponent as BigCheck } from "assets/images/big_check.svg";

import { Description, Main, StyledButton, Title } from "./styles";

const PaymentReturn: React.FC = () => (
  <Main>
    <Stack $alignItems="center" $gap={18} as="header">
      <BigCheck />
      <Title>Dziękujemy! Powodzenia :)</Title>
      <Description>Transakcja zrealizowana pomyślnie.</Description>
    </Stack>
    <StyledButton to={routeRoutes.home}>Powrót</StyledButton>
  </Main>
);

export default PaymentReturn;
