import { API } from "@escolalms/sdk/lib";
import { styled } from "styled-components";

import React from "react";

import Accordion from "components/Accordion";

import SectionTitle from "../SectionTitle";

type Props = {
  title: string;
  categories: API.Category[];
};

const Section = styled.section``;

const AccordionsContainer = styled.div`
  margin-bottom: 30px;
`;

const AccordionsSection: React.FC<Props> = ({ categories, title }) => (
  <Section>
    <SectionTitle>
      W ramach wyzwania {title} rozwiniesz następujące kompetencje
    </SectionTitle>
    <AccordionsContainer>
      {categories.map((item) => (
        <Accordion key={item.id} title={item.name}>
          {item.description || "Brak opisu"}
        </Accordion>
      ))}
    </AccordionsContainer>
  </Section>
);

export default AccordionsSection;
