import { API } from "@escolalms/sdk/lib";
import {
  CompetencyTestResult,
  GroupedCompetencyChallengeResult,
} from "types/course";

import React, { useState } from "react";

import Sidebar from "components/App/Sidebar";
import CompetencyDetailsCard from "components/CompetencyDetailsCard";
import TestResultInfo from "components/TestResultInfo";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import { ChallengeTitle, Desc, Result, Row, Title } from "./styles";

type Props = {
  challengeType?: API.CompetencyChallengeType;
  challengeTestResult: GroupedCompetencyChallengeResult;
};

const ResultCard: React.FC<Props> = ({
  challengeTestResult: { roleCategory, results },
  challengeType,
}) => {
  const [competence, setCompetence] = useState<CompetencyTestResult | null>(
    null
  );

  return (
    <>
      {competence?.category && (
        <Sidebar
          title={competence.category.name}
          onClose={() => setCompetence(null)}
        >
          <CompetencyDetailsCard
            competence={competence}
            onClose={() => setCompetence(null)}
          />
        </Sidebar>
      )}
      {challengeType === API.CompetencyChallengeType.Complex && (
        <ChallengeTitle>
          <Icon name="arrowRightOrange" />
          <h2>{roleCategory.name}</h2>
        </ChallengeTitle>
      )}
      {results.map((result, i) => (
        <Result key={i}>
          <Title>{result.category?.name}</Title>
          <Desc>{result.category?.description}</Desc>
          <Row>
            <TestResultInfo competence={result} hideBorder />
            <Button
              onClick={() => setCompetence(result)}
              iconGap={28}
              icon={<Icon name="arrowRight" />}
              size="small"
            >
              Rozpocznij kurs
            </Button>
          </Row>
        </Result>
      ))}
    </>
  );
};

export default ResultCard;
