import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const Sidebar = styled.aside`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.primary};
  padding: 25px 45px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  & picture {
    aspect-ratio: unset;
  }

  @media ${device.mobile} {
    padding: 0px;
    height: auto;
    align-items: flex-start;
    position: relative;
    top: 0;
    left: 0;
    padding: 13px 25px 17px 36px;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 25px 25px 0 0;
      left: 0;
      bottom: 0;
    }
  }
`;
