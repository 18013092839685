import { styled } from "styled-components";
import { Color } from "theme";

import React from "react";

import Footer from "components/App/Footer";
import Header from "components/App/Header";

import { ChildrenWrapper } from "./styles";

type Props = {
  children: React.ReactNode;
  bgColor?: string;
  barColor?: Color;
};

const MainLayout = styled.div<{
  $bgColor?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ $bgColor }) => $bgColor || "#fff"};
  min-height: 100vh;
`;

const Layout: React.FC<Props> = ({ children, bgColor, barColor }) => {
  return (
    <MainLayout $bgColor={bgColor}>
      <Header barColor={barColor} />
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <Footer />
    </MainLayout>
  );
};

export default Layout;
