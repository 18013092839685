import React from "react";

import AuthLayout from "components/App/AuthLayout";

import bgImg from "assets/images/login-bg-img.png";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordPage: React.FC = () => {
  return (
    <AuthLayout title="Utwórz nowe hasło" backgroundImgSrc={bgImg}>
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
