import { Link } from "react-router-dom";
import styled from "styled-components";

import Icon from "components/common/Icon";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 9px 8px 9px 12px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const WhiteIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.white};
`;
