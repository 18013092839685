import { useTheme } from "styled-components";

import React, { useMemo } from "react";

import { Row } from "components/common/Row";

import { Circle, MaxScore, Score, Wrapper } from "./styles";

type Result = {
  percentage: number;
  score: number;
  max_score: number;
};

type Props = {
  progress: Result;
  size: number;
  strokeWidth?: number;
  circleOneStroke?: string;
  circleTwoStroke?: string;
};

const CircleProgress: React.FC<Props> = ({
  progress = { percentage: 0, max_score: 0, score: 0 },
  size = 66,
  strokeWidth = 12,
  circleOneStroke,
  circleTwoStroke,
}) => {
  const theme = useTheme();
  const center = useMemo(() => size / 2, [size]);
  const radius = useMemo(() => size / 2 - strokeWidth / 2, [size, strokeWidth]);
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);
  const offset = useMemo(
    () => circumference - (progress.percentage / 100) * circumference,
    [circumference, progress]
  );

  return (
    <Row $gap={8} $alignItems="center">
      <Wrapper data-testid="circle-progress">
        <svg width={size} height={size}>
          <Circle
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            stroke={circleOneStroke || theme?.colors.gray200}
            fill="none"
            data-testid="circle-one"
          />
          <Circle
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            stroke={circleTwoStroke || theme?.colors.green}
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            data-testid="circle-two"
          />
        </svg>
        <Score>{progress.score}</Score>
      </Wrapper>
      <MaxScore>z {progress.max_score} pkt</MaxScore>
    </Row>
  );
};

export default CircleProgress;
