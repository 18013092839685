import { Col } from "react-grid-system";
import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";
import { ellipsisText } from "theme/mixins";

import { Row } from "components/common/Row";
import { Stack } from "components/common/Stack";

export const Card = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 14px 26px 8px 26px;
  width: 100%;
`;

export const StyledCol = styled(Col)<{ $mobile: boolean }>`
  ${({ $mobile }) =>
    $mobile
      ? css`
          padding-bottom: 21px;
          border-bottom: 2px dotted ${({ theme }) => theme.colors.gray200};
        `
      : css`
          border-right: 2px dotted ${({ theme }) => theme.colors.gray200};
        `}
`;

export const ButtonWrapper = styled(Stack)`
  align-items: center;
  gap: 10px;
  @media ${device.mobile} {
    width: 100%;
    margin-top: 37px;
    margin-bottom: 11px;
    gap: 23px;
    & button {
      border-radius: 21px;
    }
  }
`;

export const StyledTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 4px;
  & > span {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.5;
  }
  ${ellipsisText}
`;

export const StyledSubtitle = styled.p`
  margin: 0px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  ${ellipsisText}
  & > span {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.5;
  }
`;

export const StyledText = styled.p`
  line-height: normal;
  margin: 0px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const StyledRow = styled(Row)`
  gap: 6px;
  min-height: 28px;
`;
