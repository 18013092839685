import routeRoutes from "components/Routes/routes";

export const COURSE_SUPPLEMENT =
  "https://docs.google.com/forms/d/e/1FAIpQLSdEUj28-lCxYCZ6gE3sJl4iz0s13E2NbQC5Zfjfn3iKSBqEBQ/viewform";
//TODO: USE correct link when ready
export const VOUCHER_PROBLEM = "przykladowy@example.com";

export const PAYMENT_RETURN_URL = `${window.location.origin}/#${routeRoutes.paymentReturn}`;

export const CERTIFICATE_PROBLEM = "m.koszewska@simpliciti.pl";
