import { styled } from "styled-components";

export const CheckboxWrapper = styled.div`
  position: relative;
  min-height: 17px;
  display: inline-block;
  z-index: 0;
`;

export const CheckboxInput = styled.input`
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CheckboxLabel = styled.label<{ $checked: boolean }>`
  display: flex;
  position: relative;
  z-index: 2;
  padding-left: 20px;
  cursor: pointer;

  span {
    user-select: none;
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: ${({ $checked, theme }) =>
      $checked ? theme.colors.primary : theme.colors.gray50};
    transition: all 0.25s;
    z-index: 0;
  }

  &:after {
    position: absolute;
    content: "";
    top: 7px;
    left: 6px;
    width: 10px;
    height: 3px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
    border-left: 1px solid ${({ theme }) => theme.colors.text};
    transform: rotate(-45deg);
    display: ${({ $checked }) => ($checked ? "block" : "none")};
    z-index: 1;
  }
`;

export const Error = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: red;
  margin-top: 8px;
  display: block;
`;
