import { Col, Row } from "react-grid-system";
import theme from "theme";

import React from "react";

import Layout from "components/Layout";
import routeRoutes from "components/Routes/routes";

import LayoutContainer from "../Container";
import { Content, ContentHeader, MenuItem, PanelMenu, Title } from "./styles";

type Props = {
  children: React.ReactNode;
  title: string;
  button?: React.ReactNode;
};

type MenuItemProps = {
  label: string;
  to: string;
  redColor?: boolean;
};

const menuItems: MenuItemProps[] = [
  {
    label: "Certyfikaty",
    to: routeRoutes.userPanel.certificates,
  },
  {
    label: "Metryka",
    to: routeRoutes.userPanel.metrics,
  },
  {
    label: "Dane profilowe",
    to: routeRoutes.userPanel.accountDetails,
  },
  {
    label: "Newsletter",
    to: routeRoutes.userPanel.newsletter,
  },
  {
    label: "Usuń konto",
    to: routeRoutes.userPanel.deleteAccount,
    redColor: true,
  },
];

const UserPanelLayout: React.FC<Props> = ({ children, title, button }) => {
  return (
    <Layout bgColor={theme.colors.gray50} barColor="gray50">
      <LayoutContainer>
        <Row>
          <Col md={3}>
            <PanelMenu>
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={item.to}
                  $redColor={!!item.redColor}
                >
                  {item.label}
                </MenuItem>
              ))}
            </PanelMenu>
          </Col>
          <Col md={9}>
            <Content>
              <ContentHeader>
                <Title>{title}</Title>
                <div>{button}</div>
              </ContentHeader>
              {children}
            </Content>
          </Col>
        </Row>
      </LayoutContainer>
    </Layout>
  );
};

export default UserPanelLayout;
