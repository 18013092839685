import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Content = styled.div`
  padding-right: 67px;

  @media ${device.smallTablet} {
    padding-right: 0;
  }
`;
