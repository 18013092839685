import { styled } from "styled-components";

import Button from "components/common/Button";

export const Description = styled.p`
  margin: 0 0 21px 0;
  padding-bottom: 11px;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const ImageContainer = styled.div`
  border-radius: 9px 9px 0 0;
  max-height: 200px;
  background-color: ${({ theme }) => theme.colors.gray100};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  img {
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }
`;

export const Card = styled.div<{ $variant: "horizontal" | "vertical" }>`
  width: 100%;
  height: 100%;
  border-radius: 9px;
  border: 2px solid ${({ theme }) => theme.colors.gray100};
  background-color: ${({ theme }) => theme.colors.gray100};
  display: flex;
  flex-direction: ${({ $variant }) =>
    $variant === "horizontal" ? "row-reverse" : "column"};

  ${ImageContainer} {
    min-width: ${({ $variant }) => ($variant === "horizontal" ? "277px" : "0")};
  }
`;

export const Content = styled.div`
  position: relative;

  padding: 24px 23px;
  width: 100%;
  border-radius: 6px 6px 9px 9px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  margin-bottom: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const IconWrapper = styled.div`
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const IconLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.small};
  opacity: 0.5;
  margin-bottom: 7px;
`;

export const IconInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  gap: 21px;
`;

export const ScoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  padding-top: 15px;
`;

export const StyledButton = styled(Button)`
  max-width: fit-content;
`;

export const TestDone = styled.div`
  position: absolute;
  top: -5px;
  right: 9px;

  display: flex;
  align-items: center;
  gap: 4px;

  padding: 5px 10px 5px 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.success};

  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: calc(15px / 13);
  color: ${({ theme }) => theme.colors.white};
`;
