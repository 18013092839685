import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Col, Row as SystemRow } from "react-grid-system";

import React, { useContext } from "react";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

import useMediaQuery from "hooks/useMediaQuery";

import {
  downloadCertificate,
  handleRedirectToCourseSupplement,
} from "utils/certificate";

import {
  ButtonWrapper,
  Card,
  StyledCol,
  StyledRow,
  StyledSubtitle,
  StyledText,
  StyledTitle,
} from "./styles";

type CertificateCardProps = {
  certificate: API.Certificate & { vars?: Record<string, string> };
};
const CertificateCompetenceCard: React.FC<CertificateCardProps> = ({
  certificate,
}) => {
  const [role = "", competence = "", level = ""] = certificate?.vars
    ?.var_course_categories_with_breadcrumbs
    ? certificate?.vars?.var_course_categories_with_breadcrumbs.split(". ")
    : [];

  const { generateCertificate } = useContext(EscolaLMSContext);
  const mobileView = useMediaQuery("mobile");
  return (
    <Card>
      <SystemRow gutterWidth={6}>
        <StyledCol md={8} $mobile={mobileView}>
          <StyledRow>
            {/* Protection for previously generated certificates */}
            {(competence || certificate.title) && (
              <>
                <Icon name="level" />
                <StyledTitle>
                  <span>Zaliczone kompetencje: </span>
                  <strong>
                    {competence || certificate.title} {level && `(${level})`}
                  </strong>
                </StyledTitle>
              </>
            )}
          </StyledRow>
          {certificate.title && (
            <StyledSubtitle>
              <span>Wyzwanie: </span>
              <strong>{role || "brak danych"}</strong>
            </StyledSubtitle>
          )}
        </StyledCol>
        <Col md={4}>
          <ButtonWrapper>
            <Button
              styleVariant="secondaryBlack"
              iconGap={63}
              icon={<Icon name="download" />}
              size="small"
              onClick={() =>
                downloadCertificate({
                  generateCertificate,
                  id: certificate.id,
                  title: certificate.title,
                })
              }
            >
              <StyledText>Pobierz certyfikat</StyledText>
            </Button>
            <Button
              styleVariant="linkBlack"
              size="small"
              onClick={handleRedirectToCourseSupplement}
            >
              Potrzebujesz suplement?
            </Button>
          </ButtonWrapper>
        </Col>
      </SystemRow>
    </Card>
  );
};

export default CertificateCompetenceCard;
