import styled from "styled-components";

export const Track = styled.div`
  position: relative;

  width: 100%;
  height: 4px;

  background-color: ${({ theme }) => theme.colors.gray90};
`;

export const Progress = styled.div<{ $value: number }>`
  width: ${({ $value }) => $value}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.greenDark};

  transition: width 0.2s ease-in-out;
`;

export const Label = styled.span`
  position: absolute;
  bottom: calc(100% + 5px);
  right: 2%;

  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.greenDark};
`;
