import React from "react";

import {
  DynamicPercentage,
  ProgressBarFiller,
  ProgressBarText,
  ProgressBarWrapper,
  StaticPercentage,
} from "./styles";

type Props = {
  currentProgress: number;
  maxProgress: number;
  text?: string;
  staticPercentage?: boolean;
  size?: "small" | "big";
  bgColor?: string;
  fillColor?: string;
};

const ProgressBar: React.FC<Props> = ({
  currentProgress,
  maxProgress,
  text,
  staticPercentage,
  size = "big",
  bgColor,
  fillColor,
}) => {
  const progressPercentage = Number.isNaN((currentProgress / maxProgress) * 100)
    ? 0
    : (currentProgress / maxProgress) * 100;
  const percentage = Math.round(progressPercentage);
  return (
    <ProgressBarWrapper $size={size} style={{ backgroundColor: bgColor }}>
      <ProgressBarFiller
        style={{ backgroundColor: fillColor }}
        $progressPercentage={progressPercentage}
      >
        <ProgressBarText>{text}</ProgressBarText>
        {!staticPercentage && (
          <DynamicPercentage $progressPercentage={progressPercentage}>
            {percentage}%
          </DynamicPercentage>
        )}
      </ProgressBarFiller>
      {staticPercentage && <StaticPercentage>{percentage}%</StaticPercentage>}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
