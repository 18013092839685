import { pixelsToRem } from "utils";

export type Theme = {
  colors: {
    primary: string;
    primaryDark: string;
    secondary: string;
    black: string;
    white: string;
    gray50: string;
    gray60: string;
    gray80: string;
    gray90: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    text: string;
    textAccent: string;
    border: string;
    success: string;
    green: string;
    greenDark: string;
    greenLight: string;
    greenAccent: string;
    red: string;
    blue: string;
    blueSecondary: string;
    info: string;
    backgroundOpacity: string;
  };
  fonts: {
    primary: string;
  };
  fontSizes: {
    xxsmall: string;
    xsmall: string;
    small: string;
    medium: string;
    large: string;
    smlarge: string;
    mdlarge: string;
    xlarge: string;
    xxlarge: string;
    xxxlarge: string;
  };
  fontWeights: {
    normal: number;
    medium: number;
    semiBold: number;
  };
  container: {
    maxWidth: string;
  };
};

export type Color = keyof Theme["colors"];
export type Font = keyof Theme["fonts"];
export type FontSize = keyof Theme["fontSizes"];
export type FontWeight = keyof Theme["fontWeights"];

const theme: Theme = {
  colors: {
    primary: "#FFBB00",
    primaryDark: "#FFC831",
    secondary: "#353535",
    black: "#000",
    gray50: "#F7F7F7",
    gray60: "#F8F8F8",
    gray80: "#EFEFEF",
    gray90: "#EDEDED",
    gray100: "#EBEBEB",
    gray200: "#DDDDDD",
    gray300: "#c1c1c1",
    gray400: "#9a9a9a",
    gray500: "#9c9c9c",
    white: "#FFFFFF",
    text: "#353535",
    textAccent: "#A0A0A0",
    border: "#F0F0F0",
    success: "#27CC80",
    green: "#19cc73",
    greenDark: "#1DA54C",
    greenLight: "#dcf8ea",
    greenAccent: "#44a8a1",
    red: "#D39595",
    blue: "#0B85FD",
    blueSecondary: "#3180FF",
    info: "#FFFBDE",
    backgroundOpacity: "#00000040",
  },
  fonts: {
    primary: "Rubik, sans-serif",
  },
  fontSizes: {
    xxsmall: pixelsToRem(11),
    xsmall: pixelsToRem(12),
    small: pixelsToRem(13),
    medium: pixelsToRem(15),
    large: pixelsToRem(16),
    smlarge: pixelsToRem(17),
    mdlarge: pixelsToRem(18),
    xlarge: pixelsToRem(24),
    xxlarge: pixelsToRem(30),
    xxxlarge: pixelsToRem(30),
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
  },
  container: {
    maxWidth: "1100px",
  },
};

export default theme;
