import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { getQuizAttempts } from "@escolalms/sdk/lib/services/gfit_quiz";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  GiftQuizAnswer,
  GiftQuizAnswerObj,
  GiftQuizBooleanAnswer,
  GiftQuizMatchingAnswer,
  GiftQuizMultipleAnswer,
  GiftQuizNumericAnswer,
  GiftQuizTextAnswer,
} from "types/gift-quiz";

import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { Countdown } from "components/Countdown";
import ProgressBar from "components/ProgressBar";
import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";
import { Stack } from "components/common/Stack";

import useMediaQuery from "hooks/useMediaQuery";

import Button from "../../../components/common/Button";
import GiftQuizScore from "./GiftQuizScore";
import Description from "./questions/Description/Description";
import Essay from "./questions/Essay/Essay";
import Matching from "./questions/Matching/Matching";
import MultipleChoice from "./questions/MultipleChoice/MultipleChoice";
import MultipleChoiceWithMultipleRightAnswers from "./questions/MultipleChoiceWithMultipleRightAnswers/MultipleChoiceWithMultipleRightAnswers";
import NumericalQuestion from "./questions/NumericalQuestion/NumericalQuestion";
import ShortAnswers from "./questions/ShortAnswers/ShortAnswers";
import TrueFalse from "./questions/TrueFalse/TrueFalse";
import {
  AttemptsTooltipWrapper,
  ButtonsRow,
  CloseButton,
  GiftQuizHeader,
  GiftQuizPlayerContentForm,
  ProgressContainer,
  ProgressTitle,
} from "./styles";

interface Props {
  attempt: API.QuizAttempt & {
    is_ended?: boolean;
    min_pass_score?: number;
  };
  startQuiz: () => void;
  endQuiz: (quizAttemptId: number) => void;
  sendAnswer: <Answer extends GiftQuizAnswer>(
    questionId: number,
    answer: Answer
  ) => void;
  currentQuestionId?: number;
  showOnlyOneQuestion?: boolean;
  nextQuestionId?: number;
  previousQuestionId?: number;
  challengeId?: number;
}

function unescapeSpecificCharacters(escapedString: string): string {
  const escapeMap: Record<string, string> = {
    "\\~": "~",
    "\\=": "=",
    "\\#": "#",
    "\\{": "{",
    "\\}": "}",
    "\\:": ":",
  };

  let unescapedString = "";
  let escapedSequence = "";

  for (let i = 0; i < escapedString.length; i++) {
    const currentChar = escapedString[i];

    if (escapedSequence) {
      escapedSequence += currentChar;
      if (escapedSequence in escapeMap) {
        unescapedString += escapeMap[escapedSequence];
        escapedSequence = "";
      }
    } else if (currentChar === "\\") {
      escapedSequence = "\\";
    } else {
      unescapedString += currentChar;
    }
  }

  return unescapedString;
}

function isTextAnswer(
  answer?: GiftQuizAnswer | string
): answer is GiftQuizTextAnswer {
  return typeof (answer as GiftQuizTextAnswer)?.text === "string";
}

function isMultipleAnswer(
  answer?: GiftQuizAnswer | string
): answer is GiftQuizMultipleAnswer {
  return Array.isArray((answer as GiftQuizMultipleAnswer)?.multiple);
}

function isNumericAnswer(
  answer?: GiftQuizAnswer | string
): answer is GiftQuizNumericAnswer {
  return typeof (answer as GiftQuizNumericAnswer)?.numeric === "number";
}

function isBooleanAnswer(
  answer?: GiftQuizAnswer | string
): answer is GiftQuizBooleanAnswer {
  return typeof (answer as GiftQuizBooleanAnswer)?.bool === "boolean";
}

function isMatchingAnswer(
  answer?: GiftQuizAnswer | string
): answer is GiftQuizMatchingAnswer {
  return typeof (answer as GiftQuizMatchingAnswer)?.matching === "object";
}

const getDefaultValues = (
  questions: API.QuizQuestion[],
  answers?: API.QuizAttempt[] | null
) => {
  const findAnswer = (questionId: number) =>
    answers?.find((ansObj) => ansObj.topic_gift_question_id === questionId)
      ?.answer;

  return questions.reduce<
    Record<string, string | Record<string, boolean> | Record<string, string>>
  >((acc, { id, type, options }) => {
    const answer = findAnswer(id);

    switch (type) {
      case API.QuestionType.ESSAY:
      case API.QuestionType.SHORT_ANSWERS:
      case API.QuestionType.MULTIPLE_CHOICE:
        const textVal = isTextAnswer(answer) ? answer.text : "";

        return {
          ...acc,
          [`${id}`]: textVal,
        };
      case API.QuestionType.MULTIPLE_CHOICE_WITH_MULTIPLE_RIGHT_ANSWERS:
        const multipleVal = isMultipleAnswer(answer) ? answer.multiple : [];

        return {
          ...acc,
          [`${id}`]: options.answers.reduce(
            (acc, question) => ({
              ...acc,
              [question]: multipleVal.includes(question),
            }),
            {}
          ),
        };
      case API.QuestionType.NUMERICAL_QUESTION:
        const numericVal = isNumericAnswer(answer) ? `${answer.numeric}` : "";
        return {
          ...acc,
          [`${id}`]: numericVal,
        };
      case API.QuestionType.TRUE_FALSE:
        const booleanVal = isBooleanAnswer(answer)
          ? answer.bool
            ? "true"
            : "false"
          : "";
        return {
          ...acc,
          [`${id}`]: booleanVal,
        };
      case API.QuestionType.MATCHING:
        const matchingVal = isMatchingAnswer(answer) ? answer.matching : {};
        return { ...acc, [`${id}`]: matchingVal };
      default:
        return acc;
    }
  }, {});
};

function useAttemptsTooltip(quizId: number | undefined) {
  const { token, apiUrl } = useContext(EscolaLMSContext);
  const [attempts, setAttempts] = useState({
    loading: false,
    count: 0,
    tooltipOpen: false,
  });

  const refreshAttemptsCount = useCallback(() => {
    if (token && quizId) {
      getQuizAttempts(apiUrl, token, { topic_gift_quiz_id: quizId })
        .then((res) => {
          if (res.success) {
            setAttempts((prev) => ({
              ...prev,
              count: res.meta.total,
              tooltipOpen: true,
            }));
          }
        })
        .finally(() => {
          setAttempts((prev) => ({ ...prev, loading: false }));
        });
    }
  }, [apiUrl, quizId, token]);

  return useMemo(
    () => ({
      attempts,
      refreshAttemptsCount,
      closeTooltip: () =>
        setAttempts((prev) => ({ ...prev, tooltipOpen: false })),
    }),
    [attempts, refreshAttemptsCount]
  );
}

export const GiftQuizPlayerContent: FC<Props> = ({
  attempt,
  startQuiz,
  endQuiz,
  sendAnswer,
  currentQuestionId,
  showOnlyOneQuestion,
  nextQuestionId,
  previousQuestionId,
  challengeId,
}) => {
  const mobileView = useMediaQuery("mobile");

  const theme = useTheme();
  const navigate = useNavigate();
  const { attempts, refreshAttemptsCount, closeTooltip } = useAttemptsTooltip(
    attempt.topic_gift_quiz_id
  );
  const defaultValues = useMemo(
    () => getDefaultValues(attempt.questions, attempt.answers),
    [attempt.questions, attempt.answers]
  );

  const getQuizResultScore = useCallback(
    (questionId: number): number | undefined | null =>
      (attempt.answers as unknown as GiftQuizAnswerObj[]).find(
        ({ topic_gift_question_id }) => topic_gift_question_id === questionId
      )?.score,
    [attempt.answers]
  );

  const filteredQuestions = useMemo(() => {
    const unescapedQuestions: API.QuizQuestion[] = attempt.questions.map(
      ({ question, ...rest }: API.QuizQuestion) => ({
        ...rest,
        question: unescapeSpecificCharacters(question),
      })
    );

    return showOnlyOneQuestion
      ? unescapedQuestions.filter(
          (question) => question.id === currentQuestionId
        )
      : unescapedQuestions;
  }, [attempt.questions, currentQuestionId, showOnlyOneQuestion]);

  return (
    <>
      {showOnlyOneQuestion && (
        <ProgressContainer>
          <ProgressTitle>Test poziomujący</ProgressTitle>
          <ProgressBar
            bgColor={theme?.colors.gray90}
            fillColor={theme?.colors.greenDark}
            size="small"
            staticPercentage
            currentProgress={
              attempt.questions.findIndex(
                ({ id }: { id: number }) => id === currentQuestionId
              ) + 1
            }
            maxProgress={attempt.questions.length}
          />
        </ProgressContainer>
      )}
      <Formik
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={() => {
          endQuiz(attempt.id);
          refreshAttemptsCount();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          handleBlur,
          resetForm,
          setFieldValue,
          submitForm,
        }) => (
          <>
            <GiftQuizPlayerContentForm
              onSubmit={handleSubmit}
              data-testid="gift-quiz-player-content"
              $singleQuestion={showOnlyOneQuestion}
            >
              <Stack $gap={8}>
                {!showOnlyOneQuestion && (
                  <GiftQuizHeader
                    $justifyContent="space-between"
                    $alignItems="center"
                  >
                    {attempt?.is_ended ? (
                      <>
                        <GiftQuizScore
                          result={attempt?.result_score}
                          max={attempt?.max_score}
                          minPassScore={attempt?.min_pass_score}
                        />
                        <Button
                          styleVariant="primaryBlack"
                          type="button"
                          onClick={() => {
                            startQuiz();
                            resetForm();
                          }}
                        >
                          Powtórz test
                        </Button>
                      </>
                    ) : (
                      <>
                        {attempt?.end_at && (
                          <Countdown
                            targetDate={String(attempt?.end_at)}
                            onCountdownEnd={() => endQuiz(attempt?.id)}
                          />
                        )}
                        <Button type="submit" styleVariant="primaryBlack">
                          Wyślij
                        </Button>
                      </>
                    )}
                  </GiftQuizHeader>
                )}
                {attempts.tooltipOpen && (
                  <AttemptsTooltipWrapper
                    $alignItems="center"
                    $justifyContent="space-between"
                  >
                    {`Wykonanych prób: ${attempts.count}`}
                    <CloseButton onClick={closeTooltip}>&times;</CloseButton>
                  </AttemptsTooltipWrapper>
                )}
              </Stack>
              {filteredQuestions?.map((question: API.QuizQuestion) => {
                switch (question.type) {
                  case API.QuestionType.MULTIPLE_CHOICE:
                    return (
                      <MultipleChoice
                        {...question}
                        key={question.type + question.id}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          sendAnswer(question.id, {
                            text: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[`${question.id}`] as string}
                        hasQuizEnded={attempt?.is_ended}
                        resultScore={getQuizResultScore(question.id)}
                      />
                    );
                  case API.QuestionType
                    .MULTIPLE_CHOICE_WITH_MULTIPLE_RIGHT_ANSWERS:
                    return (
                      <MultipleChoiceWithMultipleRightAnswers
                        {...question}
                        key={question.type + question.id}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          const currOption = e.target.value;
                          const currVal = e.target.checked;

                          const answer = Object.entries(
                            values[`${question.id}`]
                          ).reduce<string[]>((acc, [key, bool]) => {
                            if (key === currOption) {
                              return currVal ? [...acc, currOption] : acc;
                            }

                            return bool ? [...acc, key] : acc;
                          }, []);

                          sendAnswer(question.id, {
                            multiple: answer,
                          });
                        }}
                        onBlur={handleBlur}
                        values={
                          values[`${question.id}`] as Record<string, boolean>
                        }
                        hasQuizEnded={attempt?.is_ended}
                        resultScore={getQuizResultScore(question.id)}
                      />
                    );
                  case API.QuestionType.TRUE_FALSE:
                    return (
                      <TrueFalse
                        {...question}
                        key={question.type + question.id}
                        onChange={(e: any) => {
                          handleChange(e);
                          sendAnswer(question.id, {
                            bool: e.target.value === "true",
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[`${question.id}`] as string}
                        hasQuizEnded={attempt?.is_ended}
                        resultScore={getQuizResultScore(question.id)}
                      />
                    );
                  case API.QuestionType.SHORT_ANSWERS:
                    return (
                      <ShortAnswers
                        {...question}
                        key={question.type + question.id}
                        onChange={(e) => {
                          handleChange(e);
                          sendAnswer(question.id, {
                            text: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[`${question.id}`] as string}
                        resultScore={getQuizResultScore(question.id)}
                        hasQuizEnded={attempt?.is_ended}
                      />
                    );
                  case API.QuestionType.MATCHING:
                    return (
                      <Matching
                        {...question}
                        key={question.type + question.id}
                        onChange={(values: GiftQuizMatchingAnswer) => {
                          setFieldValue(`${question.id}`, values);
                          sendAnswer(question.id, values);
                        }}
                        values={
                          values[`${question.id}`] as Record<string, string>
                        }
                        resultScore={getQuizResultScore(question.id)}
                        hasQuizEnded={attempt?.is_ended}
                      />
                    );
                  case API.QuestionType.NUMERICAL_QUESTION:
                    return (
                      <NumericalQuestion
                        {...question}
                        key={question.type + question.id}
                        onChange={(e) => {
                          handleChange(e);
                          sendAnswer(question.id, {
                            numeric: +e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[`${question.id}`] as string}
                        resultScore={getQuizResultScore(question.id)}
                        hasQuizEnded={attempt?.is_ended}
                      />
                    );
                  case API.QuestionType.ESSAY:
                    return (
                      <Essay
                        {...question}
                        key={question.type + question.id}
                        onChange={(e) => {
                          handleChange(e);
                          sendAnswer(question.id, {
                            text: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[`${question.id}`] as string}
                        resultScore={getQuizResultScore(question.id)}
                        hasQuizEnded={attempt?.is_ended}
                      />
                    );
                  case API.QuestionType.DESCRIPTION:
                    return (
                      <Description
                        {...question}
                        key={question.type + question.id}
                      />
                    );
                  default:
                    return <React.Fragment />;
                }
              })}
            </GiftQuizPlayerContentForm>
            <ButtonsRow>
              {previousQuestionId && (
                <Button
                  size="small"
                  styleVariant="secondaryGray"
                  iconGap={mobileView ? 20 : 60}
                  icon={<Icon name="arrowLeft" />}
                  iconOnLeft
                  type="button"
                  onClick={() =>
                    navigate(
                      routeRoutes.competencyTest
                        .replace(":questionId", String(previousQuestionId))
                        .replace(":quizId", String(attempt.topic_gift_quiz_id))
                        .replace(":challengeId", String(challengeId))
                    )
                  }
                >
                  Poprzednie
                </Button>
              )}

              {nextQuestionId && (
                <Button
                  style={{
                    marginLeft: "auto",
                  }}
                  size="small"
                  iconGap={mobileView ? 20 : 60}
                  icon={<Icon name="arrowRight" />}
                  type="button"
                  onClick={() =>
                    navigate(
                      routeRoutes.competencyTest
                        .replace(":questionId", String(nextQuestionId))
                        .replace(":quizId", String(attempt.topic_gift_quiz_id))
                        .replace(":challengeId", String(challengeId))
                    )
                  }
                >
                  Przejdź dalej
                </Button>
              )}

              {!nextQuestionId && showOnlyOneQuestion && (
                <Button
                  type="submit"
                  onClick={submitForm}
                  styleVariant="primaryBlack"
                >
                  Wyślij odpowiedzi
                </Button>
              )}
            </ButtonsRow>
          </>
        )}
      </Formik>
    </>
  );
};

export default GiftQuizPlayerContent;
