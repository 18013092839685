import React from "react";

import CourseProvider, { useCourse } from "components/course/Context";
import CourseContentCard from "components/course/CourseContentCard";
import CourseLayout from "components/course/CourseLayout";
import CourseProgramContent from "components/course/CourseProgramContent";

// TODO add loading & error state
const CourseContent: React.FC = () => {
  const { currentCourseProgram } = useCourse();

  if (!currentCourseProgram) return null;

  return (
    <CourseLayout>
      <CourseContentCard>
        <CourseProgramContent />
      </CourseContentCard>
    </CourseLayout>
  );
};

const Course: React.FC = () => (
  <CourseProvider>
    <CourseContent />
  </CourseProvider>
);

export default Course;
