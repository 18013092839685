import { API } from "@escolalms/sdk/lib";

import React from "react";

import { Stack } from "components/common/Stack";

type Props = API.QuizQuestion_Description;

const Description: React.FC<Props> = ({ title, question }) => (
  <Stack data-testid={`description-${question}`} $gap={2}>
    {title && <p>{title}</p>}
    <p>{question}</p>
  </Stack>
);

export default Description;
