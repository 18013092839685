import { Link } from "react-router-dom";

import React from "react";

import AuthLayout from "components/App/AuthLayout";
import routeRoutes from "components/Routes/routes";
import Icon from "components/common/Icon";

import bgImg from "assets/images/man_with_laptop.svg";

import RegisterForm from "./RegisterForm";
import { LoginInfo } from "./styles";

const RegisterPage: React.FC = () => {
  return (
    <AuthLayout title="Rejestracja" backgroundImgSrc={bgImg}>
      <LoginInfo>
        Jeśli posiadasz już konto to,{" "}
        <Link to={routeRoutes.login}>
          Zaloguj się
          <span>
            <Icon name="arrowRight" />
          </span>
        </Link>
      </LoginInfo>
      <RegisterForm />
    </AuthLayout>
  );
};

export default RegisterPage;
