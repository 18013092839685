import { styled } from "styled-components";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  span {
  }
`;

export const LabelSuffix = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.small};
  display: inline-block;
  margin-left: 3px;
`;

export const NewLineLabelSuffix = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.small};
  display: block;
`;

export const DetailsText = styled.div`
  color: #a0a0a0;
  padding-left: 17px;
`;

export const SubLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  display: block;
  margin-bottom: 10px;
  padding-left: 17px;
`;
