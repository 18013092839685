import { Row } from "react-grid-system";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import Icon from "components/common/Icon";
import { Stack } from "components/common/Stack";

export const StyledFooter = styled.footer`
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: 40px 40px 25px 40px;
`;

export const Heading = styled.h6`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StyledRow = styled(Row)`
  @media ${device.mobile} {
    gap: 50px;
  }
`;

export const List = styled.ul<{
  $marginTop?: number;
}>`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${({ $marginTop }) => $marginTop || 13}px;

  li {
    line-height: normal;
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 0px;
  }
`;

export const CompanyDetailsItem = styled.li`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const Email = styled.a`
  color: ${({ theme }) => theme.colors.blueSecondary};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: underline;
`;

export const SocialsContainer = styled.div``;

export const IconsContainer = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 9px;
`;

export const BottomRow = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding: 31px 0;
  margin-top: 35px;
`;

export const CopyContainer = styled(Row)`
  justify-content: space-between;
  align-self: flex-end;
  padding: 0px 15px;
  gap: 12px;
  & > a {
    margin: 0px;
  }
  @media ${device.mobile} {
    align-self: flex-start;
  }
`;

export const Copy = styled.span`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  line-height: 1.2;
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  margin: 0 18px;
  text-decoration: none;
  line-height: 1.2;
`;

export const Divider = styled.div`
  align-self: center;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray400};
`;

export const StyledStack = styled(Stack)`
  height: 100%;
  gap: 25px;
  align-items: flex-end;
  justify-content: space-between;
  @media ${device.mobile} {
    align-items: flex-start;
  }
`;

export const GoTopButton = styled(Icon)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;
