import styled from "styled-components";
import { device } from "theme/breakpoints";
import { ellipsisText } from "theme/mixins";

export const Wrapper = styled.div<{ $mobile: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(mobile) => (mobile ? "row" : "column")};
  gap: ${({ $mobile }) => ($mobile ? "21px" : "5px")};
  padding: ${(mobile) => (mobile ? "8px" : "8px 81px 8px 68px")};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SubTitleContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
`;

export const SubTitleLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const SubTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding-right: 30px;
  ${ellipsisText}
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  @media ${device.mobile} {
    width: 90%;
  }
`;
