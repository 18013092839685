import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { toast } from "react-toastify";

import React, { useCallback, useContext } from "react";

import UserPanelLayout from "components/App/UserPanelLayout";
import { Row } from "components/common/Row";
import { Switch } from "components/common/Switch";

import { Box, StyledRow, Subheader, Text } from "./styles";

const NewsletterPage: React.FC = () => {
  const { updateProfile, user } = useContext(EscolaLMSContext);

  const newsletter = user?.value?.newsletter;

  const handleNewsletter = useCallback(
    (newsletterValue: boolean) => {
      updateProfile({ newsletter: newsletterValue })
        .then((res) => {
          if (res.success) {
            res.data.newsletter
              ? toast.success("Wyrażono zgodę na newsletter")
              : toast.success("Nie wyrażono zgody na newsletter");
          } else {
            toast.error("Wystąpił błąd. Spróbuj ponownie.");
          }
        })
        .catch((error) => {
          toast.error("Wystąpił błąd. Spróbuj ponownie.");
          console.log(error);
        });
    },
    [updateProfile]
  );

  return (
    <UserPanelLayout title="Newsletter">
      <Box>
        <StyledRow>
          <Subheader>Pobieraj newsletter</Subheader>
          <Row $alignItems="center" $gap={8}>
            <Text>Nie</Text>
            <Switch
              checked={newsletter}
              onChange={() => handleNewsletter(!newsletter)}
            />
            <Text>Tak</Text>
          </Row>
        </StyledRow>
        <Text>
          Pamiętaj, że zapisując się do newslettera akceptujesz warunki
          korzystania z newslettera
        </Text>
      </Box>
    </UserPanelLayout>
  );
};

export default NewsletterPage;
