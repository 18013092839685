import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import { device } from "theme/breakpoints";

export const StyledRow = styled(Row)<{ $headerHeight: number }>`
  min-height: ${({ $headerHeight }) => `calc(100vh - ${$headerHeight}px)`};
`;

export const LessonContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const CourseOverlay = styled.div<{
  $absoluteOffset: number;
}>`
  flex: 1;

  display: flex;
  justify-content: center;

  padding-block: 16px 51px;
  background-color: ${({ theme }) => theme.colors.gray80};
  max-height: ${({ $absoluteOffset }) => `calc(100vh - ${$absoluteOffset}px)`};

  overflow-y: auto;

  @media ${device.mobile} {
    padding-bottom: 73px;
  }
`;
