import React from "react";

import Icon from "../Icon";
import { Description, Wrapper } from "./styles";

const InfoText: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <Wrapper {...props}>
    <Icon name="info" />
    <Description>{children}</Description>
  </Wrapper>
);

export default InfoText;
