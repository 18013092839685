export interface ExtendableStyledComponent {
  className?: string;
}

export enum Gender {
  NOT_SELECTED = "nie chcę podawać",
  FEMALE = "kobieta",
  MALE = "mężczyzna",
  NON_BINARY = "osoba niebinarna",
}
