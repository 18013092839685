import { styled } from "styled-components";

import React from "react";

type Props = {
  children: React.ReactNode;
};

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: 15px;
`;

const SectionTitle: React.FC<Props> = ({ children }) => {
  return <Title>{children}</Title>;
};

export default SectionTitle;
