import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { useContext, useEffect, useState } from "react";

import CertificateCompetenceCard from "components/CertificateCompetenceCard";
import { Pagination } from "components/Pagination";
import { Stack } from "components/common/Stack";

import { CompletedCoursesWrapper, StyledTitle } from "../styles";

const CompletedCoursesSection: React.FC = () => {
  const { certificates, fetchCertificates } = useContext(EscolaLMSContext);
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = certificates.list?.meta.last_page || 1;

  useEffect(() => {
    fetchCertificates({ page: currentPage, per_page: 5 });
  }, [currentPage, fetchCertificates]);

  return (
    <>
      <CompletedCoursesWrapper>
        {certificates?.list?.data.length !== 0 && (
          <div data-testid="certificates-page">
            <StyledTitle>Zakończone</StyledTitle>

            <Stack $gap={8}>
              {(certificates?.list?.data ?? []).map(
                (certificate: API.Certificate) => (
                  <div key={certificate.id}>
                    <CertificateCompetenceCard certificate={certificate} />
                  </div>
                )
              )}
            </Stack>
          </div>
        )}
      </CompletedCoursesWrapper>
      {lastPage > 1 && (
        <Pagination
          pageCount={lastPage}
          setCurrentPage={(item: number) => setCurrentPage(item)}
        />
      )}
    </>
  );
};

export default CompletedCoursesSection;
