import { Col } from "react-grid-system";

import React, { useState } from "react";

import { useComponentHeight } from "hooks/useComponentHeight";
import useMediaQuery from "hooks/useMediaQuery";

import { useCourse } from "../Context";
import CourseHeader from "../CourseHeader";
import CourseLessonList from "../CourseLessonList";
import { MobileLessonListWrapper } from "../CourseLessonList/styles";
import CourseSubheader from "../CourseSubheader";
import { CourseOverlay, LessonContentCol, StyledRow } from "./styles";

type Props = React.PropsWithChildren;

const CourseLayout: React.FC<Props> = ({ children }) => {
  const { isAnyDataLoading } = useCourse();

  const [headerRef, headerHeight] = useComponentHeight(61, isAnyDataLoading);
  const [subheaderRef, subheaderHeight] = useComponentHeight(
    50,
    isAnyDataLoading
  );
  const [showLessonList, setShowLessonList] = useState(false);
  const mobileView = useMediaQuery("mobile");

  return (
    <>
      <CourseHeader ref={headerRef} />
      <StyledRow nogutter $headerHeight={headerHeight}>
        <LessonContentCol>
          <CourseSubheader
            ref={subheaderRef}
            mobile={mobileView}
            showLessonList={showLessonList}
            setShowLessonList={() => setShowLessonList(!showLessonList)}
          />
          <CourseOverlay $absoluteOffset={headerHeight + subheaderHeight}>
            {children}
          </CourseOverlay>
        </LessonContentCol>
        {!mobileView && (
          <Col sm="content">
            <CourseLessonList
              headerHeight={headerHeight}
              mobile={mobileView}
              showLessonList={showLessonList}
            />
          </Col>
        )}
      </StyledRow>
      {mobileView && showLessonList && (
        <MobileLessonListWrapper $headerHeight={headerHeight + subheaderHeight}>
          <CourseLessonList
            headerHeight={headerHeight + subheaderHeight}
            mobile={mobileView}
            showLessonList={showLessonList}
          />
        </MobileLessonListWrapper>
      )}
    </>
  );
};

export default CourseLayout;
