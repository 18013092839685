import { styled } from "styled-components";
import { Color } from "theme";
import { device } from "theme/breakpoints";

import Icon from "components/common/Icon";

export const StyledHeader = styled.header<{
  $barColor?: Color;
}>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 9px 9px 17px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: ${({ theme, $barColor }) =>
      $barColor ? theme.colors[$barColor] : theme.colors.white};
    border-radius: 25px 25px 0 0;
    left: 0;
    bottom: 0;
    z-index: 998;
  }
`;

export const LogoWrapper = styled.div`
  & picture {
    display: flex;
    aspect-ratio: unset;
  }
`;

export const UserEmail = styled.div`
  position: relative;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const UserMenuWrapper = styled.div`
  position: absolute;
  top: 36px;
  right: 0;
  display: none;
  @media ${device.mobile} {
    top: 52px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
  position: relative;
  cursor: pointer;
  z-index: 999;
  padding: 8px 12px;
  border-radius: 18px;

  &:hover {
    ${UserMenuWrapper} {
      display: block;
    }
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const MobileIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: 41px;
  width: auto;
`;
