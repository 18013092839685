import { styled } from "styled-components";

export const Card = styled.div`
  width: 100%;
  border: 2px dashed ${({ theme }) => theme.colors.gray100};
  border-radius: 9px;
  background-color: ${({ theme }) => theme.colors.gray50};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 43px;
`;
