import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useState } from "react";

import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Input from "components/common/Input";

import { getErrorMessage } from "utils";

import { changePasswordSchema } from "validation/userDataFormValidation";

import {
  FormWrapper,
  InitialValueWrapper,
  InputWrapper,
  ResetPasswordLink,
  StyledRow,
} from "../styles";

type InitialValues = {
  current_password: string;
  password: string;
  new_confirm_password: string;
};

export const UserPasswordForm: React.FC = () => {
  const { changePassword } = useContext(EscolaLMSContext);
  const [editPassword, setEditPassword] = useState(false);

  const initialValues: InitialValues = {
    current_password: "",
    password: "",
    new_confirm_password: "",
  };

  const onSubmit = useCallback(
    (values: InitialValues) => {
      changePassword({
        current_password: values.current_password,
        new_password: values.password,
        new_confirm_password: values.new_confirm_password,
      })
        .then((response) => {
          if (response.success) {
            setEditPassword(false);
            toast.success("Hasło zostało zmienione");
          } else {
            toast.error("Wystąpił błąd podczas zmiany hasła");
          }
        })
        .catch((error) => {
          toast.error("Wystąpił błąd podczas zmiany hasła");
          console.log(error);
        });
    },
    [changePassword]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: onSubmit,
  });

  return (
    <StyledRow $justifyContent="space-between" $flexWrap="wrap">
      {editPassword ? (
        <FormWrapper onSubmit={formik.handleSubmit}>
          <InputWrapper>
            <Input
              type="password"
              id="current_password"
              name="current_password"
              placeholder="Podaj aktualne hasło"
              label="Hasło"
              disabled={!editPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.current_password}
              error={getErrorMessage<InitialValues>("current_password", formik)}
            />
            <ResetPasswordLink>
              <Link to={routeRoutes.resetPassword}>Nie pamiętasz?</Link>
            </ResetPasswordLink>
            <Input
              type="password"
              id="password"
              name="password"
              placeholder="Podaj nowe hasło"
              label="Nowe hasło"
              disabled={!editPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={getErrorMessage<InitialValues>("password", formik)}
            />
            <Input
              type="password"
              id="new_confirm_password"
              name="new_confirm_password"
              placeholder="Powtórz nowe hasło"
              disabled={!editPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.new_confirm_password}
              error={getErrorMessage<InitialValues>(
                "new_confirm_password",
                formik
              )}
            />
          </InputWrapper>
          <Button type="submit" styleVariant="secondaryGreen">
            Zapisz nowe hasło
          </Button>
        </FormWrapper>
      ) : (
        <InitialValueWrapper>
          <h4>Hasło</h4>
          <p>********</p>
        </InitialValueWrapper>
      )}
      <Button
        styleVariant="primaryText"
        onClick={() => setEditPassword(!editPassword)}
      >
        {editPassword ? "Anuluj" : "Nowe hasło"}
      </Button>
    </StyledRow>
  );
};
