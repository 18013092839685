import { API } from "@escolalms/sdk/lib/index";
import { toast } from "react-toastify";

import React, { useEffect } from "react";

import Loader from "components/Loader";
import Button from "components/common/Button";

import { useQuiz } from "hooks/useQuiz";

import GiftQuizPlayerContent from "./GiftQuizPlayerContent";
import { GiftQuizPlayerWrapper, StartButtonWrapper } from "./styles";

interface Props {
  topic: API.TopicQuiz;
  onTopicEnd?: () => void;
}

export const GiftQuizPlayer: React.FC<Props> = ({ topic, onTopicEnd }) => {
  const { data, startQuiz, sendAnswer, endQuiz } = useQuiz(
    topic.topicable.id,
    onTopicEnd
  );

  useEffect(() => {
    if (!data.error || data.error.message !== "Too many attempts") return;

    // Unlock next button, and show toast
    onTopicEnd?.();
    toast.error("Za dużo prób!");
  }, [data.error]);

  return (
    <GiftQuizPlayerWrapper data-testid="gift-quiz-player">
      {!data.value && !data.loading && (
        <StartButtonWrapper>
          <Button styleVariant="primaryBlack" type="button" onClick={startQuiz}>
            Start
          </Button>
        </StartButtonWrapper>
      )}
      {data.loading && !data.value && <Loader />}
      {data.value && (
        <GiftQuizPlayerContent
          attempt={data.value}
          startQuiz={startQuiz}
          sendAnswer={sendAnswer}
          endQuiz={endQuiz}
        />
      )}
    </GiftQuizPlayerWrapper>
  );
};

export default GiftQuizPlayer;
