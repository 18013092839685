import * as Yup from "yup";

import {
  emailValidation,
  passwordValidation,
  repeatPasswordValidation,
} from "./common";

export const forgotPasswordSchema = Yup.object().shape({
  email: emailValidation,
});

export const resetPasswordSchema = Yup.object().shape({
  password: passwordValidation,
  repeatPassword: repeatPasswordValidation,
});
