import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { DATE_FORMAT } from "const/dates";
import { format } from "date-fns";

import React, { useContext, useEffect, useMemo, useState } from "react";

import Sidebar from "components/App/Sidebar";
import CompetencyDetailsCard from "components/CompetencyDetailsCard";
import ProgressBar from "components/ProgressBar";
import TextWithIcon from "components/TextWithIcon";
import Icon from "components/common/Icon";

import useMediaQuery from "hooks/useMediaQuery";
import { PickedChallengeData } from "hooks/usePickedChallenges";

import {
  getFlatTopics,
  getPreviousProgressedCourseProgressItemElement,
} from "utils/course";

import routeRoutes from "../../Routes/routes";
import {
  CourseInfoWrapper,
  DetailsContainer,
  LeftCol,
  List,
  ListEmpty,
  ListItem,
  ListTitle,
  PairContainer,
  RightCol,
  StyledButton,
} from "./styles";

const CourseInfo: React.FC<PickedChallengeData> = ({
  course,
  suggestedCourseId,
  progress,
  competencyCategory,
  levelCategory,
}) => {
  const mobile = useMediaQuery("mobile");
  const { fetchCourse } = useContext(EscolaLMSContext);
  const [matchedCourse, setMatchedCourse] = useState<API.Course | undefined>(
    undefined
  );
  const [showSidebar, setShowSidebar] = useState(false);

  const topicsByRootLesson = useMemo(
    () =>
      course.lessons.reduce<
        {
          lesson: API.Lesson;
          topics: API.Topic[];
          progress: API.CourseProgressItemElement[];
        }[]
      >((acc, lesson) => {
        const topics = getFlatTopics(lesson.lessons ?? []);
        const topicsProgress = progress.progress.filter(({ topic_id }) =>
          topics.find((t) => t.id === topic_id)
        );

        return [
          ...acc,
          {
            lesson,
            topics,
            progress: topicsProgress,
          },
        ];
      }, []),
    [course.lessons, progress.progress]
  );

  const { currentProgress, maxProgress } = useMemo(
    () =>
      topicsByRootLesson.reduce<{
        currentProgress: number;
        maxProgress: number;
      }>(
        (acc, { progress }) => {
          const isCurrentLessonCompleted =
            progress.length > 0
              ? progress.every(
                  ({ status }) =>
                    status === API.CourseProgressItemElementStatus.COMPLETE
                )
              : false;

          return {
            currentProgress: isCurrentLessonCompleted
              ? acc.currentProgress + 1
              : acc.currentProgress,
            maxProgress: acc.maxProgress + 1,
          };
        },
        { currentProgress: 0, maxProgress: 0 }
      ),
    [topicsByRootLesson]
  );

  const currentTopics = useMemo(
    () =>
      topicsByRootLesson.find(({ progress }) =>
        progress.some(
          ({ status }) =>
            status !== API.CourseProgressItemElementStatus.COMPLETE
        )
      )?.topics ?? [],
    [topicsByRootLesson]
  );

  const courseLink = useMemo(() => {
    const flatTopics = getFlatTopics(course.lessons ?? []);
    const progressElements = progress.progress.reduce<
      Record<number, API.CourseProgressItemElement>
    >(
      (acc, progressItemElement) => ({
        ...acc,
        [progressItemElement.topic_id]: progressItemElement,
      }),
      {}
    );
    const reorderedProgress = flatTopics.reduce<
      API.CourseProgressItemElement[]
    >(
      (acc, t) =>
        progressElements?.[t.id] ? [...acc, progressElements?.[t.id]] : acc,
      []
    );

    const previousProgressedTopicId =
      getPreviousProgressedCourseProgressItemElement(
        reorderedProgress
      )?.topic_id;
    if (previousProgressedTopicId === undefined) {
      return routeRoutes.course
        .replace(":courseId", "14")
        .replace("/:topicId", "");
    }

    const courseId = progress.course.id;

    return routeRoutes.course
      .replace(":courseId", `${courseId}`)
      .replace(":topicId", `${previousProgressedTopicId}`);
  }, [course.lessons, progress.course.id, progress.progress]);

  useEffect(() => {
    suggestedCourseId &&
      fetchCourse(suggestedCourseId).then((response) =>
        response.success
          ? setMatchedCourse(response.data)
          : setMatchedCourse(undefined)
      );
  }, [fetchCourse, suggestedCourseId]);

  return (
    <>
      <CourseInfoWrapper>
        <LeftCol>
          <DetailsContainer>
            <PairContainer>
              <TextWithIcon
                text={competencyCategory.name}
                label="Kompetencja:"
                iconName="crosshair"
              />
              <TextWithIcon
                text={levelCategory.name}
                label="Poziom wyzwania:"
                iconName="level"
              />
            </PairContainer>
            {course.active_from && course.active_to && (
              <PairContainer>
                <TextWithIcon
                  text={format(new Date(course.active_from), DATE_FORMAT)}
                  label="Start wyzwania:"
                  iconName="calendar"
                />
                <TextWithIcon
                  text={format(new Date(course.active_to), DATE_FORMAT)}
                  label="Koniec wyzwania:"
                  iconName="calendar"
                />
              </PairContainer>
            )}
            <TextWithIcon label="Postęp w wyzwaniu:" iconName="progress" />
            <ProgressBar
              currentProgress={currentProgress}
              maxProgress={maxProgress}
              text={
                currentProgress > 0
                  ? `Tydzień ${currentProgress} z ${maxProgress}`
                  : ""
              }
            />
          </DetailsContainer>
          {!mobile &&
            (progress.finish_date ? (
              <StyledButton
                styleVariant="primaryYellow"
                iconGap={63}
                icon={<Icon name="arrowRightBlack" />}
                onClick={() => setShowSidebar(true)}
              >
                <strong>Przejdź na wyższy poziom</strong>
              </StyledButton>
            ) : (
              <StyledButton
                to={courseLink}
                styleVariant="primaryYellow"
                iconGap={63}
                icon={<Icon name="arrowRightBlack" />}
              >
                <strong>Kontynuuj naukę</strong>
              </StyledButton>
            ))}
        </LeftCol>
        <RightCol>
          <ListTitle>W tym tygodniu czeka Cię:</ListTitle>
          {currentTopics.length > 0 ? (
            <List>
              {currentTopics.map((t) => (
                <ListItem key={t.id}>{t.title}</ListItem>
              ))}
            </List>
          ) : (
            <ListEmpty>Brak lekcji w tym tygodniu</ListEmpty>
          )}
          {mobile &&
            (progress.finish_date ? (
              <StyledButton
                styleVariant="primaryYellow"
                iconGap={63}
                icon={<Icon name="arrowRightBlack" />}
                onClick={() => setShowSidebar(true)}
              >
                <strong>Przejdź na wyższy poziom</strong>
              </StyledButton>
            ) : (
              <StyledButton
                to={courseLink}
                styleVariant="primaryYellow"
                iconGap={63}
                icon={<Icon name="arrowRightBlack" />}
              >
                <strong>Kontynuuj naukę</strong>
              </StyledButton>
            ))}
        </RightCol>
      </CourseInfoWrapper>
      {showSidebar && matchedCourse && (
        <Sidebar
          title={matchedCourse?.title}
          onClose={() => setShowSidebar(false)}
        >
          <CompetencyDetailsCard
            matchedHigherLevelCourse={matchedCourse}
            onClose={() => setShowSidebar(false)}
          />
        </Sidebar>
      )}
    </>
  );
};

export default CourseInfo;
