import { Row } from "components/common/Row";

import { checkQuizScore } from "utils/course";

import { GiftQuizScoreWrapper } from "./styles";

export type QuizScoreColor = "systemDanger" | "systemPositive";

interface Props {
  result?: number;
  max?: number;
  minPassScore?: number;
}

const GiftQuizScore: React.FC<Props> = ({ result, max, minPassScore }) => (
  <GiftQuizScoreWrapper
    data-testid="gift-quiz-score"
    passedQuiz={
      minPassScore
        ? checkQuizScore({
            max_score: Number(max),
            min_pass_score: minPassScore,
            result_score: Number(result),
          })
        : undefined
    }
  >
    <Row $justifyContent="space-between" $gap={12}>
      <p>Twój wynik:</p>
      <p>
        {result}/{max}
      </p>
    </Row>
    {minPassScore && <p>Próg zdawalności: {minPassScore}% </p>}
  </GiftQuizScoreWrapper>
);

export default GiftQuizScore;
