import { API } from "@escolalms/sdk/lib";
import { useNavigate } from "react-router-dom";
import { LessonListTopicState } from "types/course";

import React, { useCallback, useMemo } from "react";

import routeRoutes from "components/Routes/routes";
import Icon, { IconName } from "components/common/Icon";

import { onKeyboardInteraction } from "utils/a11y";

import { useCourse } from "../../Context";
import { CardTitle, Col, ListItem, TopicTitle } from "./styles";

type StateWithoutAvailable = Exclude<LessonListTopicState, "available">;

export interface Props {
  index: number;
  topic: API.Topic;
}

const ICON_MAP: Record<StateWithoutAvailable, IconName> = {
  finished: "finish",
  current: "play",
  locked: "lock",
};

const CourseTopics: React.FC<Props> = ({ index, topic }) => {
  const {
    currentTopic,
    finishedTopicsIds,
    currentCourseProgram,
    availableTopicsIds,
  } = useCourse();
  const navigate = useNavigate();

  const state: LessonListTopicState = useMemo(() => {
    if (currentTopic?.id === topic.id) return "current";
    if (finishedTopicsIds?.includes(topic.id)) return "finished";
    if (availableTopicsIds?.includes(topic.id)) return "available";

    return "locked";
  }, [availableTopicsIds, currentTopic?.id, finishedTopicsIds, topic.id]);

  const clickable = state === "finished" || state === "available";

  const onClick = useCallback(() => {
    if (!clickable || currentCourseProgram?.id === undefined) return;

    const to = routeRoutes.course
      .replace(":courseId", `${currentCourseProgram.id}`)
      .replace(":topicId", `${topic.id}`);
    navigate(to);
  }, [clickable, navigate, currentCourseProgram?.id, topic.id]);

  return (
    <ListItem
      $state={state}
      data-testid="agenda-topic"
      aria-disabled={state === "locked"}
      tabIndex={clickable ? 0 : -1}
      onClick={onClick}
      onKeyDown={onKeyboardInteraction(onClick)}
      role="button"
    >
      <Col>
        <CardTitle>Temat {index}</CardTitle>
        <TopicTitle>{topic.title}</TopicTitle>
      </Col>
      {state !== "available" && <Icon name={ICON_MAP[state] ?? "lock"} />}
    </ListItem>
  );
};

export default CourseTopics;
