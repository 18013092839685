import { styled } from "styled-components";

export const ProgressBarFiller = styled.div<{ $progressPercentage: number }>`
  height: 26px;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: inherit;
  transition: width 0.2s ease-in-out;
  width: ${(props) => props.$progressPercentage}%;
  max-width: 100%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0;

  position: relative;
`;

export const StaticPercentage = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.greenDark};
  position: absolute;
  right: 20px;
  top: -25px;
`;

export const ProgressBarWrapper = styled.div<{ $size: "small" | "big" }>`
  width: 100%;
  height: ${({ $size }) => ($size === "small" ? "4px" : "26px")};
  background-color: ${({ theme }) => theme.colors.greenLight};
  border-radius: 3px;
  position: relative;

  ${ProgressBarFiller} {
    height: ${({ $size }) => ($size === "small" ? "4px" : "26px")};
  }
`;

export const ProgressBarText = styled.span`
  padding-left: 10px;
`;

export const DynamicPercentage = styled.span<{ $progressPercentage: number }>`
  position: absolute;
  right: -5px;
  top: 50%;
  color: ${({ theme, $progressPercentage }) =>
    $progressPercentage > 90 ? theme.colors.white : theme.colors.text};
  transform: ${({ $progressPercentage }) =>
    $progressPercentage > 90
      ? "translate(-50%, -50%);"
      : "translate(100%, -50%);"};
`;
