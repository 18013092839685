import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import {
  quizAttempt as fetchQuizAttempt,
  quizAnswer,
  quizAttemptFinish,
} from "@escolalms/sdk/lib/services/gfit_quiz";
import { GiftQuizAnswer } from "types/gift-quiz";

import { useCallback, useContext, useMemo, useRef, useState } from "react";

import { checkQuizScore } from "utils/course";

interface QuizData {
  loading: boolean;
  value?: API.QuizAttempt & {
    is_ended?: boolean;
  };
  error?: API.DefaultResponseError;
}

export function useQuiz(
  quizId: number | undefined,
  onTopicEnd?: (quizAttempt: API.QuizAttempt) => void
) {
  const [data, setData] = useState<QuizData>({ loading: false });
  const { token, apiUrl } = useContext(EscolaLMSContext);

  const topicEndCb = useRef(onTopicEnd);

  const questionsIds: number[] | undefined = useMemo(() => {
    return data.value?.questions.map((question: { id: number }) => question.id);
  }, [data.value]);

  const firstQuestionId: number | undefined = useMemo(() => {
    return questionsIds && questionsIds[0];
  }, [questionsIds]);

  const nextQuestionId = useCallback(
    (currentQuestionId: number) => {
      if (questionsIds) {
        const currentQuestionIndex = questionsIds.indexOf(currentQuestionId);
        const nextQuestionIndex = currentQuestionIndex + 1;
        return questionsIds[nextQuestionIndex];
      }
    },
    [questionsIds]
  );

  const previousQuestionId = useCallback(
    (currentQuestionId: number) => {
      if (questionsIds) {
        const currentQuestionIndex = questionsIds.indexOf(currentQuestionId);
        const previousQuestionIndex = currentQuestionIndex - 1;
        return questionsIds[previousQuestionIndex];
      }
    },
    [questionsIds]
  );

  const startQuiz = useCallback(async () => {
    if (quizId && token) {
      setData((prev) => ({ ...prev, loading: true }));
      await fetchQuizAttempt(apiUrl, token, {
        topic_gift_quiz_id: quizId,
      })
        .then((response) => {
          if (response.success) {
            setData((prev) => ({ ...prev, value: response.data }));
          } else {
            setData((prev) => ({ ...prev, error: response }));
          }
        })
        .catch((error: API.DefaultResponseError) => {
          setData((prev) => ({ ...prev, error }));
        })
        .finally(() => {
          setData((prev) => ({ ...prev, loading: false }));
        });
    }
  }, [quizId, apiUrl, token]);

  const endQuiz = useCallback(
    (quizAttemptId: number) => {
      if (token) {
        quizAttemptFinish(apiUrl, token, quizAttemptId).then((response) => {
          if (response.success) {
            const { max_score, min_pass_score, result_score } = response.data;

            setData((prev) => ({ ...prev, value: response.data }));
            checkQuizScore({ max_score, min_pass_score, result_score }) &&
              topicEndCb.current?.(response.data);
          }
        });
      }
    },
    [token, apiUrl]
  );

  const sendAnswer = useCallback(
    <Answer extends GiftQuizAnswer>(questionId: number, answer: Answer) => {
      if (data?.value?.id && token && !data?.value?.is_ended) {
        quizAnswer(apiUrl, token, {
          topic_gift_question_id: questionId,
          topic_gift_quiz_attempt_id: data.value.id,
          answer,
        });
      }
    },
    [token, apiUrl, data?.value?.id, data?.value?.is_ended]
  );

  const getQuestionAnswerObj = useCallback(
    (questionId: number) =>
      data.value?.answers?.find(
        (
          answerItem: API.QuizAttempt & {
            is_ended?: boolean;
          }
        ) => answerItem?.topic_gift_question_id === questionId
      ),
    [data.value?.answers]
  );

  return useMemo(
    () => ({
      data,
      startQuiz,
      sendAnswer,
      getQuestionAnswerObj,
      endQuiz,
      questionsIds,
      firstQuestionId,
      nextQuestionId,
      previousQuestionId,
    }),
    [
      data,
      sendAnswer,
      getQuestionAnswerObj,
      startQuiz,
      endQuiz,
      questionsIds,
      firstQuestionId,
      nextQuestionId,
      previousQuestionId,
    ]
  );
}
