import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Gender } from "types/common";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Loader from "components/Loader";
import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Input from "components/common/Input";
import RadioInput from "components/common/RadioInput";
import Select from "components/common/Select";
import StyledRangeInput from "components/common/StyledRangeInput";

import { getErrorMessage, getGenderEnum, getGenderNumber } from "utils";

import { onboardingSchema } from "validation/registerFormValidation";

import {
  ErrorMsg,
  Fieldset,
  FormWrapper,
  InputWrapper,
  Label,
  RangeInputWrapper,
} from "./styles";

enum Fields {
  GENDER = "gender",
  AGE = "age",
  EDUCATION = "education",
  CITY_SIZE = "city_size",
  SECTOR = "sector",
  DEPARTMENT = "department",
  POSITION = "position",
}

type Option = { pl: string; en: string };

type InitialValues = {
  gender: Gender;
  age: number;
  education: string;
  city_size: number;
  sector: string;
  department: string;
  position: string;
};

type Props = {
  onSuccess?: () => void;
};

const UserDetailsForm: React.FC<Props> = ({ onSuccess }) => {
  const [initialValues, setInitialValues] = useState<InitialValues>({
    gender: Gender.NOT_SELECTED,
    age: 1,
    education: "",
    city_size: 1,
    sector: "choose",
    department: "",
    position: "",
  });
  const { settings, fetchSettings, fetchFields, updateProfile, user } =
    useContext(EscolaLMSContext);

  const location = useLocation();
  const isMetricPage = location.pathname === routeRoutes.userPanel.metrics;

  const onSubmit = useCallback(
    (values: InitialValues) => {
      const remapedAge =
        settings.value?.onboarding?.[Fields.AGE]?.[values?.age - 1];
      const remapedCitySize =
        settings.value?.onboarding[Fields.CITY_SIZE]?.[values?.city_size - 1];

      updateProfile({
        ...values,
        gender: getGenderNumber(values.gender),
        age: remapedAge,
        city_size: remapedCitySize,
      })
        .then((res) => {
          if (res.success) {
            onSuccess && onSuccess();
            toast.success("Twój profil został zaktualizowany");
          } else {
            toast.error("Wystąpił błąd podczas zapisywania danych");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Wystąpił błąd podczas zapisywania danych");
        });
    },
    [settings.value?.onboarding, updateProfile, onSuccess]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: onboardingSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const mapUserToFormValues = useCallback(
    (
      user: API.UserAsProfile,
      onboardingSettings: Record<string, any> | undefined
    ) => {
      return {
        gender: getGenderEnum(user?.gender),
        age: onboardingSettings?.[Fields.AGE]?.indexOf(user?.age || "") + 1,
        education: user?.education || "",
        city_size:
          onboardingSettings?.[Fields.CITY_SIZE]?.indexOf(
            user?.city_size || ""
          ) + 1,
        sector: user?.sector || "",
        department: user?.department || "",
        position: user?.position || "",
      };
    },
    []
  );

  const genderOptions = useMemo(
    () =>
      (settings.value?.onboarding?.[Fields.GENDER] ?? []).map(
        (option: Option) => ({
          value: option.pl,
          label: option.pl,
        })
      ),
    [settings.value?.onboarding]
  );

  useEffect(() => {
    fetchFields({
      class_type: "EscolaLms\\Auth\\Models\\User",
    });
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isMetricPage &&
      user.value &&
      !user.loading &&
      setInitialValues(
        mapUserToFormValues(user.value, settings.value?.onboarding)
      );
  }, [user, settings.value?.onboarding, mapUserToFormValues, isMetricPage]);

  if (settings.loading || !settings.value?.onboarding || formik.isSubmitting) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <Select
          value={formik.values.gender}
          id={Fields.GENDER}
          name={Fields.GENDER}
          label="1. Płeć"
          onChange={formik.handleChange}
          options={genderOptions}
          error={getErrorMessage<InitialValues>(Fields.GENDER, formik)}
        />
      </InputWrapper>
      <Label>
        2. Wiek <span>(przedział)</span>
      </Label>
      <RangeInputWrapper>
        <StyledRangeInput
          maxValue={5}
          minValue={1}
          value={formik.values.age}
          step={1}
          formatLabel={(value) =>
            settings.value?.onboarding?.[Fields.AGE]?.[value - 1] ?? ""
          }
          onChange={(value) => formik.setFieldValue(Fields.AGE, value)}
        />
      </RangeInputWrapper>
      <Label>3. Wykształcenie</Label>
      <Fieldset>
        {settings.value?.onboarding?.[Fields.EDUCATION]?.map(
          (option: Option, index: number) => (
            <div key={index}>
              <RadioInput
                label={option.pl}
                name="education"
                id={`${option.pl}-${index}`}
                onChange={formik.handleChange}
                value={option.pl}
                checked={formik.values.education === option.pl}
              />
            </div>
          )
        )}
      </Fieldset>
      <ErrorMsg>
        {getErrorMessage<InitialValues>(Fields.EDUCATION, formik)}
      </ErrorMsg>
      <InputWrapper>
        <Select
          value={formik.values.sector}
          id={Fields.SECTOR}
          name={Fields.SECTOR}
          label="4. Sektor, w jakim pracujesz"
          onChange={formik.handleChange}
          options={[
            {
              value: "choose",
              label: "Wybierz",
              selected: formik.values.sector === "choose",
              disabled: true,
            },
            ...(settings.value?.onboarding?.[Fields.SECTOR] ?? []).map(
              (option: Option) => ({
                value: option.pl,
                label: option.pl,
              })
            ),
          ]}
          error={getErrorMessage<InitialValues>(Fields.SECTOR, formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Label>
          5. Jeśli pracujesz w urzędzie to:
          <Input
            type="text"
            placeholder="Podaj nazwę wydziału, w którym pracujesz"
            id={Fields.DEPARTMENT}
            name={Fields.DEPARTMENT}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.department}
            error={getErrorMessage<InitialValues>(Fields.DEPARTMENT, formik)}
          />
          <div style={{ height: 10 }} />
          <Input
            placeholder="Podaj stanowisko, na którym obecnie pracujesz"
            type="text"
            id={Fields.POSITION}
            name={Fields.POSITION}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.position}
            error={getErrorMessage<InitialValues>(Fields.POSITION, formik)}
          />
        </Label>
      </InputWrapper>
      <Label>
        6.Wielkość miasta <span>(liczba ludności)</span>
        <span className="new-line">
          *miasta w którym pracujesz/uczysz się/działasz
        </span>
      </Label>
      <RangeInputWrapper>
        <StyledRangeInput
          maxValue={3}
          minValue={1}
          value={Number(formik.values.city_size)}
          step={1}
          formatLabel={(value) =>
            settings.value?.onboarding?.[Fields.CITY_SIZE]?.[value - 1] ?? ""
          }
          onChange={(value) => formik.setFieldValue(Fields.CITY_SIZE, value)}
        />
      </RangeInputWrapper>
      <Button
        styleVariant={isMetricPage ? "secondaryGreen" : "primaryBlack"}
        type="submit"
        icon={isMetricPage ? undefined : <Icon name="arrowRight" />}
        iconGap={isMetricPage ? 0 : 134}
      >
        Zapisz {isMetricPage && "zmiany"}
      </Button>
    </FormWrapper>
  );
};

export default UserDetailsForm;
