import { useTranslation } from "react-i18next";
// TODO CHANGE VERSION OF REACT-PDF TO NEWEST
import { Document, Page, PageProps, pdfjs } from "react-pdf";
import styled from "styled-components";

import React, { useEffect, useState } from "react";

import Button from "components/common/Button";

interface PdfPlayerProps {
  url: string;
  documentConfig?: Omit<React.ComponentProps<typeof Document>, "file">;
  pageConfig?: PageProps;
  onLoad?: () => void;
  onTopicEnd?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .react-pdf__Page__svg {
    margin-inline: auto;
  }

  .pagination-area {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
`;

export const PdfPlayer: React.FunctionComponent<PdfPlayerProps> = ({
  url,
  onLoad,
  onTopicEnd,
  documentConfig = {},
  pageConfig = {},
}): React.ReactElement => {
  const [allPages, setAllPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [endActionFired, setEndActionFired] = useState(false);
  const { t } = useTranslation();

  const handleNextPageClick = () => {
    if (onTopicEnd && allPages && !(allPages > currentPage)) {
      setEndActionFired(true);
      onTopicEnd();
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (currentPage === allPages) {
      onLoad?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, currentPage]);

  if (!url) {
    return <p>Nie znaleziono pliku .pdf</p>;
  }

  return (
    <Wrapper className="wellms-component" data-testid="pdf-player">
      {isMounted && url && (
        <Document
          className="document"
          loading={t("Loading")}
          onLoadSuccess={({ numPages }) => setAllPages(numPages)}
          file={url}
          {...documentConfig}
        >
          <Page
            pageNumber={currentPage}
            renderAnnotationLayer={false}
            {...pageConfig}
          />
        </Document>
      )}

      {allPages && allPages > 1 && (
        <div className="pagination-area">
          <Button
            styleVariant="primaryBlack"
            disabled={!(currentPage > 1)}
            className="nav-btn-modal"
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Poprzednia strona
          </Button>
          <p>
            <strong>{currentPage}</strong> strona z <strong>{allPages}</strong>
          </p>
          <Button
            style={{ marginLeft: "10px" }}
            styleVariant="primaryBlack"
            disabled={onTopicEnd ? endActionFired : !(allPages > currentPage)}
            className="nav-btn-modal"
            onClick={handleNextPageClick}
          >
            Następna strona
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

export default PdfPlayer;
