import ReactPaginate from "react-paginate";
import styled, { css } from "styled-components";

export const basicStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  width: 27px;
`;

export const StyledPaginate = styled(ReactPaginate)`
  max-width: 400px;
  width: fit-content;
  padding: 0;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  color: ${({ theme }) => theme.colors.text};
  gap: 14px;
  cursor: pointer;

  .page-link-prev,
  .page-link-next {
    font-size: ${({ theme }) => theme.fontSizes.mdlarge};
    &:hover {
      opacity: 0.8;
    }
  }

  .page-link {
    ${basicStyles}
    &:hover {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.gray100};
    }
  }

  .page-item {
    ${basicStyles}
    &.disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.gray400};
    }
    &.active {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
