import { styled } from "styled-components";

import Button from "components/common/Button";

export const Main = styled.main`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 74px;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  line-height: 24px/28px;
  letter-spacing: -0.6px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Description = styled.p`
  line-height: 15px/24px;
  letter-spacing: -0.38px;

  margin: 0;
`;

export const StyledButton = styled(Button)`
  padding: 11px 28px 12px 29px;
`;
