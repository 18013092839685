import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import React, { useCallback, useContext, useEffect, useMemo } from "react";

import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Input from "components/common/Input";

import { convertSnakeCaseToCamelCase, getErrorMessage } from "utils";

import { signUpSchema } from "validation/registerFormValidation";

import ConsentField from "./ConsentField";
import { FormWrapper, InputWrapper } from "./styles";

type InitialValues = {
  email: string;
  password: string;
  repeatPassword: string;
  firstName: string;
  lastName: string;
  [key: string]: string | boolean;
};

const initialValues: InitialValues = {
  email: "",
  password: "",
  repeatPassword: "",
  firstName: "",
  lastName: "",
  privacyPolicy: false,
  termsOfUse: false,
};

const RegisterForm: React.FC = () => {
  const { register, fields, fetchFields } = useContext(EscolaLMSContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFields({
      class_type: "EscolaLms\\Auth\\Models\\User",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const booleanFields = useMemo(
    () =>
      fields?.list?.filter(
        (field) => field.type === "boolean" && field.name !== "newsletter"
      ),
    [fields]
  );

  const onSubmit = useCallback(
    (values: InitialValues) => {
      register({
        email: values.email.toLowerCase(),
        password: values.password,
        password_confirmation: values.repeatPassword,
        first_name: values.firstName,
        last_name: values.lastName,
        return_url: `${window.location.origin}/#${routeRoutes.login}`,
        privacy_policy: values.privacyPolicy,
        terms_of_use: values.termsOfUse,
      })
        .then((response) => {
          if (response.success) {
            toast.success(
              "Rejestracja udana. Sprawdź swoją skrzynkę pocztową, aby aktywować konto."
            );
            navigate(routeRoutes.login);
          }
        })
        .catch((error) => {
          toast.error("Wystąpił błąd podczas rejestracji");
          console.log(error);
        });
    },
    [navigate, register]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signUpSchema,
    onSubmit: onSubmit,
  });

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <Input
          type="text"
          id="email"
          name="email"
          placeholder="Wpisz adres email"
          label="Adres email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={getErrorMessage("email", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="text"
          id="firstName"
          name="firstName"
          placeholder="Wpisz imię"
          label="Imię"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={getErrorMessage("firstName", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="text"
          id="lastName"
          name="lastName"
          placeholder="Wpisz nazwisko"
          label="Nazwisko"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={getErrorMessage("lastName", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="password"
          id="password"
          name="password"
          placeholder="Wpisz hasło"
          label="Hasło"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={getErrorMessage("password", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="password"
          id="repeatPassword"
          name="repeatPassword"
          placeholder="Wpisz ponownie hasło"
          label="Powtórz hasło"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repeatPassword}
          error={getErrorMessage("repeatPassword", formik)}
        />
      </InputWrapper>
      {booleanFields?.map((field) => (
        <ConsentField
          key={field.id}
          field={field}
          checked={!!formik.values?.[convertSnakeCaseToCamelCase(field.name)]}
          onChange={formik.handleChange}
          error={getErrorMessage(
            convertSnakeCaseToCamelCase(field.name),
            formik
          )}
        />
      ))}
      <Button type="submit" icon={<Icon name="arrowRight" />} iconGap={80}>
        Zarejestruj się
      </Button>
    </FormWrapper>
  );
};

export default RegisterForm;
