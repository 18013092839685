import React from "react";

import CircleProgress from "components/CircleProgress";
import Button from "components/common/Button";
import Icon from "components/common/Icon";

import {
  BottomRow,
  ButtonWrapper,
  Container,
  Description,
  DetailsItem,
  Label,
  LevelTitle,
  Title,
} from "./styles";

type Result = {
  percentage: number;
  score: number;
  max_score: number;
};

type Props = {
  title: string;
  className?: string;
  description: string;
  progress: Result;
  level: string;
  onClick?: () => void;
};

const ScoreCard: React.FC<Props> = ({
  title,
  className,
  description,
  progress,
  level,
  onClick,
}) => {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <BottomRow>
        <DetailsItem>
          <Label>Twój wynik</Label>
          <CircleProgress size={66} progress={progress} />
        </DetailsItem>
        <DetailsItem>
          <Label>
            Osiągnięty <br /> poziom
          </Label>
          <LevelTitle>{level}</LevelTitle>
        </DetailsItem>
        <DetailsItem>
          <ButtonWrapper>
            <Button
              icon={<Icon name="arrowRightSmall" />}
              iconGap={28}
              size="small"
              onClick={onClick}
            >
              Rozpocznij kurs
            </Button>
          </ButtonWrapper>
        </DetailsItem>
      </BottomRow>
    </Container>
  );
};

export default ScoreCard;
