import { COURSE_SUPPLEMENT } from "const/links";
import { toast } from "react-toastify";

type downloadCertificteProps = {
  generateCertificate: (id: number, title?: string) => Promise<Blob>;
  id: number;
  title?: string;
};

export const downloadCertificate = async ({
  generateCertificate,
  id,
  title,
}: downloadCertificteProps) => {
  try {
    const response = await generateCertificate(id);
    if (response) {
      const element = document.createElement("a");
      document.body.appendChild(element);
      element.setAttribute("href", URL.createObjectURL(new Blob([response])));
      element.setAttribute("download", `${title || "Certificate"}.pdf`);
      element.style.display = "";
      element.click();
      document.body.removeChild(element);
    }
  } catch (error) {
    toast.error("Wystąpił nieoczekiwany błąd. Spróbuj ponownie");

    console.log(error);
  }
};

export const handleRedirectToCourseSupplement = () => {
  window.open(COURSE_SUPPLEMENT, "_blank");
};
