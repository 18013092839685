import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import { useContext, useMemo } from "react";

import { getFlatCategoriesSortedById } from "utils/categories";

export interface PickedChallengeData {
  course: API.CourseProgram;
  suggestedCourseId?: number;
  progress: API.CourseProgressItem;
  competencyCategory: API.Category;
  levelCategory: API.Category;
}

export interface PickedChallenge {
  challenge: API.CompetencyChallenge;
  challengeIdByRoleCategory?: number;
  roleCategory: API.Category;
  data: PickedChallengeData[];
}

const getNewestResult = (
  results: API.CompetencyTestResults[]
): API.CompetencyTestResults | undefined =>
  results.sort((a, b) => b.attempt_id - a.attempt_id)?.[0];

export function usePickedChallenges(): PickedChallenge[] {
  const { progress, categoryTree, program, challenges, challenge, course } =
    useContext(EscolaLMSContext);

  const flatCategories = useMemo(
    () => getFlatCategoriesSortedById(categoryTree.list ?? []),
    [categoryTree.list]
  );

  const data = useMemo(
    () =>
      (progress.value ?? []).reduce<PickedChallengeData[]>((acc, progress) => {
        const courseId = progress?.course?.id;
        const currentCourse = program.byId?.[courseId]?.value;

        const courseRelatedProducts =
          course.byId?.[courseId] &&
          course.byId?.[courseId].value?.product?.related_products;

        const suggestedCourseId =
          courseRelatedProducts && courseRelatedProducts.length > 0
            ? course.byId?.[courseId].value?.product?.related_products?.[0]
                .productables?.[0].id
            : undefined;

        const levelCategory = progress?.categories?.[0];
        const competencyCategory =
          flatCategories?.[levelCategory?.parent_id ?? -1];

        if (!currentCourse || !levelCategory || !competencyCategory) return acc;

        return [
          ...acc,
          {
            course: currentCourse,
            suggestedCourseId,
            progress,
            levelCategory,
            competencyCategory,
          },
        ];
      }, []),
    [progress.value, program.byId, course.byId, flatCategories]
  );

  const simpleChallengesWithTestDone = useMemo(
    () =>
      (challenges.list?.data ?? []).reduce<API.SimpleCompetencyChallenge[]>(
        (acc, ch) => {
          const challengeDetails = challenge.byId?.[ch.id]?.value;
          if (
            !challengeDetails ||
            challengeDetails.results_count < 1 ||
            challengeDetails.type !== API.CompetencyChallengeType.Simple
          )
            return acc;

          return [...acc, challengeDetails];
        },
        []
      ),
    [challenge.byId, challenges.list?.data]
  );

  const complexChallengesWithTestDone = useMemo(
    () =>
      (challenges.list?.data ?? []).reduce<API.ComplexCompetencyChallenge[]>(
        (acc, ch) => {
          const challengeDetails = challenge.byId?.[ch.id]?.value;
          if (
            !challengeDetails ||
            challengeDetails.results_count < 1 ||
            challengeDetails.type !== API.CompetencyChallengeType.Complex
          )
            return acc;

          return [...acc, challengeDetails];
        },
        []
      ),
    [challenges.list?.data, challenge.byId]
  );

  const simplePickedChallenges = useMemo(
    () =>
      simpleChallengesWithTestDone.reduce<PickedChallenge[]>(
        (acc, challenge) => {
          const newestResult = getNewestResult(challenge.results);
          if (!newestResult) return acc;

          const challengeData = data.filter(
            (item) =>
              item.competencyCategory.parent_id === challenge.category.id
          );

          const roleCategory = flatCategories?.[challenge?.category?.id];
          if (!roleCategory || challengeData.length === 0) return acc;

          return [
            ...acc,
            {
              challenge,
              challengeIdByRoleCategory: challenge.id,
              data: challengeData,
              roleCategory,
            },
          ];
        },
        []
      ),
    [data, flatCategories, simpleChallengesWithTestDone]
  );

  const complexPickedChallenges = useMemo(
    () =>
      complexChallengesWithTestDone.reduce<PickedChallenge[]>(
        (acc, challenge) => {
          const newestResult = getNewestResult(challenge.results);
          if (!newestResult) return acc;

          const resultsGroupedByCategoryParentId = Object.values(
            newestResult.value.reduce<Record<number, API.ResultValue>>(
              (resultValueAcc, resultValue) => ({
                ...resultValueAcc,
                [resultValue.parent_category_id]: {
                  ...(resultValueAcc?.[resultValue.parent_category_id] ?? {}),
                  ...resultValue,
                  matched_course: [
                    ...(resultValueAcc?.[resultValue.parent_category_id]
                      ?.matched_course ?? []),
                    ...resultValue.matched_course,
                  ],
                },
              }),
              {}
            )
          );

          const pickedChallenges = resultsGroupedByCategoryParentId.reduce<
            PickedChallenge[]
          >((resultValueAcc, resultValue) => {
            const matchedData = data.filter(
              (item) =>
                item.competencyCategory.parent_id ===
                resultValue.parent_category_id
            );
            const roleCategory =
              flatCategories?.[resultValue.parent_category_id];

            if (matchedData.length === 0 || !roleCategory)
              return resultValueAcc;

            const challengeIdByRoleCategory = simpleChallengesWithTestDone.find(
              (ch) => ch?.category?.id === roleCategory.id
            )?.id;

            return [
              ...resultValueAcc,
              {
                challenge,
                challengeIdByRoleCategory,
                roleCategory,
                data: matchedData,
              },
            ];
          }, []);

          return [...acc, ...pickedChallenges];
        },
        []
      ),
    [
      complexChallengesWithTestDone,
      data,
      flatCategories,
      simpleChallengesWithTestDone,
    ]
  );
  return useMemo(
    () => [...simplePickedChallenges, ...complexPickedChallenges],
    [complexPickedChallenges, simplePickedChallenges]
  );
}
