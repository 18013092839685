import styled from "styled-components";

interface CommonProps {
  $isChecked?: boolean;
  $isDisabled?: boolean;
}

export const SwitchContainer = styled.label<CommonProps>`
  display: flex;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  align-items: center;
`;

export const Label = styled.label<CommonProps>`
  display: inline-flex;
  position: relative;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};

  &::after {
    content: "";
    position: absolute;
    left: ${({ $isChecked }) => ($isChecked ? "18px" : "2px")};
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background: ${({ theme: { colors }, $isChecked }) =>
      $isChecked ? colors.primary : colors.gray400};
    border-radius: 50%;
    transition: 0.3s;
  }
`;

export const Input = styled.input`
  appearance: none;
  min-width: 30px;
  height: 14px;
  background: ${({ theme }) => theme.colors.gray200};
  border-radius: 20px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const Span = styled.span`
  margin-left: ${({ theme }) => theme.spacing};
  user-select: none;
`;
