import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";
import { ellipsisText } from "theme/mixins";

import { Stack } from "components/common/Stack";

export const textBasicStyles = css`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
  opacity: 0.5;
`;

export const Card = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  padding: 10px 18px;
  width: 100%;

  & > button {
    display: flex;
    align-self: center;
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

export const StyledTitle = styled.h4`
  line-height: 16px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  ${ellipsisText}
`;

export const StyledText = styled.p`
  line-height: 16px;
  margin: 0px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
  ${ellipsisText}
  & > span {
    ${textBasicStyles}
  }
`;

export const StyledSubtitle = styled.h5`
  ${textBasicStyles}
`;

export const StyledStack = styled(Stack)`
  @media ${device.mobile} {
    width: 100%;
    margin-top: 37px;
    margin-bottom: 11px;
    & button {
      border-radius: 21px;
    }
  }
`;
