import InputRange, { InputRangeProps } from "react-input-range";
import "react-input-range/lib/css/index.css";

import React from "react";

import { Container, Mark, Marks } from "./styles";

const StyledRangeInput: React.FC<InputRangeProps> = (props) => {
  return (
    <Container>
      <InputRange {...props} />

      <Marks>
        {Array.from(Array(props.maxValue).keys()).map((_, index) => (
          <Mark key={index} />
        ))}
      </Marks>
    </Container>
  );
};

export default StyledRangeInput;
