import { css, styled } from "styled-components";
import { device } from "theme/breakpoints";

import { pixelsToRem } from "utils";

const fontSize18 = pixelsToRem(18);

export const basicTitle = () => css`
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }
`;

export const WelcomeText = styled.div`
  font-size: ${fontSize18};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  span {
    font-size: ${fontSize18};
    padding-left: 5px;
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }
`;

export const ChallengeSection = styled.section`
  margin: 55px 0 112px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.tablet} {
    margin: 55px 0 50px;
  }
`;

export const SectionTitle = styled.h2`
  margin: 0;
  ${basicTitle}
`;

export const StyledTitle = styled.h2`
  margin-bottom: 22px;
  ${basicTitle}
`;

export const SliderSection = styled.section`
  margin-top: 112px;

  @media ${device.tablet} {
    margin-top: 50px;
  }
`;

export const SectionDesc = styled.p`
  margin: 0 0 35px 0;
  max-width: 530px;
  line-height: 24px;
`;

export const CompletedCoursesWrapper = styled.div`
  margin-top: 86px;
`;

export const SliderContainer = styled.div`
  margin-bottom: 55px;
  position: relative;
  z-index: 3;
  margin-top: 40px;
  width: 115%;

  @media ${device.laptop} {
    width: 100%;
  }

  .swiper {
    z-index: 3;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 250px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.9290091036414566) 47%,
        rgba(255, 255, 255, 1) 100%
      );
      top: 0;
      right: 0;
      z-index: 3;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.text};
    z-index: 4;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
    right: 135px;

    &::after {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      border-right: 2px solid ${({ theme }) => theme.colors.white};
      border-top: 2px solid ${({ theme }) => theme.colors.white};
      left: 50%;
      top: 50%;
      transform: translate(-60%, -50%) rotate(45deg);
    }
  }

  .swiper-button-prev {
    &:before {
      transform: translate(-40%, -50%) rotate(-135deg);
    }
  }
`;
