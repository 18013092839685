import { API } from "@escolalms/sdk/lib";
import { CompetencyTestResult } from "types/course";

import React, { useCallback, useMemo, useState } from "react";

import Sidebar from "components/App/Sidebar";
import CompetencyDetailsCard from "components/CompetencyDetailsCard";

import useCompetencyTestResults from "hooks/useCompetencyTestResults";

import ResultCard from "./ResultCard";
import { ScoresContainer } from "./styles";

type Props = {
  competencyChallenge: API.CompetencyChallenge;
  scoresOpened: boolean;
  closeScores: () => void;
};

const TestScoreSidebar: React.FC<Props> = ({
  competencyChallenge,
  scoresOpened,
  closeScores,
}) => {
  const [competence, setCompetence] = useState<CompetencyTestResult | null>(
    null
  );

  const resultWithHighestAttemptId: API.CompetencyTestResults | undefined =
    useMemo(
      () =>
        (competencyChallenge?.results ?? []).sort(
          (prev, current) => current.attempt_id - prev.attempt_id
        )?.[0],
      [competencyChallenge?.results]
    );

  const { challengeTestResults } = useCompetencyTestResults(
    resultWithHighestAttemptId
  );

  const handleDetailsBackClick = useCallback(() => setCompetence(null), []);

  const handleDetailsClose = useCallback(() => {
    closeScores();
    handleDetailsBackClick();
  }, [closeScores, handleDetailsBackClick]);

  if (!scoresOpened) return null;

  return (
    <>
      {competence?.category ? (
        <Sidebar
          title={competence.category.name}
          onClose={handleDetailsClose}
          onClickBackBtn={handleDetailsBackClick}
        >
          <CompetencyDetailsCard
            competence={competence}
            onClose={handleDetailsClose}
          />
        </Sidebar>
      ) : (
        <Sidebar title="Twoje wyniki" onClose={closeScores}>
          <ScoresContainer>
            {challengeTestResults.map((challengeTestResult, i) => (
              <ResultCard
                key={i}
                challengeType={competencyChallenge.type}
                result={challengeTestResult}
                onDevelopCompetencyClick={setCompetence}
              />
            ))}
          </ScoresContainer>
        </Sidebar>
      )}
    </>
  );
};

export default TestScoreSidebar;
