const routeRoutes = {
  home: "/",
  login: "/logowanie",
  register: "/rejestracja",
  resetPassword: "/resetowanie-hasla",
  onboarding: "/onboarding",
  userPanel: {
    certificates: "/moje-certyfikaty",
    metrics: "/metryka",
    accountDetails: "/moje-dane",
    deleteAccount: "/usun-konto",
    newsletter: "/newsletter",
  },
  challengeDetails: "/wyzwanie/:id",
  deleteAccountConfirmation: "/usun-konto-potwierdzenie",
  course: "/kurs/:courseId/:topicId?",
  competencyTest: "/test-kompetencji/:challengeId/:quizId/:questionId?",
  terms: "/regulamin",
  privacyPolicy: "/polityka-prywatnosci",
  page: "/:slug",
  paymentReturn: "/platnosc-pomyslna",
};

export default routeRoutes;
