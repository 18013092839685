import { styled } from "styled-components";

import Icon from "components/common/Icon";

export const Title = styled.h2`
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
`;

export const PrimaryColorIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;
