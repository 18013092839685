import { styled } from "styled-components";

export const FormWrapper = styled.form`
  max-width: 410px;
  display: flex;
  flex-direction: column;
  gap: 74px;

  button {
    max-width: 233px;
  }
`;
