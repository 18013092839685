import { styled } from "styled-components";

export const LoginInfo = styled.p`
  a {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.colors.text};
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      transform: rotate(-45deg);
    }
  }
`;
