import { useEffect, useRef, useState } from "react";

export function useComponentHeight(
  initialState: number,
  recalculate?: boolean
) {
  const [height, setHeight] = useState(initialState);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.offsetHeight === undefined) return;
    setHeight(ref.current?.offsetHeight);
  }, [recalculate]);

  return [ref, height] as const;
}
