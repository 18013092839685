import Modal from "react-modal";

import React from "react";

import Icon, { IconName } from "components/common/Icon";

import { CloseButton, ModalBody } from "./styles";

export type IconPosition = { top?: string; right?: string };

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  customStyles?: {};
  customCloseIcon?: IconName;
  iconPosition?: IconPosition;
};

const styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  },
  content: {
    margin: "0 auto",
    padding: "30px 33px",
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    height: "fit-content",
    width: "fit-content",
  },
};

const CustomModal: React.FC<Props> = ({
  customStyles,
  customCloseIcon,
  iconPosition,
  children,
  onClose,
  isOpen,
}) => {
  return (
    <Modal style={customStyles || styles} isOpen={isOpen} ariaHideApp={false}>
      <ModalBody>
        <CloseButton onClick={onClose} iconPosition={iconPosition}>
          <Icon name={customCloseIcon || "close"} />
        </CloseButton>
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;
