import styled, { RuleSet, css } from "styled-components";
import { LessonListTopicState } from "types/course";

const STATE_STYLES: Record<LessonListTopicState, RuleSet> = {
  available: css`
    opacity: 0.45;
  `,
  finished: css`
    opacity: 0.45;
  `,
  current: css`
    opacity: 1;
  `,
  locked: css`
    opacity: 0.45;
  `,
};

export const ListItem = styled.li<{ $state: LessonListTopicState }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 7px 10px 7px 14px;
  border: ${({ theme }) => `1px solid ${theme.colors.text}`};
  border-radius: 5px;

  user-select: none;
  transition: opacity 200ms ease-in-out;

  ${({ $state }) => STATE_STYLES[$state]};

  &:not([aria-disabled="true"]) {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;

  width: 80%;
`;

const titleMixin = css`
  line-height: 16px;
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  margin: 0;
`;

export const CardTitle = styled.p`
  ${titleMixin};
`;

export const TopicTitle = styled.p`
  ${titleMixin};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
