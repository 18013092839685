import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const Result = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  padding: 20px 30px;

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.gray100};
  }

  @media ${device.tablet} {
    padding: 20px 0;
  }
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.smlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0 0 9px 0;
`;

export const Desc = styled.p`
  margin: 0 0 30px 0;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  button {
    margin-left: 95px;

    @media ${device.mobile} {
      margin-left: 0;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChallengeTitle = styled(Row)`
  gap: 9px;
  & > h2 {
    width: 100%;
    padding: 12px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  }
  & svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
