import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import {
  addProject,
  fetchProjects,
  removeProject,
} from "@escolalms/sdk/lib/services/project";
import { DATE_FORMAT } from "const/dates";
import { format } from "date-fns";

import React, { useCallback, useContext, useEffect, useState } from "react";

import Loader from "components/Loader";
import MarkdownRenderer from "components/MarkdownRenderer";
import Icon from "components/common/Icon";
import Upload from "components/common/Upload";

import { Card, Wrapper } from "./styles";

interface ProjectsData {
  data: API.ProjectFile[];
  loading: boolean;
}

interface ProjectsListProps {
  projects: ProjectsData;
  onDeleteSuccess?: () => void;
  onDeleteError?: () => void;
  className?: string;
}

export interface ProjectPlayerProps {
  course_id: number;
  topic: API.TopicProject;
  onSuccess?: () => void;
  onError?: () => void;
  className?: string;
}

const ProjectsList: React.FC<ProjectsListProps> = ({
  projects,
  onDeleteSuccess,
  onDeleteError,
  className,
}) => {
  const { token, apiUrl } = useContext(EscolaLMSContext);
  const [isDeleting, setIsDeleting] = useState<number[]>([]);

  const onFileDeleteFactory = useCallback(
    (fileId: number) => () => {
      if (!token) return console.warn("noToken");
      setIsDeleting((prev) => [...prev, fileId]);
      removeProject(apiUrl, token, fileId)
        .then(() => onDeleteSuccess?.())
        .catch(() => onDeleteError?.())
        .finally(() =>
          setIsDeleting((prev) => prev.filter((id) => id !== fileId))
        );
    },
    [token, apiUrl]
  );

  return (
    <div className={className}>
      {projects.loading && !projects.data.length && <Loader />}
      {projects.data.map(({ id, created_at }, i) => (
        <Card className="project-card" key={id}>
          <div className="project-card__details">
            <p className="project-card__title">
              <Icon name="file" />
              Plik
              {` ${i + 1}`}
            </p>
            <p className="project-card__date">
              {format(new Date(created_at), DATE_FORMAT)}
            </p>
          </div>
          <button
            className="project-card__delete-btn"
            disabled={isDeleting.includes(id)}
            onClick={onFileDeleteFactory(id)}
          >
            <Icon name="deleteColor" />
          </button>
        </Card>
      ))}
    </div>
  );
};

const ProjectPlayer: React.FC<ProjectPlayerProps> = ({
  course_id,
  topic,
  onSuccess,
  onError,
  className,
}) => {
  const { token, apiUrl } = useContext(EscolaLMSContext);
  const [projects, setProjects] = useState<ProjectsData>({
    data: [],
    loading: false,
  });

  const [isUploading, setIsUploading] = useState(false);

  const refreshProjects = useCallback(() => {
    if (!token) return console.error("noToken");

    setProjects((prev) => ({ ...prev, loading: true }));
    fetchProjects(apiUrl, token, { course_id, topic_id: topic?.id })
      .then((res) => {
        if (res.success) {
          setProjects((prev) => ({
            ...prev,
            data: res.data,
          }));
        }
      })
      .catch((err) => console.warn(err))
      .finally(() => setProjects((prev) => ({ ...prev, loading: false })));
  }, [token, apiUrl, course_id, topic?.id]);

  useEffect(() => {
    refreshProjects();
  }, [refreshProjects]);

  const onProjectFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.currentTarget.files?.[0] || !token) return;

      setIsUploading(true);
      const body: API.AddProjectBody = {
        topic_id: topic?.id + "",
        file: e.currentTarget.files[0],
      };

      addProject(apiUrl, token, body)
        .then(() => {
          refreshProjects();
          onSuccess?.();
        })
        .catch(() => {
          onError?.();
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    // eslint-disable-next-line
    [apiUrl, token, topic?.id, refreshProjects]
  );

  return (
    <Wrapper className={className} data-testid="project-player">
      {topic.description && (
        <MarkdownRenderer className="project-player__description">
          {topic.description}
        </MarkdownRenderer>
      )}
      <Upload
        className="project-player__upload-input"
        name="project_file"
        onChange={onProjectFileSelect}
        disabled={isUploading}
      />
      <ProjectsList
        className="project-player__projects-list"
        projects={projects}
        onDeleteSuccess={refreshProjects}
      />
    </Wrapper>
  );
};

export default ProjectPlayer;
