import { CompetencyTestResult } from "types/course";

import CircleProgress from "components/CircleProgress";

import { getCompetenceResultLevel } from "utils/course";

import { Label, LevelTitle, StyledRow } from "./styles";

type Props = {
  competence: CompetencyTestResult;
  hideBorder?: boolean;
};

const TestResultInfo: React.FC<Props> = ({ competence, hideBorder }) => {
  const actualCompetenceLevel = getCompetenceResultLevel(competence);
  const { percentage, score, max_score } = competence;
  return (
    <StyledRow $hideBorder={hideBorder}>
      <CircleProgress size={66} progress={{ percentage, score, max_score }} />
      <div>
        <Label>Osiągnięty poziom</Label>
        <LevelTitle>{actualCompetenceLevel?.name}</LevelTitle>
      </div>
    </StyledRow>
  );
};

export default TestResultInfo;
