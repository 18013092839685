import { styled } from "styled-components";

export const RadioInputContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RadioInputBox = styled.input<
  React.InputHTMLAttributes<HTMLInputElement>
>`
  appearance: none;
  width: 16px;
  position: relative;
  height: 16px;
  border: 2px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.text};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:checked {
    border-color: ${({ theme }) => theme.colors.text};
    &:after {
      opacity: 1;
    }
  }
`;

export const RadioInputLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
