import { styled } from "styled-components";

export const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const AvatarWrapper = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Name = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-bottom: -5px;
`;

export const Position = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
`;
