import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import React, { useCallback, useContext } from "react";

import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import Input from "components/common/Input";

import { getErrorMessage } from "utils";

import { signInSchema } from "validation/loginFormValidation";

import { FormWrapper, InputWrapper, ResetPasswordLink } from "./styles";

type InitialValues = {
  email: string;
  password: string;
};

type Props = {
  onForgotPasswordClick: () => void;
};

const initialValues: InitialValues = {
  email: "",
  password: "",
};

const LoginForm: React.FC<Props> = ({ onForgotPasswordClick }) => {
  const navigate = useNavigate();
  const { login } = useContext(EscolaLMSContext);

  const onSubmit = useCallback(
    (values: InitialValues) => {
      login({
        email: values.email.toLowerCase(),
        password: values.password,
      })
        .then((response) => {
          if (response.success) {
            navigate(routeRoutes.home);
          } else {
            toast.error("Wystąpił błąd podczas logowania");
          }
        })
        .catch((error) => {
          toast.error("Wystąpił błąd podczas logowania");
          console.log(error);
        });
    },
    [login, navigate]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: signInSchema,
    onSubmit: onSubmit,
  });

  return (
    <FormWrapper onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <Input
          type="text"
          id="email"
          name="email"
          placeholder="Wpisz adres email"
          label="Adres email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={getErrorMessage<InitialValues>("email", formik)}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          type="password"
          id="password"
          name="password"
          placeholder="Wpisz hasło"
          label="Hasło"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={getErrorMessage<InitialValues>("password", formik)}
        />
      </InputWrapper>
      <ResetPasswordLink>
        <button type="button" onClick={onForgotPasswordClick}>
          Nie pamiętasz?
        </button>
      </ResetPasswordLink>
      <Button type="submit" icon={<Icon name="arrowRight" />} iconGap={105}>
        Zaloguj się
      </Button>
    </FormWrapper>
  );
};

export default LoginForm;
