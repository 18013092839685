import { styled } from "styled-components";

export const StyledRow = styled.div<{
  $hideBorder?: boolean;
}>`
  margin: 6px 0;
  padding: ${({ $hideBorder }) => ($hideBorder ? "0px" : "10px")};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  border-top: 2px dotted
    ${({ $hideBorder, theme }) =>
      $hideBorder ? "transparent" : `${theme.colors.gray200}`};
  border-bottom: ${({ $hideBorder, theme }) =>
    $hideBorder ? "transparent" : `${theme.colors.gray200}`};
`;

export const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 5px;
  line-height: 1.15;
`;

export const LevelTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
