import { css, styled } from "styled-components";

import InfoText from "components/common/InfoText";
import { Row } from "components/common/Row";
import { Stack } from "components/common/Stack";

const containerStyles = css`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & > button {
    align-self: flex-start;
  }
`;

export const StyledRow = styled(Row)`
  ${containerStyles}
`;

export const StyledStack = styled(Stack)`
  ${containerStyles}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 410px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  input {
    margin-bottom: 12px;
  }
`;

export const InitialValueWrapper = styled.div`
  & > h4 {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    padding-left: 6px;
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-bottom: 16px;
  }
  & > p {
    margin: 0;
    padding-left: 6px;
    color: ${({ theme }) => theme.colors.textAccent};
  }
`;

export const ResetPasswordLink = styled.span`
  margin-bottom: 26px;
  display: block;
  & > a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSizes.small};
    transition: color 0.2s ease-in-out;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const StyledInfoText = styled(InfoText)`
  margin-left: 6px;
  margin-top: 16px;

  & > picture {
    display: flex;
    align-self: center;
  }
`;
