import { styled } from "styled-components";

import { Row } from "../Row";

export const Wrapper = styled(Row)`
  align-items: flex-start;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: 5px;
  padding: 6px;
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 20px;
  margin: 0;
`;
