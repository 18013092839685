import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

import React, { useContext, useEffect } from "react";

import LayoutContainer from "components/App/Container";
import Layout from "components/Layout";
import Loader from "components/Loader";
import MarkdownRenderer from "components/MarkdownRenderer";
import routeRoutes from "components/Routes/routes";
import ErrorBox from "components/common/ErrorBox";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StaticPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { fetchPage, page } = useContext(EscolaLMSContext);

  useEffect(() => {
    if (slug && !page.loading) {
      fetchPage(String(slug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (page.loading) {
    return (
      <Layout>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </Layout>
    );
  }

  if (!page.loading && page.error) {
    return (
      <Layout>
        <LayoutContainer>
          <ErrorBox
            title="Wystąpił błąd"
            message="Treść do której próbujesz uzyskać dostęp, została usunięta lub nie istnieje."
            callToAction={{
              to: routeRoutes.home,
              text: "Wróć na stronę główną",
            }}
          />
        </LayoutContainer>
      </Layout>
    );
  }

  return (
    <Layout>
      <LayoutContainer>
        {page.value?.content && (
          <MarkdownRenderer>{page.value?.content}</MarkdownRenderer>
        )}
      </LayoutContainer>
    </Layout>
  );
};

export default StaticPage;
