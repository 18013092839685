import { styled } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 190px;
  min-width: 185px;
  padding-top: 25px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 10px 15px #00000012;
`;

export const MenuItem = styled.button`
  border: none;
  appearance: none;
  text-align: left;
  padding: 15px 0;
  background-color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.medium};

  cursor: pointer;

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray50};
  }

  &:last-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.border};
    color: #959595;
    padding: 15px 23px;
    border-radius: 0 0 8px 8px;
  }

  a {
    padding: 0 23px;
    width: 100%;
    display: block;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
  }
`;
