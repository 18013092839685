import React from "react";

import { CheckboxInput, CheckboxLabel, CheckboxWrapper, Error } from "./styles";

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: React.ReactNode;
  error?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  error,
  ...props
}) => {
  return (
    <CheckboxWrapper>
      <CheckboxLabel htmlFor={props.name} $checked={checked}>
        <CheckboxInput
          type="checkbox"
          id={props.id}
          name={props.name}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <span>{label}</span>
      </CheckboxLabel>
      {error && <Error>{error}</Error>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
