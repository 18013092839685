import { styled } from "styled-components";

import { IconPosition } from ".";

export const ModalBody = styled.div`
  position: relative;
`;

export const CloseButton = styled.button<{ iconPosition?: IconPosition }>`
  position: absolute;
  top: ${({ iconPosition }) => iconPosition?.top || "-22px"};
  right: ${({ iconPosition }) => iconPosition?.right || "-26px"};
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.gray300};
  }
`;
