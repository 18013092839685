import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import React, { useContext, useState } from "react";

import UserPanelLayout from "components/App/UserPanelLayout";
import Button from "components/common/Button";

import { getGenderEnum } from "utils";

import UserDetailsForm from "pages/Onboarding/UserDetailsForm";

import {
  DetailsContainer,
  DetailsText,
  Item,
  Label,
  LabelSuffix,
  NewLineLabelSuffix,
  SubLabel,
} from "./styles";

type DetailsItemProps = {
  label: string;
  labelSuffix?: string;
  newLineLabelSuffix?: string;
  content: {
    text: string;
    label?: string;
  }[];
};

const MetricsPage: React.FC = () => {
  const [view, setView] = useState<"form" | "details">("details");
  const { user } = useContext(EscolaLMSContext);

  return (
    <UserPanelLayout
      title="Metryka"
      button={
        <Button
          onClick={() => {
            view === "details" ? setView("form") : setView("details");
          }}
          styleVariant="linkGreen"
        >
          {view === "details" ? "Edytuj" : "Anuluj"}
        </Button>
      }
    >
      {view === "details" ? (
        <DetailsContainer>
          <DetailsItem
            label="1. Płeć"
            content={[
              {
                text: getGenderEnum(user?.value?.gender),
              },
            ]}
          />
          <DetailsItem
            label="2. Wiek"
            labelSuffix="(przedział)"
            content={[
              {
                text: `${user?.value?.age} lat`,
              },
            ]}
          />
          <DetailsItem
            label="3. Wykształcenie"
            content={[
              {
                text: user?.value?.education,
              },
            ]}
          />
          <DetailsItem
            label="4. Sektor, w jakim pracujesz"
            content={[
              {
                text: user?.value?.sector,
              },
            ]}
          />
          <DetailsItem
            label="5. Jeśli pracujesz w urzędzie to:"
            content={[
              {
                label: "Nazwa wydziału, w ktorym pracujesz",
                text: `${user?.value?.department || "-"}`,
              },
              {
                label: "Stanowisko, na którym obecnie praujesz",
                text: `${user?.value?.position || "-"}`,
              },
            ]}
          />
          <DetailsItem
            label="6. Wielkość miasta"
            labelSuffix="(liczba ludności)"
            newLineLabelSuffix="*miasta w którym pracujesz/uczysz się/działasz"
            content={[
              {
                text: user?.value?.city_size,
              },
            ]}
          />
        </DetailsContainer>
      ) : (
        <div>
          <UserDetailsForm onSuccess={() => setView("details")} />
        </div>
      )}
    </UserPanelLayout>
  );
};

export default MetricsPage;

const DetailsItem: React.FC<DetailsItemProps> = ({
  label,
  content,
  labelSuffix,
  newLineLabelSuffix,
}) => {
  return (
    <Item>
      <Label>
        {label} <LabelSuffix>{labelSuffix}</LabelSuffix>
        {newLineLabelSuffix && (
          <NewLineLabelSuffix>{newLineLabelSuffix}</NewLineLabelSuffix>
        )}
      </Label>
      {content.map((item, index) => (
        <div
          key={index}
          style={{
            marginBottom: index !== content.length - 1 ? 15 : 0,
          }}
        >
          {item.label && <SubLabel>{item.label}</SubLabel>}
          <DetailsText>{item.text}</DetailsText>
        </div>
      ))}
    </Item>
  );
};
