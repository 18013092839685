import { styled } from "styled-components";

import { Row } from "components/common/Row";

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
  flex-wrap: wrap;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 18px;
  max-width: 416px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const Subheader = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;
