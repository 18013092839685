import { styled } from "styled-components";

export const AccordionWrapper = styled.div`
  border: 1px solid #e8e8e8;
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 19px;
  cursor: pointer;
`;

export const AccordionTitle = styled.h4`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const AccordionIcon = styled.div<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
  transition: transform 0.2s ease-in-out;
`;

export const AccordionContent = styled.div`
  padding: 0 22px 10px 22px;
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
