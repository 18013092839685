import React from "react";

import routes from "components/Routes/routes";
import Icon from "components/common/Icon";

import { useCourse } from "../Context";
import { Header, StyledLink, Title, TitleContainer, WhiteIcon } from "./styles";

const CourseHeader = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { courseCategories } = useCourse();

  return (
    <Header as="header" ref={ref}>
      <TitleContainer>
        <WhiteIcon name="logoSignature" />
        <Title>{courseCategories?.roleCategory?.name}</Title>
      </TitleContainer>
      <StyledLink to={routes.home}>
        <Icon name="close" />
      </StyledLink>
    </Header>
  );
});

export default CourseHeader;
