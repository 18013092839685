import { API } from "@escolalms/sdk/lib";

import React from "react";

import { getUniqueId } from "utils";

import { StyledDefaultLayout } from "../../styles";

interface Props extends API.QuizQuestion_NumericalQuestion {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  value: string;
  hasQuizEnded?: boolean;
  resultScore?: number | null;
}

const NumericalQuestion: React.FC<Props> = ({
  id,
  question,
  title,
  onBlur,
  onChange,
  value,
  hasQuizEnded,
  resultScore,
}) => (
  <StyledDefaultLayout
    data-testid={`numerical-question-${question}`}
    title={title}
    question={question}
    resultScore={resultScore}
    showScore={hasQuizEnded}
  >
    <input
      placeholder="Napisz odpowiedź"
      type="number"
      name={`${id}`}
      id={getUniqueId(`Numerical-${id}`)}
      disabled={hasQuizEnded}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  </StyledDefaultLayout>
);

export default NumericalQuestion;
