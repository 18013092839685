import * as Yup from "yup";

import {
  fieldIsRequired,
  notCurrentPasswordValidation,
  repeatPasswordValidation,
} from "./common";

export const changePasswordSchema = Yup.object().shape({
  current_password: fieldIsRequired,
  password: notCurrentPasswordValidation,
  new_confirm_password: repeatPasswordValidation,
});

export const changeUserNameSchema = Yup.object().shape({
  first_name: fieldIsRequired,
  last_name: fieldIsRequired,
});
