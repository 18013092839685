import { styled } from "styled-components";

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 410px;
  margin-top: 52px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 23px;

  &:last-of-type {
    margin-bottom: 60px;
  }
`;
