import { styled } from "styled-components";

export const RegisterInfo = styled.p`
  a {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.colors.text};
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      transform: rotate(-45deg);
    }
  }
`;

export const EmailToast = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
`;

export const ModalTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
`;

export const ModalDesc = styled.div`
  margin: 15px 0 17px;
`;

export const ModalContent = styled.div`
  max-width: 600px;
`;
