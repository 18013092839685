import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  flex-grow: 1;

  width: 100%;
  max-width: 600px;
  margin-inline: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1``;

export const Text = styled.p``;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
