import React from "react";

import type { IconName } from "../Icon";
import { Label, PrimaryColorIcon, Text } from "./styles";

interface Props
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "hidden" | "id" | "type"
  > {
  name: string;
  iconName?: IconName;
}

const Upload: React.FC<Props> = ({
  name,
  iconName = "upload",
  className,
  ...props
}) => (
  <Label
    data-testid={`file-upload-${name}`}
    className={className}
    htmlFor={name}
  >
    <PrimaryColorIcon name={iconName} />
    <Text>Naciśnij, aby wgrać plik</Text>
    <input hidden type="file" name={name} id={name} {...props} />
  </Label>
);

export default Upload;
