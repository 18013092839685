import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { isAfter } from "date-fns";
import { useParams } from "react-router-dom";
import type { CourseParams } from "types/course";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  getFlatLessons,
  getFlatTopics,
  getLessonParentsIds,
  getPrevNextTopic,
} from "utils/course";

import { getFlatCategoriesSortedById } from "../../../utils/categories";

type CourseCategories = Record<
  "levelCategory" | "competencyCategory" | "roleCategory",
  API.Category
>;

interface CourseContext {
  flatTopics?: API.Topic[];
  prevTopic?: API.Topic | null;
  currentTopic?: API.Topic;
  nextTopic?: API.Topic | null;
  currentLesson?: API.Lesson;
  currentCourseProgram?: API.CourseProgram;
  isNextTopicButtonDisabled?: boolean;
  setIsNextTopicButtonDisabled?: (b: boolean) => void;
  completeCurrentTopic?: () => void;
  finishedTopicsIds?: number[];
  availableTopicsIds?: number[];
  currentLessonParentsIds?: number[];
  isCourseFinished?: boolean;
  courseCategories?: CourseCategories;

  isAnyDataLoading?: boolean;
}

const Context = React.createContext<CourseContext>({});

export const useCourse = () => useContext(Context);

const DISABLE_NEXT_BUTTON_TYPES = [
  API.TopicType.H5P,
  API.TopicType.Audio,
  API.TopicType.Video,
  API.TopicType.Project,
  API.TopicType.GiftQuiz,
];

const CourseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    sendProgress,
    program,
    fetchProgram,
    progress,
    fetchProgress,
    categoryTree,
    fetchCategories,
  } = useContext(EscolaLMSContext);
  const [isNextTopicButtonDisabled, setIsNextTopicButtonDisabled] =
    useState(false);

  const { courseId, topicId } = useParams<CourseParams>();

  const currentCourseProgram = useMemo(() => program.value, [program.value]);

  // For development purposes only
  if (process.env.NODE_ENV === "development") {
    // @ts-ignore
    window.resetProgress = () =>
      sendProgress(
        currentCourseProgram?.id!,
        (flatTopics ?? []).map((topic) => ({
          topic_id: Number(topic?.id),
          status: API.CourseProgressItemElementStatus.INCOMPLETE,
        }))
      );
  }

  const flatLessons = useMemo(
    () => getFlatLessons(currentCourseProgram?.lessons ?? []),
    [currentCourseProgram?.lessons]
  );

  const flatTopics = useMemo(
    () => getFlatTopics(currentCourseProgram?.lessons ?? []),
    [currentCourseProgram?.lessons]
  );

  const findLessonById = useCallback(
    (id: number) => flatLessons.find((l) => l.id === id),
    [flatLessons]
  );

  const findTopicById = useCallback(
    (id: number) => flatTopics.find((t) => t.id === id),
    [flatTopics]
  );

  const prevTopic = useMemo(
    () => getPrevNextTopic(Number(topicId), flatTopics),
    [flatTopics, topicId]
  );

  const currentTopic = useMemo(
    () => findTopicById(Number(topicId)),
    [findTopicById, topicId]
  );

  const nextTopic = useMemo(
    () => getPrevNextTopic(Number(topicId), flatTopics, "next"),
    [flatTopics, topicId]
  );

  const currentLesson = useMemo(
    () => currentTopic && findLessonById(currentTopic?.lesson_id),
    [findLessonById, currentTopic]
  );

  const currentCourseProgress = useMemo(
    () =>
      (progress.value ?? []).find(
        ({ course }) => course.id === Number(courseId)
      ),
    [progress, courseId]
  );

  const completeCurrentTopic = useCallback(() => {
    if (currentCourseProgram?.id === undefined) return;
    if (currentTopic?.id === undefined) return;

    setIsNextTopicButtonDisabled(false);
    sendProgress(currentCourseProgram.id, [
      {
        topic_id: currentTopic.id,
        status: API.CourseProgressItemElementStatus.COMPLETE,
      },
    ]);
  }, [sendProgress, currentCourseProgram?.id, currentTopic?.id]);

  const finishedTopicsIds = useMemo(
    () =>
      (currentCourseProgress?.progress ?? []).reduce<number[]>(
        (acc, { status, topic_id }) =>
          status === API.CourseProgressItemElementStatus.COMPLETE
            ? [...acc, topic_id]
            : acc,
        []
      ),
    [currentCourseProgress]
  );

  const availableTopicsIds = useMemo(() => {
    const activeLessons = (currentCourseProgram?.lessons ?? []).filter(
      (l) => l.active_from && isAfter(new Date(), new Date(l.active_from))
    );
    const activeLessonsFlatTopics = getFlatTopics(activeLessons);

    const { incomplete, in_progress, complete } =
      activeLessonsFlatTopics.reduce<{
        in_progress: number[];
        complete: number[];
        incomplete: number[];
      }>(
        (acc, t) => {
          const el = (currentCourseProgress?.progress ?? []).find(
            ({ topic_id }) => topic_id === t.id
          );
          if (!el) return acc;

          const statusMap = {
            [API.CourseProgressItemElementStatus.INCOMPLETE]: "incomplete",
            [API.CourseProgressItemElementStatus.COMPLETE]: "complete",
            [API.CourseProgressItemElementStatus.IN_PROGRESS]: "in_progress",
          } as const;

          return {
            ...acc,
            [statusMap[el.status]]: [...acc[statusMap[el.status]], t.id],
          };
        },
        {
          in_progress: [],
          incomplete: [],
          complete: [],
        }
      );

    if (in_progress.length) return [...in_progress, ...complete];

    const firstInCompletedLessonId = incomplete?.[0] ? [incomplete[0]] : [];
    return [...complete, ...firstInCompletedLessonId];
  }, [currentCourseProgram?.lessons, currentCourseProgress?.progress]);

  const currentLessonParentsIds: number[] = useMemo(() => {
    if (!currentLesson) return [];

    return getLessonParentsIds(flatLessons, currentLesson);
  }, [currentLesson, flatLessons]);

  const currentCourseCategories = useMemo(
    () => currentCourseProgress?.categories,
    [currentCourseProgress?.categories]
  );

  const flatCategoriesSortedById = useMemo(
    () => getFlatCategoriesSortedById(categoryTree.list ?? []),
    [categoryTree.list]
  );

  const courseCategories: CourseCategories | undefined = useMemo(() => {
    const levelCategory = currentCourseCategories?.[0];
    const competencyCategory =
      flatCategoriesSortedById?.[levelCategory?.parent_id ?? -1];
    const roleCategory =
      flatCategoriesSortedById?.[competencyCategory?.parent_id ?? -1];

    if (!levelCategory || !competencyCategory || !roleCategory) return;

    return { levelCategory, competencyCategory, roleCategory };
  }, [currentCourseCategories, flatCategoriesSortedById]);

  useEffect(() => {
    if (!courseId) return;

    fetchProgram(Number(courseId));
    fetchCategories();
  }, [fetchProgram, courseId, fetchCategories]);

  useEffect(() => {
    if (!topicId) return;

    fetchProgress();
  }, [fetchProgress, topicId]);

  // disable next button
  useEffect(() => {
    if (!currentTopic?.topicable_type) return;

    setIsNextTopicButtonDisabled(
      DISABLE_NEXT_BUTTON_TYPES.includes(currentTopic?.topicable_type)
    );
  }, [currentTopic?.topicable_type]);

  const isCourseFinished = useMemo(
    () =>
      Boolean(
        currentCourseProgress?.progress &&
          currentCourseProgress?.progress.every(
            ({ status }) =>
              status === API.CourseProgressItemElementStatus.COMPLETE
          )
      ),
    [currentCourseProgress?.progress]
  );

  const isAnyDataLoading = useMemo(
    () => program.loading || categoryTree.loading || progress.loading,
    [categoryTree.loading, program.loading, progress.loading]
  );

  return (
    <Context.Provider
      value={{
        flatTopics,
        prevTopic,
        currentTopic,
        nextTopic,
        currentLesson,
        currentCourseProgram,
        isNextTopicButtonDisabled,
        setIsNextTopicButtonDisabled,
        completeCurrentTopic,
        availableTopicsIds,
        finishedTopicsIds,
        currentLessonParentsIds,
        isCourseFinished,
        courseCategories,
        isAnyDataLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CourseProvider;
