import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useNavigate } from "react-router-dom";

import React, { useContext, useEffect } from "react";

import AuthLayout from "components/App/AuthLayout";
import Loader from "components/Loader";
import routeRoutes from "components/Routes/routes";

import imgBg from "assets/images/woman_board.svg";

import UserDetailsForm from "./UserDetailsForm";
import { Description } from "./styles";

const OnboardingPage: React.FC = () => {
  const { user, token } = useContext(EscolaLMSContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate(routeRoutes.login);
      return;
    }
    if (user?.value?.age || user?.value?.city_size) {
      navigate(routeRoutes.home);
    }
  }, [user?.value?.age, user?.value?.city_size, navigate, token]);

  if (!user || user.loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <AuthLayout title="Zarejestrowano. Dziękujemy" backgroundImgSrc={imgBg}>
      <Description>
        Mamy do Ciebie kilka pytań, dzięki którym nieco lepiej Cię poznamy.{" "}
        <br />
        <br />
        Wypełnij poniższe pola i wybierz odpowiedzi, które najlepiej Cię
        opisują.
      </Description>
      <UserDetailsForm onSuccess={() => navigate(routeRoutes.home)} />
    </AuthLayout>
  );
};

export default OnboardingPage;
