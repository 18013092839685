import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import React, { useContext, useEffect, useMemo } from "react";

import LayoutContainer from "components/App/Container";
import ChallengeCard from "components/ChallengeCard";
import Layout from "components/Layout";
import PickedChallengeCard from "components/PickedChallengedCard";
import TestBanner from "components/TestBanner";
import { Stack } from "components/common/Stack";

import useMediaQuery from "hooks/useMediaQuery";
import { usePickedChallenges } from "hooks/usePickedChallenges";

import NoChallengeCard from "pages/Challenge/components/NoChallengeCard";

import CompletedCoursesSection from "./CompletedCoursesSection";
import {
  ChallengeSection,
  SectionDesc,
  SectionTitle,
  SliderContainer,
  SliderSection,
  StyledTitle,
  WelcomeText,
} from "./styles";

const HomePage: React.FC = () => {
  const {
    user,
    fetchChallenges,
    challenge,
    challenges,
    fetchChallenge,
    fetchCategories,
    fetchProgress,
    fetchProgram,
    fetchMyCourses,
  } = useContext(EscolaLMSContext);
  const mobileView = useMediaQuery("mobile");
  const tabletView = useMediaQuery("tablet");
  const SLIDES_PER_VIEW = tabletView ? 2 : 3;

  const pickedChallenges = usePickedChallenges();

  const highlightedChallenge = useMemo(
    () =>
      (challenges.list?.data ?? []).find(
        (challenge) => challenge.is_highlighted
      ),
    [challenges.list?.data]
  );

  const complexChallenge = useMemo(
    () =>
      challenge.byId
        ? Object.values(challenge.byId).find(
            (context) =>
              context.value?.type === API.CompetencyChallengeType.Complex
          )?.value
        : undefined,
    [challenge.byId]
  );

  const simpleChallenges = useMemo(
    () =>
      (challenges.list?.data ?? []).filter(
        (ch) => ch.type === API.CompetencyChallengeType.Simple
      ),
    [challenges.list?.data]
  );

  useEffect(() => {
    fetchChallenges({}).then((res) => {
      if (res.success) {
        res.data.forEach((ch) => fetchChallenge(ch.id));
      }
    });
    fetchProgress().then((res) => {
      if (!res || !res.success) return;
      res.data.forEach((progressItem) => {
        if (Array.isArray(progressItem)) return;
        fetchProgram(progressItem.course.id);
      });
    });
    fetchCategories();
    fetchMyCourses();
  }, [
    fetchChallenges,
    fetchChallenge,
    fetchProgress,
    fetchProgram,
    fetchCategories,
    fetchMyCourses,
  ]);

  return (
    <Layout>
      <LayoutContainer>
        <WelcomeText>
          Hej, {user?.value?.first_name} 👋 <span>Dobrze Cię widzieć!</span>
        </WelcomeText>
        <ChallengeSection>
          <SectionTitle>Podjęte wyzwania</SectionTitle>
          {pickedChallenges.length > 0 ? (
            pickedChallenges.map((pickedChallenge) => (
              <PickedChallengeCard
                key={`ch-${pickedChallenge.challenge.id} cat-${pickedChallenge.roleCategory.id}`}
                {...pickedChallenge}
              />
            ))
          ) : (
            <NoChallengeCard />
          )}
        </ChallengeSection>
        <SliderSection>
          <StyledTitle>Wybierz kolejne wyzwanie</StyledTitle>
          <SectionDesc>
            Każde wyzwanie składa się z testu poziomującego, po którym możesz
            wybrać odpowiedni dla siebie kurs. Możesz wybrać więcej niż jedno
            wyzwanie.
          </SectionDesc>
          {!mobileView && highlightedChallenge && (
            <ChallengeCard
              challenge={highlightedChallenge}
              variant="horizontal"
            />
          )}

          {!mobileView ? (
            <SliderContainer>
              <Swiper
                navigation
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={SLIDES_PER_VIEW}
              >
                {simpleChallenges?.map((challenge, index) => (
                  <SwiperSlide key={`id-${challenge.id} i-${index}`}>
                    <ChallengeCard challenge={challenge} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SliderContainer>
          ) : (
            <Stack $gap={43}>
              {simpleChallenges?.map((challenge, index) => (
                <ChallengeCard
                  key={`id-${challenge.id} i-${index}`}
                  challenge={challenge}
                />
              ))}
            </Stack>
          )}
          {complexChallenge?.type === API.CompetencyChallengeType.Complex && (
            <TestBanner complexChallenge={complexChallenge} />
          )}
          <CompletedCoursesSection />
        </SliderSection>
      </LayoutContainer>
    </Layout>
  );
};

export default HomePage;
