import { Container } from "react-grid-system";

import React from "react";

type Props = {
  children: React.ReactNode;
  maxWidth?: number;
};

const LayoutContainer: React.FC<Props> = ({ children, maxWidth }) => {
  return (
    <Container
      style={{
        maxWidth: maxWidth || 1110,
      }}
    >
      {children}
    </Container>
  );
};

export default LayoutContainer;
