import styled from "styled-components";

import Icon from "../Icon";

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  padding: 20px;
  border: 2px dashed ${({ theme }) => theme.colors.gray200};
  border-radius: 12px;

  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray50};
  }
`;

export const Text = styled.p`
  margin: 0;
`;

export const PrimaryColorIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;
