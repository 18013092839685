import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import Button from "components/common/Button";
import { Row } from "components/common/Row";

export const Container = styled.div`
  padding-bottom: 12px;
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.smlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Description = styled.p`
  margin: 9px 0 25px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding-right: 80px;
`;

export const DetailsItem = styled.div`
  &:last-of-type {
    margin: 29px 0 0 auto;
  }
`;

export const BasicCourseInfo = styled.div`
  margin: 30px 0px;
  padding-bottom: 30px;
  border-bottom: 2px dotted ${({ theme }) => theme.colors.gray200};
  & > div {
    background-color: ${({ theme }) => theme.colors.info};
    padding: 10px 18px;
    border-radius: 5px;
    & h5 {
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      margin: 0px;
    }

    & p {
      font-size: ${({ theme }) => theme.fontSizes.small};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      margin: 0;
      white-space: nowrap;
    }
  }
`;

export const ResultWrapper = styled.div`
  & > h4 {
    font-size: ${({ theme }) => theme.fontSizes.smlarge};
  }
  margin-bottom: 34px;
`;

export const AccordionWrapper = styled.div`
  & > h4 {
    font-size: ${({ theme }) => theme.fontSizes.smlarge};
    margin-bottom: 18px;
  }
`;
export const GetCourseWrapper = styled.div`
  & > h4 {
    font-size: ${({ theme }) => theme.fontSizes.smlarge};
    margin-bottom: 18px;
  }
`;

export const AccessInformation = styled.p`
  margin: 0px;
`;

export const OrText = styled.p`
  margin: auto 20px;
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 38px;

  & svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const StyledVoucherButton = styled(Button)`
  width: 100%;
  max-height: 38px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;
export const ButtonContent = styled(Row)`
  width: 100%;
  justify-content: space-between;
  & p {
    margin: 0px;
    & span {
      margin: 0px 10px;
    }
  }
`;

export const ModalTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ModalDesc = styled.p`
  margin: 6px 0 25px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const ModalContent = styled.div`
  width: 100%;
`;

export const VoucherInfo = styled(Row)`
  gap: 3px;
  & p {
    margin: 0px;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;

export const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  },
  content: {
    margin: "0 auto",
    padding: "22px",
    borderRadius: "10px",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    height: "max-content",
    maxWidth: "426px",
    width: "100%",
    overflow: "hidden",
  },
};

export const ButtonWrapper = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 15px;
  }
`;
