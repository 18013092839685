import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { Col, Row } from "react-grid-system";
import { useParams } from "react-router-dom";

import React, { useContext, useEffect, useMemo } from "react";

import LayoutContainer from "components/App/Container";
import Layout from "components/Layout";
import Icon from "components/common/Icon";

import { useScrollTop } from "hooks/useScrollTop";

import { getFlatCategoriesSortedById } from "utils/categories";

import AccordionsSection from "./components/AccordionsSection";
import ChallengeAuthorsSidebar from "./components/ChallengeAuthorsSidebar";
import ChallengeSection from "./components/ChallengeSection";
import DescriptionSection from "./components/DescriptionSection";
import { Content, Title } from "./styles";

const ChallengePage: React.FC = () => {
  useScrollTop();
  const { fetchChallenge, challenge, categoryTree, fetchCategories } =
    useContext(EscolaLMSContext);
  const { id } = useParams();

  const currentChallenge = useMemo(
    () => (id ? challenge.byId?.[+id].value : challenge.value),
    [challenge.byId, challenge.value, id]
  );

  const challengeCategories = useMemo(() => {
    const challengeCategoryIds = currentChallenge?.categories;

    if (!challengeCategoryIds || !categoryTree.list) return [];
    const categoriesSortedById = getFlatCategoriesSortedById(categoryTree.list);

    return challengeCategoryIds.reduce<API.Category[]>(
      (acc, categoryId) =>
        categoriesSortedById?.[categoryId]
          ? [...acc, categoriesSortedById[categoryId]]
          : acc,
      []
    );
  }, [currentChallenge?.categories, categoryTree.list]);

  useEffect(() => {
    fetchChallenge(Number(id));
    fetchCategories();
  }, [id, fetchChallenge, fetchCategories]);

  return (
    <Layout>
      <LayoutContainer>
        <Row nogutter>
          <Col lg={8}>
            <Content>
              <Title>
                <Icon name="arrowRightOrange" />
                {currentChallenge?.name}
              </Title>
              <DescriptionSection description={currentChallenge?.description} />
              <ChallengeSection challenge={currentChallenge} />
              <AccordionsSection
                categories={challengeCategories}
                title={currentChallenge?.name || ""}
              />
            </Content>
          </Col>
          <Col lg={4}>
            <ChallengeAuthorsSidebar authors={currentChallenge?.authors} />
          </Col>
        </Row>
      </LayoutContainer>
    </Layout>
  );
};

export default ChallengePage;
