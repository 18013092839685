import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import Button from "components/common/Button";
import Icon from "components/common/Icon";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  border: 2px solid ${({ theme }) => theme.colors.gray100};
  border-radius: 9px;
  padding: 20px 28px 30px;
  width: 100%;
`;
export const IconPrimary = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const CardTitle = styled.h3`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;

  @media ${device.mobile} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledButton = styled(Button)`
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray400};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
