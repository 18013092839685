import * as Yup from "yup";

export const emailValidation = Yup.string()
  .email("Niepoprawny adres email")
  .required("To pole jest wymagane");

export const fieldIsRequired = Yup.string().required("To pole jest wymagane");

export const passwordValidation = Yup.string()
  .min(8, "Hasło musi mieć minimum 8 znaków")
  .required("To pole jest wymagane");

export const notCurrentPasswordValidation = passwordValidation.notOneOf(
  [Yup.ref("current_password")],
  "Nowe hasło musi być inne niż stare"
);

export const repeatPasswordValidation = Yup.string()
  .oneOf([Yup.ref("password")], "Hasła muszą być takie same")
  .required("To pole jest wymagane");

export const checkboxValidation = Yup.boolean().oneOf(
  [true],
  "To pole jest wymagane"
);
