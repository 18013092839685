import React, { FC } from "react";

import { Input, Label, Span, SwitchContainer } from "./styles";

export interface SwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
}

export const Switch: FC<SwitchProps> = ({
  checked,
  disabled,
  name,
  label,
  ...props
}) => (
  <SwitchContainer $isDisabled={disabled}>
    <Label $isChecked={checked} $isDisabled={disabled}>
      <Input
        {...props}
        checked={checked}
        type="checkbox"
        name={name}
        data-testid={name}
      />
      {label && <Span>{label}</Span>}
    </Label>
  </SwitchContainer>
);
