import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import {
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes as Switch,
} from "react-router-dom";

import { PropsWithChildren, ReactElement, Suspense, useContext } from "react";

import ChallengePage from "pages/Challenge";
import CompetencyTestPage from "pages/CompetencyTest";
import Course from "pages/Course";
import DeleteAccountConfirmationPage from "pages/DeleteAccountConfirmation";
import ErrorPage from "pages/ErrorPage";
import HomePage from "pages/Home";
import Login from "pages/Login";
import Onboarding from "pages/Onboarding";
import PaymentReturn from "pages/PaymentReturn";
import Register from "pages/Register";
import ResetPassword from "pages/ResetPassword";
import StaticPage from "pages/StaticPage";
import CertificatesPage from "pages/UserPanel/Certificates";
import DeleteAccountPage from "pages/UserPanel/DeleteAccount";
import MetricsPage from "pages/UserPanel/Metrics";
import NewsletterPage from "pages/UserPanel/Newsletter";
import ProfileDataPage from "pages/UserPanel/ProfileData";

import routes from "./routes";

const ProtectedRoute = ({
  redirectPath = "/logowanie",
}: {
  redirectPath?: string;
}) => {
  const { token, user } = useContext(EscolaLMSContext);

  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }
  if (!user.value?.age || !user.value?.city_size) {
    return <Navigate to={routes.onboarding} replace />;
  }
  return <Outlet />;
};

const Routes: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
  const {
    login,
    home,
    register,
    resetPassword,
    onboarding,
    userPanel,
    challengeDetails,
    course,
    deleteAccountConfirmation,
    competencyTest,
    page,
    paymentReturn,
  } = routes;

  return (
    <HashRouter>
      <Suspense fallback={"Ładowanie..."}>
        <Switch>
          <Route path={login} element={<Login />} />
          <Route path={register} element={<Register />} />
          <Route path={resetPassword} element={<ResetPassword />} />
          <Route path={onboarding} element={<Onboarding />} />
          <Route
            path={deleteAccountConfirmation}
            element={<DeleteAccountConfirmationPage />}
          />
          <Route path={page} element={<StaticPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path={paymentReturn} element={<PaymentReturn />} />
            <Route path={home} element={<HomePage />} />
            <Route path={userPanel.metrics} element={<MetricsPage />} />
            <Route path={course} element={<Course />} />
            <Route path={competencyTest} element={<CompetencyTestPage />} />
            <Route
              path={userPanel.accountDetails}
              element={<ProfileDataPage />}
            />
            <Route
              path={userPanel.certificates}
              element={<CertificatesPage />}
            />
            <Route path={challengeDetails} element={<ChallengePage />} />
            <Route
              path={userPanel.deleteAccount}
              element={<DeleteAccountPage />}
            />
            <Route path={userPanel.newsletter} element={<NewsletterPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Switch>
      </Suspense>
      {children}
    </HashRouter>
  );
};

export default Routes;
