import Image from "@escolalms/sdk/lib/react/components/Image";

import React from "react";

import { AvatarWrapper, Box, Name, Position } from "./styles";

type Props = {
  name: string;
  avatar: string | null;
  position: string;
};

const CourseAuthor: React.FC<Props> = ({ name, position, avatar }) => {
  return (
    <Box>
      {avatar && (
        <AvatarWrapper>
          <Image path={avatar} alt={name} />
        </AvatarWrapper>
      )}
      <div>
        <Name>{name}</Name>
        {position && <Position>{position}</Position>}
      </div>
    </Box>
  );
};

export default CourseAuthor;
