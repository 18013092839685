import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import LayoutContainer from "components/App/Container";
import Layout from "components/Layout";
import Loader from "components/Loader";
import routeRoutes from "components/Routes/routes";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import GiftQuizPlayerContent from "components/course/Quizzes/GiftQuizPlayerContent";

import useCompetencyTestResults from "hooks/useCompetencyTestResults";
import { useQuiz } from "hooks/useQuiz";

import { retryPromise } from "utils/retryPromise";

import ResultCard from "./ResultCard";
import {
  DescriptionButtonContainer,
  InnerWrapper,
  ResultsContainer,
  ResultsList,
  ResultsTitle,
} from "./styles";

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  gap: 25px;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompetencyTestPage: React.FC = () => {
  const { quizId, questionId, challengeId } = useParams();
  const { challenge, fetchChallenge } = useContext(EscolaLMSContext);

  const [resultsLoading, setResultsLoading] = useState(false);

  const fetchResults = useCallback(
    async (quizAttempt: API.QuizAttempt) => {
      try {
        setResultsLoading(true);
        await retryPromise(
          () => fetchChallenge(Number(challengeId)),
          (response) =>
            Boolean(
              response &&
                response.success &&
                response.data.results.find(
                  (result) => result.attempt_id === quizAttempt?.id
                )
            ),
          { waitFor: 1000, attempts: 20 }
        );
      } catch (e) {
        /* TODO */
      } finally {
        setResultsLoading(false);
      }
    },
    [challengeId, fetchChallenge]
  );

  const {
    data,
    startQuiz,
    sendAnswer,
    endQuiz,
    firstQuestionId,
    nextQuestionId,
    previousQuestionId,
  } = useQuiz(Number(quizId), fetchResults);

  const challengeResults = useMemo(
    () =>
      (challenge.value?.results ?? []).find(
        (result) => result.attempt_id === data.value?.id
      ),
    [challenge.value?.results, data.value?.id]
  );

  const { challengeTestResults } = useCompetencyTestResults(challengeResults);
  const navigate = useNavigate();

  const navigateToFirstQuestion = useCallback(() => {
    navigate(
      routeRoutes.competencyTest
        .replace(":questionId", String(firstQuestionId))
        .replace(":quizId", String(quizId))
        .replace(":challengeId", String(challengeId))
    );
  }, [navigate, firstQuestionId, quizId, challengeId]);

  useEffect(() => {
    quizId && startQuiz();
  }, [quizId]);

  useEffect(() => {
    navigateToFirstQuestion();
    //Don't add navigate to dependencies array. It breaks next question button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstQuestionId]);

  //This fix bug when user click back button and questionId is undefined
  useEffect(() => {
    if (questionId === "undefined") navigateToFirstQuestion();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  if (!quizId || !questionId || data.loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  if (data.value?.questions.length === 0 || !data.value) {
    return (
      <InfoContainer>
        Brak testu lub test został już wykonany.
        <Button to={routeRoutes.home} type="button">
          Powrót do dashboardu
        </Button>
      </InfoContainer>
    );
  }

  return (
    <Layout>
      <LayoutContainer>
        {data.value &&
          data?.value?.questions?.length > 0 &&
          !data.value?.is_ended && (
            <GiftQuizPlayerContent
              attempt={data.value}
              startQuiz={startQuiz}
              sendAnswer={sendAnswer}
              endQuiz={endQuiz}
              currentQuestionId={Number(questionId)}
              showOnlyOneQuestion
              nextQuestionId={nextQuestionId(Number(questionId))}
              previousQuestionId={previousQuestionId(Number(questionId))}
              challengeId={Number(challengeId)}
            />
          )}
        {data.value?.is_ended && (
          <>
            <ResultsContainer>
              <ResultsTitle>Dziękujemy! Test został wykonany</ResultsTitle>
              <InnerWrapper>
                <ResultsTitle>Twoje wyniki są następujące:</ResultsTitle>
                <ResultsList>
                  {resultsLoading && challengeTestResults.length === 0 && (
                    <Loader />
                  )}
                  {challengeTestResults.length > 0 &&
                    challengeTestResults.map((challengeTestResult, i) => (
                      <ResultCard
                        key={i}
                        challengeTestResult={challengeTestResult}
                        challengeType={challenge.value?.type}
                      />
                    ))}
                </ResultsList>
              </InnerWrapper>
            </ResultsContainer>
            <DescriptionButtonContainer>
              <Button
                to={routeRoutes.challengeDetails.replace(
                  ":id",
                  String(challengeId)
                )}
                size="small"
                icon={<Icon name="arrowTopRight" />}
                iconGap={25}
                type="button"
              >
                Wróć do opisu
              </Button>
            </DescriptionButtonContainer>
          </>
        )}
      </LayoutContainer>
    </Layout>
  );
};

export default CompetencyTestPage;
