import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const PanelMenu = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 6px;
  border-radius: 10px;

  @media ${device.smallTablet} {
    margin-bottom: 15px;
  }
`;

export const MenuItem = styled(NavLink)<{ $redColor: boolean }>`
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  border-radius: 5px;
  padding: 13px 15px;
  display: block;
  width: 100%;
  margin: 2px auto;
  color: ${({ theme, $redColor }) =>
    $redColor ? theme.colors.red : theme.colors.text};

  &:hover,
  &.active {
    background-color: #fff8e6;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 24px 33px 33px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 14px;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
