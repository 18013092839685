import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { FULL_DATE_FORMAT } from "const/dates";
import { PAYMENT_RETURN_URL, VOUCHER_PROBLEM } from "const/links";
import { differenceInWeeks, format } from "date-fns";
import { pl } from "date-fns/locale";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CompetencyTestResult } from "types/course";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Accordion from "components/Accordion";
import CustomModal from "components/App/Modal";
import routeRoutes from "components/Routes/routes";
import TestResultInfo from "components/TestResultInfo";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import { Row } from "components/common/Row";
import { Stack } from "components/common/Stack";

import useModal from "hooks/useModal";

import { handleMailRedirect } from "utils";
import {
  findCompetenceById,
  formatPrice,
  getCompetenceResultLevel,
} from "utils/course";

import VoucherForm from "./VoucherForm";
import {
  AccessInformation,
  AccordionWrapper,
  BasicCourseInfo,
  ButtonContent,
  ButtonWrapper,
  Container,
  Description,
  GetCourseWrapper,
  ModalContent,
  ModalDesc,
  ModalTitle,
  OrText,
  ResultWrapper,
  StyledButton,
  StyledVoucherButton,
  VoucherInfo,
  customModalStyles,
} from "./styles";

type Props = {
  competence?: CompetencyTestResult;
  matchedHigherLevelCourse?: API.Course;
  onClose: () => void;
};

const CompetencyDetailsCard: React.FC<Props> = ({
  competence,
  matchedHigherLevelCourse,
  onClose,
}) => {
  const {
    user,
    fetchCourse,
    course,
    addToCart,
    payWithP24,
    fetchCart,
    categoryTree,
    progress,
  } = useContext(EscolaLMSContext);
  const navigate = useNavigate();
  const { isOpen, setIsOpen } = useModal();
  const [matchedCourse, setMatchedCourse] = useState<API.Course | null>(null);

  const actualCompetenceLevel = competence
    ? getCompetenceResultLevel(competence)
    : categoryTree.list &&
      findCompetenceById(matchedCourse?.categories?.[0].id, categoryTree.list);

  const isCourseOwned = useMemo(
    () =>
      Boolean(
        (progress.value ?? []).find((p) => p.course.id === matchedCourse?.id)
      ),
    [matchedCourse?.id, progress.value]
  );

  const courseStartDate =
    matchedCourse &&
    matchedCourse.active_from &&
    new Date(matchedCourse.active_from);

  const courseEndDate =
    matchedCourse &&
    matchedCourse.active_to &&
    new Date(matchedCourse.active_to);

  const handlePay = () => {
    const email = String(user?.value?.email).toLowerCase();
    addToCart(course?.value?.product?.id)
      .then(() =>
        payWithP24(email, PAYMENT_RETURN_URL)
          .then((response: any) => {
            if (!response.success) {
              toast.error("Wystąpił niespodziewany błąd");
              return;
            }
            if (
              response?.data?.status === "redirect" &&
              response?.data?.redirect_url
            ) {
              window.location.replace(response.data.redirect_url);
              return;
            }
            if (response?.data?.status === "paid") {
              navigate(`${routeRoutes.home}`);
              toast.success("Płatność przebiegła pomyślnie");
            }
            fetchCart();
          })
          .catch(() => {
            toast.error("Wystąpił niespodziewany błąd");
          })
      )
      .catch(() => {
        toast.error("Wystąpił niespodziewany błąd");
      });
  };

  const getMatchedCourse = useCallback(() => {
    const isMatchedCourse = competence && !!competence.matched_course.length;
    const matchedCourseId = competence && competence.matched_course[0];
    isMatchedCourse && matchedCourseId
      ? fetchCourse(matchedCourseId)
          .then((response) => {
            if (response.success) {
              setMatchedCourse(response.data);
            }
          })
          .catch(() => {
            toast.error("Wystąpił niespodziewany błąd");
            setMatchedCourse(null);
          })
      : matchedHigherLevelCourse
      ? setMatchedCourse(matchedHigherLevelCourse)
      : setMatchedCourse(null);
  }, [competence, fetchCourse, matchedHigherLevelCourse]);

  useEffect(() => {
    getMatchedCourse();
  }, [getMatchedCourse]);

  return (
    <>
      <Container>
        <Description>
          {actualCompetenceLevel?.description ||
            "Brak opisu dla danej kompetencji"}
        </Description>
        {competence && (
          <ResultWrapper>
            <h4>Twój wynik</h4>
            <TestResultInfo competence={competence} />
          </ResultWrapper>
        )}

        <AccordionWrapper>
          <h4>Co znajdziesz w tym kursie</h4>
          {actualCompetenceLevel?.subcategories?.map((item) => (
            <Accordion key={item.id} title={item.name}>
              {item.description || "Brak opisu"}
            </Accordion>
          ))}
        </AccordionWrapper>

        <BasicCourseInfo>
          <Stack>
            <Row $justifyContent="space-between">
              <h5>Czas trwania kursu</h5>
              <p>
                {courseStartDate && courseEndDate
                  ? `${differenceInWeeks(
                      courseEndDate,
                      courseStartDate
                    )} tygodni`
                  : "brak danych"}
              </p>
            </Row>
            <Row $justifyContent="space-between">
              <h5>Chcesz uczyć się w grupie? Najblisza rozpoczyna się </h5>
              <p>
                {courseStartDate
                  ? `${format(courseStartDate, FULL_DATE_FORMAT, {
                      locale: pl,
                    })}`
                  : "brak danych"}
              </p>
            </Row>
          </Stack>
        </BasicCourseInfo>

        <GetCourseWrapper>
          <h4>Rozwiń kompetencje. Przejdź na wyższy poziom</h4>
          {matchedCourse ? (
            isCourseOwned ? (
              <AccessInformation>
                Masz już dostęp do tego kursu
              </AccessInformation>
            ) : (
              <ButtonWrapper>
                <StyledButton onClick={handlePay}>
                  <ButtonContent>
                    <p>Wykup dostęp</p>
                    <p>
                      {matchedCourse.product && (
                        <span>
                          {formatPrice(
                            matchedCourse.product.price,
                            matchedCourse.product.tax_rate
                          )}{" "}
                          zł
                        </span>
                      )}
                      <Icon name="arrowRightSmall" />
                    </p>
                  </ButtonContent>
                </StyledButton>
                <OrText>lub</OrText>
                <StyledVoucherButton
                  styleVariant="primaryGray"
                  onClick={() => setIsOpen(true)}
                >
                  <p>Posiadasz kod dostepu? Wpisz</p>
                </StyledVoucherButton>
              </ButtonWrapper>
            )
          ) : (
            <AccessInformation>Brak dopasowanego kursu</AccessInformation>
          )}
        </GetCourseWrapper>
      </Container>
      <CustomModal
        customStyles={customModalStyles}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        customCloseIcon="closeIcon"
        iconPosition={{ top: "-12px", right: "-10px" }}
      >
        <ModalContent>
          <ModalTitle>Posiadasz kod dostępu?</ModalTitle>
          <ModalDesc>Wpisz go tutaj, aby odblokować dostęp</ModalDesc>
          <VoucherForm
            onSuccess={() => {
              setIsOpen(false);
              onClose();
              navigate(routeRoutes.home);
            }}
            productId={matchedCourse?.product?.id}
          />
          <VoucherInfo>
            <p>Masz problem z kodem?</p>
            <Button
              styleVariant="linkBlack"
              size="small"
              onClick={() => handleMailRedirect(VOUCHER_PROBLEM)}
            >
              <p>Zgłoś tutaj</p>
            </Button>
          </VoucherInfo>
        </ModalContent>
      </CustomModal>
    </>
  );
};

export default CompetencyDetailsCard;
