import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

import { FC, useContext } from "react";

import UserPanelLayout from "components/App/UserPanelLayout";

import { UserNameForm } from "./UserNameForm";
import { UserPasswordForm } from "./UserPasswordForm";
import { InitialValueWrapper, StyledRow } from "./styles";

const ProfileDataPage: FC = () => {
  const { user } = useContext(EscolaLMSContext);

  return (
    <UserPanelLayout title="Dane profilowe">
      <StyledRow $justifyContent="space-between">
        <InitialValueWrapper>
          <h4>Adres email</h4>
          <p>{user.value?.email}</p>
        </InitialValueWrapper>
      </StyledRow>
      <UserNameForm />
      <UserPasswordForm />
    </UserPanelLayout>
  );
};

export default ProfileDataPage;
