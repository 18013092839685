import { styled } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Circle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;

export const Score = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
export const MaxScore = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
