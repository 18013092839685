import "katex/dist/katex.min.css";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { Gallery, Item } from "react-photoswipe-gallery";
import { Link } from "react-router-dom";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import styled, { createGlobalStyle } from "styled-components";

import React, { useState } from "react";

import { SharedLightboxStyle } from "utils";
import {
  fixContentForMarkdown,
  setFontSizeByHeaderLevel,
} from "utils/markdown";

interface StyledMarkdownRendererProps {
  $mobile?: boolean;
  $fontSize?: string;
}

export interface MarkdownRendererProps extends ReactMarkdownOptions {
  mobile?: boolean;
  fontSize?: string;
}

const pxToEm = (px: string) => {
  const pxNumber = parseFloat(px);
  const emNumber = pxNumber / 14;
  return emNumber.toFixed(2);
};

const StyledMarkdownRenderer = styled("div")<StyledMarkdownRendererProps>`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${(props) => props.$fontSize && `${pxToEm(props.$fontSize)}em`};
  line-height: 1.55em;

  h1 {
    font-size: ${(props) =>
      `${pxToEm(setFontSizeByHeaderLevel(1, props.$mobile))}em`};
  }

  h2 {
    font-size: ${(props) =>
      `${pxToEm(setFontSizeByHeaderLevel(2, props.$mobile))}em`};
  }

  h3 {
    font-size: ${(props) =>
      `${pxToEm(setFontSizeByHeaderLevel(3, props.$mobile))}em`};
  }

  h4 {
    font-size: ${pxToEm(setFontSizeByHeaderLevel(4))}em;
  }

  h5 {
    font-size: ${pxToEm(setFontSizeByHeaderLevel(5))}em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    font-weight: bold;
    line-height: 125%;
    color: ${({ theme }) => theme.colors.text};

    &:first-child {
      margin-top: 0;
    }
  }

  strong,
  b {
    font-weight: bold;
  }

  code {
    border-radius: 4px;
    border: 1px solid rgb(232, 235, 237);
    padding: 3px 4px;
    font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier,
      monospace;
    font-size: 85%;
  }

  mark {
    border-radius: 1px;
    background: rgb(171, 255, 50);
    padding: 0.2em;
  }

  blockquote {
    border-left: 3px solid rgb(218, 225, 233);
    margin: 1em 0;
    padding-left: 10px;
    font-style: italic;
  }

  .table-responsive {
    td,
    tr,
    th {
      border: 1px solid ${({ theme }) => theme.colors.border};
      padding: 5px 10px;
    }
    table {
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.border};
      border-collapse: collapse;
    }
  }

  ul,
  ol {
    margin: 1em 0.1em;
    padding: 0;
  }

  ol {
    padding-left: 19px;
  }

  ul {
    list-style: none;
  }

  ul li {
    position: relative;
    padding-left: 22px;

    &:before {
      content: "";
      position: absolute;
      width: 13px;
      height: 13px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 50px;
      left: 0;
      top: 6px;
    }
  }

  ul.contains-task-list {
    list-style: none;
    padding: 0;
    margin: 1em 0;
  }
  ul.contains-task-list li {
    display: flex;
    &:before {
      display: none;
    }
  }
  ul.contains-task-list li input {
    pointer-events: initial;
    opacity: 1;
    margin: 3px 0.5em 0 0;
    width: 14px;
    height: 14px;
  }
  ul.contains-task-list li:has(input[checked]) {
    color: rgb(78, 92, 110);
    text-decoration: line-through;
  }

  .image {
    text-align: center;
    max-width: 100%;
    clear: both;
  }
  .image-left-50 {
    float: left;
    width: 50%;
    margin-right: 2em;
    margin-bottom: 1em;
    clear: initial;
  }

  .image-right-50 {
    float: right;
    width: 50%;
    margin-left: 2em;
    margin-bottom: 1em;
    clear: initial;
  }

  .image span {
    line-height: 0;
    display: inline-block;
  }

  .image img {
    display: inline-block;
    max-width: 100%;
    max-height: 75vh;
  }
`;

const LightBoxOverwrite = createGlobalStyle`
${SharedLightboxStyle}
`;

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  mobile = false,
  fontSize = "16",
  children,
  className,
  ...props
}) => (
  <StyledMarkdownRenderer
    className={`wellms-component ${className}`}
    $mobile={mobile}
    $fontSize={fontSize}
  >
    <ReactMarkdown
      linkTarget="_blank"
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      remarkPlugins={[remarkGfm, remarkMath]}
      components={{
        img: (props) => {
          return <MarkdownImage {...props} />;
        },
        table: (props) => {
          return <MarkdownTable {...props} />;
        },
        a: (props) => {
          return <Link to={String(props.href)} {...props} />;
        },
        input: (props) => {
          return <MarkdownCheckList {...props} />;
        },
      }}
      {...props}
    >
      {fixContentForMarkdown(children)}
    </ReactMarkdown>
  </StyledMarkdownRenderer>
);

export const MarkdownCheckList: React.FC<
  React.InputHTMLAttributes<HTMLInputElement>
> = ({ checked, disabled, type }) => {
  return (
    <input
      className="text-checkbox"
      type={type}
      disabled={disabled}
      checked={checked}
    />
  );
};

export const MarkdownImage: React.FC<
  React.ImgHTMLAttributes<HTMLImageElement>
> = ({ src, alt, title }) => {
  const [size, setSize] = useState([0, 0]);

  return (
    <>
      <LightBoxOverwrite />
      <Gallery
        options={{
          arrowPrev: false,
          arrowNext: false,
          imageClickAction: "zoom",
          initialZoomLevel: "fit",
          secondaryZoomLevel: 2,
          maxZoomLevel: 3,
        }}
      >
        <Item original={src} width={size[0]} height={size[1]}>
          {({ ref, open }) => (
            <div className={`image ${title ? "image-" + title : ""}`}>
              <span
                role="button"
                onClick={open}
                onKeyDown={() => open({} as React.MouseEvent)}
                tabIndex={0}
                style={{ cursor: "pointer" }}
              >
                <img
                  ref={ref as React.MutableRefObject<HTMLImageElement>}
                  onLoad={(e) =>
                    setSize([
                      e.currentTarget.naturalWidth,
                      e.currentTarget.naturalHeight,
                    ])
                  }
                  src={src}
                  alt={alt}
                />
              </span>
            </div>
          )}
        </Item>
      </Gallery>
    </>
  );
};

export const MarkdownTable: React.ComponentType<
  React.TableHTMLAttributes<HTMLTableElement>
> = (props) => {
  return (
    <div className="table-responsive">
      <table className={`table ${props.className ?? ""}`} {...props}>
        {props.children}
      </table>
    </div>
  );
};

export default MarkdownRenderer;
