import { styled } from "styled-components";
import { device } from "theme/breakpoints";

import { Row } from "components/common/Row";

export const Baner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: 35px 57px 37px 51px;
  border-radius: 9px;
  position: relative;
  width: 100%;
  @media ${device.tablet} {
    flex-direction: column;
    margin-top: 38px;
    padding: 24px 28px 31px 28px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.xxxlarge};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-bottom: 25px;
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.normal};
  }

  p {
    margin: 0;
    letter-spacing: 0;
  }
`;

export const BgImg = styled.img`
  position: absolute;
  max-width: 184px;
  left: 50%;
  pointer-events: none;
  @media ${device.tablet} {
    left: 0;
    position: unset;
    margin: 30px 0 17px 0;
  }
`;

export const RepeatTest = styled(Row)`
  gap: 3px;
  & p {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  }
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;
