import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const ChildrenWrapper = styled.div`
  padding: 100px 0 210px;

  @media ${device.mobile} {
    padding: 100px 0 150px;
  }
`;
