import { styled } from "styled-components";

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 470px;
`;

export const InputWrapper = styled.div`
  margin: 50px 0;
`;

export const RangeInputWrapper = styled.div`
  margin: 45px 0 60px;
`;

export const Label = styled.label`
  margin-bottom: 16px;
  display: block;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  span {
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: ${({ theme }) => theme.fontWeights.normal};

    &.new-line {
      display: block;
    }
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 22px;
`;

export const ErrorMsg = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 5px;
`;
