import { styled } from "styled-components";
import { device } from "theme/breakpoints";

export const Container = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  padding-bottom: 12px;

  @media ${device.mobile} {
    padding-bottom: 30px;
  }
  &.complex-test {
    padding-left: 16px;
    & > h1 {
      font-size: ${({ theme }) => theme.fontSizes.smlarge};
      margin: 0;
    }
  }
`;

export const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.smlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Description = styled.p`
  margin: 9px 0 25px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding-right: 80px;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;

  @media ${device.mobile} {
    flex-wrap: wrap;
  }

  @media ${device.smallMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-bottom: 5px;
  line-height: 1.15;
`;

export const LevelTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.mdlarge};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-top: 11px;
`;

export const DetailsItem = styled.div`
  &:last-of-type {
    margin: 29px 0 0 auto;

    @media ${device.mobile} {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: center;
    }

    @media ${device.smallMobile} {
      justify-content: flex-start;
    }
  }
`;

export const ButtonWrapper = styled.div`
  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
