import styled, { keyframes } from "styled-components";

import React from "react";

const spin = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: ${spin} 0.6s linear infinite;
`;

const Loader: React.FC = () => {
  return <Spinner />;
};

export default Loader;
